import React from 'react'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../stores/MobXProvider'
import { routesSide, routesPages, flattenRoutesList } from '../../pages/routes'
import { FileSearchOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'

const NavBarSide = ({ setCollapsed }) => {
	const { users, appType } = useStores()
	const { appIsClient, appIsTeam } = appType

	const items = []

	const validateRoute = (route) => {
		if (!route.label) return
		if (route.loggedInDisable && users.isLoggedIn) return
		if (route.loggedInActive && !users.isLoggedIn) return
		if (route?.appType && route.appType === 'client' && !appIsClient) return
		if (route?.appType && route.appType === 'team' && !appIsTeam) return
		if (route.published === false) return

		const item = {
			key: route.key,
			keyparent: route?.keyparent || null,
			label: route?.path ? (
				<Link to={route.path}>{route.label}</Link>
			) : (
				route.label
			),
			icon: route?.icon ? <route.icon /> : null,
			type: route?.type || null
		}

		if (route.children) {
			const validChildren = route.children.map((child) =>
				validateRoute(child)
			)
			if (validChildren.length) {
				item.children = validChildren
			}
		}

		return item
	}

	routesSide.forEach((route) => {
		const validItem = validateRoute(route)
		items.push(validItem)
	})

	// If user is logged in we will add routesPages but inside a group called Pages as children
	if (users.isLoggedIn) {
		const pages = {
			key: 'pages',
			label: 'Pages',
			icon: <FileSearchOutlined />,
			children: []
		}
		routesPages.forEach((route) => {
			const validItem = validateRoute(route)
			pages.children.push(validItem)
		})
		items.push(pages)
	} else {
		// If user is not logged in we will add routesPages as main items
		routesPages.forEach((route) => {
			const validItem = validateRoute(route)
			items.push(validItem)
		})
	}

	const searchKeySelected = (curentPage) => {
		const route = flattenRoutesList.find((r) => r.key === curentPage)
		if (!route) return []

		if (route?.keyparent) {
			return [route.keyparent, route.key]
		}
		return [route.key]
	}

	const curentSelectedMenuItem = () => {
		const path = window.location.pathname
		const firstSegment = path.split('/')[1] // Split the path and get the first segment
		const curentPage = firstSegment ? firstSegment : 'home' // Return the first segment or '/' if it doesn't exist
		return searchKeySelected(curentPage)
	}

	const [selectedMenuItem, setSelectedMenuItem] = React.useState(
		curentSelectedMenuItem()
	)

	return (
		<Menu
			mode="inline"
			theme="dark"
			style={{
				background: 'transparent',
				textAlign: 'left',
				fontSize: '16px'
			}}
			onClick={(e) => {
				setSelectedMenuItem(searchKeySelected(e.key))
				isMobile && setCollapsed(true)
			}}
			selectedKeys={selectedMenuItem}
			items={items}
			ref={React.createRef()}
		/>
	)
}

NavBarSide.propTypes = {
	setCollapsed: PropTypes.func
}

export default observer(NavBarSide)
