import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { runInAction } from 'mobx'
import { Card, Button, Typography, Tabs, Row, Col, Space, Tag } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'
import { isMobile } from 'react-device-detect'

import { useStores } from '../../stores/MobXProvider'
import ActiveSubscriptions from './ActiveSubscriptions'
import {
	stripeCheckout,
	stripeLoadPlans
} from '../../stores/subscriptions/actions'
import { observer } from 'mobx-react-lite'
import SubscriptionCancelButton from './SubscriptionActions'

const { Title } = Typography

const SubscriptionOptions = () => {
	const { users, subscriptions } = useStores()
	const plans = subscriptions.plans

	const { loggedInUserId } = users
	const user = users.requireUserById(loggedInUserId)
	const userEmail = user.email
	const userSubscriptions = user.subscriptions

	// Filter the active plans
	const getActiveSubscription = (id) => {
		const activeSubscription = userSubscriptions.find(subscription => 
			subscription?.plan?.id === id
		)

		return activeSubscription 
			? {
				subId: activeSubscription.id,
				status: activeSubscription.status,
				canceled_at: activeSubscription?.canceled_at,
				cancel_at_period_end: activeSubscription?.cancel_at_period_end
			} 
			: {
				subId: null,
				status: null,
				canceled_at: null,
				cancel_at_period_end: null
			}
	}

	useEffect(() => {
		// Fetch plans if not already loaded
		plans.length === 0 && stripeLoadPlans()
	}, [])

	// Design
	const renderPlanDetails = (plan) => {
		const { subId, status, canceled_at, cancel_at_period_end } = getActiveSubscription(plan.id)
		const isActive = status === 'active';
		const isCanceled = status === 'canceled';
		const tagColor = isActive ? 'green' : isCanceled ? 'red' : 'default';
		const borderColor = isActive ? 'green' : isCanceled ? 'red' : 'default';

		return (
			<Card
				key={plan.id}
				style={{  borderColor }}
			>
				{subId && (
					<Tag color={tagColor} style={{ position: 'absolute', top: '16px', right: '16px' }}>
						{status}
					</Tag>
				)}
				<p>
					<strong>Plan:</strong> {plan.product.name}
				</p>
				<p>
					<strong>Amount:</strong> ${(plan.unit_amount / 100).toFixed(2)}{' '}
					{plan.currency.toUpperCase()}
				</p>
				<p>
					<strong>Billing Interval:</strong>{' '}
					{plan.recurring.interval_count} {plan.recurring.interval}(s)
				</p>
				<p>
					<strong>Description:</strong> {plan.product.description}
				</p>
				{plan.nickname && (
					<p>
						<strong>Note:</strong> {plan.nickname}
					</p>
				)}
				{
					isActive && subId
						?
						cancel_at_period_end
							? <SubscriptionCancelButton type='resume' subId={subId} />
							: <SubscriptionCancelButton type='cancel' subId={subId} />
						:
						isCanceled
							? <strong>Cancelled at {new Date(canceled_at * 1000).toLocaleString()}</strong>
							: <Button type="primary" onClick={() => stripeCheckout(userEmail, plan.id)}>
								Subscribe
							</Button>
				}
			</Card>
		)
	}

	const filterPlansByCategory = (category) => {
		return plans.filter((plan) => plan.product.metadata.type === category)
	}

	const getUniqueTypes = () => {
		const types = plans.map((plan) => plan.product.metadata.type)
		return [...new Set(types)]
	}

	const RenderTabsContent = ({ children }) => {
		return (
			<Row gutter={[16, 16]}>
				{children.map((child, index) => (
					<Col key={index} span={isMobile ? 24 : 8}>
						{child}
					</Col>
				))}
			</Row>
		)
	}
	RenderTabsContent.propTypes = {
		children: PropTypes.array
	}

	const renderTabs = (type) => {
		const items = [
			{
				key: 'monthly',
				label: 'Monthly',
				children: (
					<RenderTabsContent>
						{filterPlansByCategory(type)
							.filter(
								(plan) => plan.recurring.interval === 'month'
							)
							.map(renderPlanDetails)}
					</RenderTabsContent>
				)
			},
			{
				key: 'yearly',
				label: 'Yearly',
				children: (
					<RenderTabsContent>
						{filterPlansByCategory(type)
							.filter(
								(plan) => plan.recurring.interval === 'year'
							)
							.map(renderPlanDetails)}
					</RenderTabsContent>
				)
			}
		]
		return <Tabs items={items} />
	}

	return (
		<div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
			<Space align="center" style={{ marginTop: 25, marginBottom: 25 }}>
				<ReloadOutlined
					onClick={() => {
						runInAction(() => {
							subscriptions.setPlans([])
						})
						window.location.reload()
					}}
				/>
				<Title level={3} style={{ margin: 0 }}>
					Subscription Plans
				</Title>
			</Space>
			<Tabs
				defaultActiveKey="0"
				items={getUniqueTypes().map((type, index) => ({
					key: `${index}`,
					label: type,
					children: renderTabs(type)
				}))}
			/>
			<ActiveSubscriptions />
		</div>
	)
}

export default observer(SubscriptionOptions)
