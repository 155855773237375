import React, { useState } from 'react';
import { Upload, Button, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { uploadImage } from '../../modules/api-images';
import { API_BASE_URL } from '../../config';
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom';

/**
 * UploadImage component provides an interface for uploading images.
 * It displays a button to initiate the upload process and shows upload progress.
 * Uploaded images are stored in a file list and can be managed through props.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array<Object>} props.fileList - Initial list of uploaded files.
 * @param {Function} props.onFileListChange - Callback function to update the file list.
 * @returns {JSX.Element} The rendered UploadImage component.
 */
const UploadImage = ({ fileList: initialFileList, onFileListChange }) => {
    const { itmId } = useParams()
    const [fileList, setFileList] = useState(initialFileList || []);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);

    const uploadProps = {
        beforeUpload: async (file) => {
            const formData = new FormData(); // Make sure formData is initialized here
            const imageUuid = uuidv4(); // Generate a unique ID for the image
            const fileExtension = file.name.split('.').pop(); // Extract the file extension
            const newFileName = itmId
                ? `${itmId}__${imageUuid}.${fileExtension}` // Combine item ID, UUID and extension for the new filename
                : `${imageUuid}.${fileExtension}` // Combine UUID and extension for the new filename

            formData.append('image', file) // Add the file to formData
            formData.append('newFileName', newFileName) // Add the generated filename to formData

            try {
                setUploading(true);
                setProgress(0);

                const response = await uploadImage(formData, {
                    onUploadProgress: (event) => {
                        if (event.lengthComputable) {
                            const percent = Math.round((event.loaded * 100) / event.total);
                            setProgress(percent);
                        }
                    },
                });
                // Check if the upload was successful
                if (response.data.status === 'success') {
                    // Add the new image filename to the fileList
                    const newFileList = [
                        ...fileList,
                        {
                            uid: newFileName,
                            name: file.name,
                            status: 'done',
                            url: `${API_BASE_URL}/images/uploads/${newFileName}`
                        }
                    ];
                    setFileList(newFileList);
                    onFileListChange(newFileList);
                } else {
                    console.error(`${file.name} upload failed.`);
                }
            } catch (error) {
                console.error(`Upload error: ${error}`);
            } finally{
                setUploading(false);
            }

            return false; // Prevent automatic upload
        },
        onRemove: (file) => {
            const newFileList = fileList.filter((item) => item.uid !== file.uid);
            setFileList(newFileList);
            onFileListChange(newFileList);
        },
        fileList,
        listType: 'picture'
    };

    return (
        <Upload {...uploadProps}>
            <Button icon={uploading ? <Spin size="small" /> : <PlusOutlined />} disabled={uploading}>
                {uploading ? `Uploading ${progress}%` : 'Upload'}
            </Button>
        </Upload>
    );
};

UploadImage.propTypes = {
    fileList: PropTypes.arrayOf(
        PropTypes.shape({
            uid: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            url: PropTypes.string
        })
    ),
    onFileListChange: PropTypes.func.isRequired
};

export default UploadImage;
