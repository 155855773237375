import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router-dom'
import {
	Button,
	Space,
	ConfigProvider,
	Form,
	Input,
	Card,
	Tooltip,
	Row,
	Col,
	Collapse
} from 'antd'
import { SettingOutlined } from '@ant-design/icons'
import { runInAction, toJS } from 'mobx'
import { IS_NEW } from '../../config'
import { actSaveUser } from '../../stores/users/actions'
import { useStores } from '../../stores/MobXProvider'
import { GeneralTitle } from '../../components/Shared/GeneralComponents'
import LstTypeInputBase from '../../components/Form/LstTypeInputBase'
import { Platforms } from '../../modules/cls/platforms'
import DynamicFormComponent from '../../components/Form/DynamicFormComponent'
import { SelectUsers } from '../../components/Form/SelectUsers'
import PermissionsInput from '../../components/Form/PermissionsInput'
import { BusinessDetailsInput } from '../../components/Form/BusinessDetailsInput'
import UserPreferencesInput from '../../components/Form/UserPreferencesInput'

/**
 * Profile component allows viewing and editing of user profile information.
 * It handles both new user creation and existing user profile updates.
 *
 * @component
 * @returns {JSX.Element} The rendered Profile component.
 */
const UserData = () => {
	const navigate = useNavigate()
	const [form] = Form.useForm()

	// Get user ID from URL params
	const { itmId } = useParams()
	const { users } = useStores()
	const { loggedInUser } = users
	const isAdmin = loggedInUser.isAdmin
	const isSuperAdmin = loggedInUser.isSuperAdmin
	const user = users.requireUserById(itmId)

	const _initialFormValues = {
		name: user.name,
		platforms: user.platforms,
		clients: user.clients,
		team: user.team,
		allowed: user.allowed,
		businessDetails: user.businessDetails
	}

	const lstPermissions = ['read', 'write', 'delete', 'delete_view']
	const categoryPermissions = ['start', 'end', 'demo']
	const rolesAndResourcePermissions = ['status']

	// Base allowed object for a user
	const roles = {
		admin: false,
		superAdmin: false,
		clientAdmin: false,
		client: false,
		teamAdmin: false,
		team: false
	}
	const lst = {
		sprints: {
			delete: false
		},
		sprints_team: {
			delete: false
		},
		requests: {
			delete: false
		},
		monthly_reports: {
			delete: false
		}
	}
	const categories = {
		1: {
			demo: false,
			start: '',
			end: ''
		},
		2: {
			demo: false,
			start: '',
			end: ''
		},
		3: {
			demo: false,
			start: '',
			end: ''
		},
		4: {
			demo: false,
			start: '',
			end: ''
		},
		5: {
			demo: false,
			start: '',
			end: ''
		},
		6: {
			demo: false,
			start: '',
			end: ''
		},
		7: {
			demo: false,
			start: '',
			end: ''
		}
	}
	const resources = {
		switchApp: false,
		videoMeeting: false,
		upload_photos: false,
		upload_files: false,
		chat: false,
		ai: false
	}

	// Check if editing a super admin user
	const isEditingSuperAdmin = Form.useWatch(['allowed', 'superAdmin'], form)

	/**
	 * Handles form submission for saving user profile.
	 *
	 * @param {Object} vals - The form values.
	 */
	const _doSave = (vals) => {
		if (vals !== null) {
			// console.log('--- Save', vals)
			runInAction(() => {
				// Reset last full load
				user.loaded === IS_NEW && user.resetLastFullLoad()
				user.setName(vals.name)
				user.setPlatforms(vals.platforms)
				user.setClients(vals.clients)
				user.setTeam(vals.team)
				user.setBusinessDetails(vals.businessDetails)
				user.setAllowed(vals.allowed)
			})
			// console.log('--- Save user', toJS(vals))
			actSaveUser(user.user_id, toJS(user.full), toJS(user.allowed)).then(
				() => {
					users.saveState()
					navigate('/dashboard')
				}
			)
		}
	}

	// State to manage the current active tab that contains the Collapse
	const [activeTab, setActiveTab] = useState('details')

	return (
		<Space direction="vertical" style={{ width: '100%' }}>
			<GeneralTitle text="Profile" />
			<Card
				title={
					user.loaded === IS_NEW ? (
						'Create new user'
					) : (
						<Tooltip title="Edit user">
							<SettingOutlined /> {user?.name}
						</Tooltip>
					)
				}
				style={{ width: '100%' }}
			>
				<ConfigProvider>
					<Form
						labelCol={{ span: 6 }}
						wrapperCol={{ span: 18 }}
						form={form}
						name="profile"
						initialValues={_initialFormValues}
						onFinish={_doSave}
					>
						<Row gutter={16} style={{ marginBottom: 16 }}>
							<Col>
								<Button
									type={
										activeTab === 'details'
											? 'primary'
											: 'default'
									}
									onClick={() => setActiveTab('details')}
								>
									Details
								</Button>
							</Col>
							<Col>
								<Button
									type={
										activeTab === 'sprintsFilterPreferences'
											? 'primary'
											: 'default'
									}
									onClick={() =>
										setActiveTab('sprintsFilterPreferences')
									}
								>
									Preferences
								</Button>
							</Col>
							<Col>
								<Button
									type={
										activeTab === 'permissions'
											? 'primary'
											: 'default'
									}
									onClick={() => setActiveTab('permissions')}
								>
									Permissions
								</Button>
							</Col>
						</Row>

						<Collapse
							style={{
								display:
									activeTab == 'details' ? 'block' : 'none'
							}}
							defaultActiveKey={['1']}
							expandIconPosition="end"
							items={[
								{
									key: '1',
									label: 'General Settings',
									children: (
										<Row>
											<Col flex="auto">
												<Form.Item
													label="Email"
													tooltip="Is used for searching, filtering and assignment."
												>
													{user.email}
												</Form.Item>
												<Form.Item
													label="Name"
													tooltip="Is used for searching and filtering."
													name="name"
													rules={[
														{
															required: true,
															message:
																'Please provide a name!'
														}
													]}
												>
													<Input />
												</Form.Item>
												{isAdmin && (
													<>
														<Form.Item
															label="Clients"
															tooltip="Clients & coresponding data assigned to curent user. Multiple clients can be selected."
															name="clients"
														>
															<SelectUsers
																type="clients"
																mode="multiple"
																style={{
																	width: '100%'
																}}
															/>
														</Form.Item>

														<Form.Item
															label="Team"
															tooltip="Team members assigned to curent user. Multiple team members can be selected."
															name="team"
														>
															<SelectUsers
																type="team"
																mode="multiple"
																style={{
																	width: '100%'
																}}
															/>
														</Form.Item>
													</>
												)}
											</Col>
										</Row>
									)
								},
								{
									key: '7',
									label: 'Business details',
									children: (
										<BusinessDetailsInput form={form} />
									)
								},
								{
									key: '2',
									label: 'Manage Platforms',
									children: (
										<Row>
											<Col flex="auto">
												<Form.Item
													label="Platforms"
													tooltip="Sprints will require a platform to be assigned. Multiple platforms can be selected."
													name="platforms"
												>
													<LstTypeInputBase
														UseLstClass={Platforms}
														phaseTwoLimited
														additionalEditSection={(
															viewOnly
														) => {
															/*
															 * Additional section for dynamic form in EditItem Modal
															 */
															return (
																<Form.Item
																	noStyle
																	disabled={
																		viewOnly
																	}
																	name="data"
																	wrapperCol={{
																		span: 24
																	}}
																>
																	<DynamicFormComponent
																		viewOnly={
																			viewOnly
																		}
																	/>
																</Form.Item>
															)
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									)
								}
							]}
						/>

						<Collapse
							style={{
								display:
									activeTab == 'sprintsFilterPreferences'
										? 'block'
										: 'none'
							}}
							defaultActiveKey={['1']}
							expandIconPosition="end"
							items={[
								{
									key: '1',
									label: 'Sprints Filter Preferences',
									children: (
										<UserPreferencesInput user={user} />
									)
								}
							]}
						/>

						<Collapse
							style={{
								display:
									activeTab == 'permissions'
										? 'block'
										: 'none'
							}}
							defaultActiveKey={['1']}
							expandIconPosition="end"
							items={[
								{
									key: '1',
									label: 'Manage Roles',
									forceRender: true,
									children: (
										<PermissionsInput
											data={roles}
											permissions={
												rolesAndResourcePermissions
											}
											type="roles"
										/>
									)
								},
								{
									key: '2',
									label: 'Manage Permissions',
									forceRender: true,
									collapsible:
										!isEditingSuperAdmin &&
										!isSuperAdmin &&
										'disabled',
									children: (
										<PermissionsInput
											data={lst}
											permissions={lstPermissions}
											type="lst"
										/>
									)
								},
								{
									key: '3',
									label: 'Manage Categories',
									forceRender: true,
									collapsible:
										!isEditingSuperAdmin &&
										!isSuperAdmin &&
										'disabled',
									children: (
										<PermissionsInput
											data={categories}
											permissions={categoryPermissions}
											type="categories"
										/>
									)
								},
								{
									key: '4',
									label: 'Manage Resources',
									forceRender: true,
									collapsible:
										!isEditingSuperAdmin &&
										!isSuperAdmin &&
										'disabled',
									children: (
										<PermissionsInput
											data={resources}
											permissions={
												rolesAndResourcePermissions
											}
											type="resources"
										/>
									)
								}
							]}
						/>

						<Row
							justify="end"
							gutter={[10, 10]}
							style={{ marginTop: 20 }}
						>
							<Col>
								<Form.Item style={{ marginBottom: 0 }}>
									<Button type="primary" htmlType="submit">
										{user.loaded === IS_NEW
											? 'Create'
											: 'Update'}
									</Button>
								</Form.Item>
							</Col>
							<Col>
								<Form.Item style={{ marginBottom: 0 }}>
									<Button
										type="default"
										onClick={() => {
											form.resetFields()
										}}
									>
										Reset
									</Button>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</ConfigProvider>
			</Card>
		</Space>
	)
}

export default observer(UserData)
