import React, { useState, useRef, useCallback, useEffect } from 'react'
import { Button, Drawer, Alert } from 'antd'
import {
	CameraOutlined,
	DeleteOutlined,
	UploadOutlined
} from '@ant-design/icons'
import PropTypes from 'prop-types'

const WebcamCapture = ({ onUpload }) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [hasPhoto, setHasPhoto] = useState(false)
	const [photo, setPhoto] = useState(null)
	const [error, setError] = useState(null) // Error state for unsupported devices or permission issues
	const [isUsingWebcam, setIsUsingWebcam] = useState(false) // To track if we are using the webcam
	const [isWebcamSupported, setIsWebcamSupported] = useState(false) // Check if webcam is supported
	const videoRef = useRef(null)
	const streamRef = useRef(null)
	const fileInputRef = useRef(null)

	// Check if getUserMedia (webcam) is supported
	useEffect(() => {
		if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
			setIsWebcamSupported(true) // Webcam is supported
		} else {
			setIsWebcamSupported(false) // No webcam support
		}
	}, [])

	// Reusable function to start the webcam
	const startWebcam = useCallback(() => {
		return navigator.mediaDevices
			.getUserMedia({ video: true })
			.then((stream) => {
				streamRef.current = stream
				if (videoRef.current) {
					videoRef.current.srcObject = stream // Attach stream to the video element
				}
			})
			.catch((err) => {
				console.error('Error accessing webcam: ', err)
				setError(
					`Unable to access the webcam. Reason: ${err.message}. Please check your permissions or try selecting a photo instead.`
				)
				setIsUsingWebcam(false) // Fallback to file input
			})
	}, [])

	// Handle file selection or photo capture, and also handle file input cancel
	const handleFileChange = (event) => {
		const file = event.target.files[0]

		if (file) {
			const reader = new FileReader()
			reader.onloadend = () => {
				setPhoto(reader.result) // Set the photo as base64 URL
				setHasPhoto(true)
				setError(null) // Clear any previous errors
				setIsModalOpen(true) // Show the modal after a file is selected
			}
			reader.onerror = () => {
				setError('Failed to read the file. Please try again.')
			}
			reader.readAsDataURL(file) // Convert the file to a base64 string
		} else {
			// If no file is selected (cancel action), reset the state and close the modal
			setIsModalOpen(false)
			setPhoto(null)
			setHasPhoto(false)
		}

		// Reset the file input to ensure it triggers on each click, even for the same file
		if (fileInputRef.current) {
			fileInputRef.current.value = ''
		}
	}

	// Open file input dialog
	const openFileInput = () => {
		setError(null) // Reset any previous errors

		// Programmatically open the file input without affecting modal state
		if (fileInputRef.current) {
			fileInputRef.current.click()
		}
	}

	// Close the modal and reset state on cancel
	const handleCancel = () => {
		setPhoto(null)
		setHasPhoto(false)
		setIsModalOpen(false)
		if (isUsingWebcam) {
			closeWebcam()
		}
	}

	// Open the webcam when the user clicks the webcam button
	const openWebcam = () => {
		setError(null)
		setIsModalOpen(true)
		setIsUsingWebcam(true)
		startWebcam() // Start the webcam
	}

	// Close the webcam and stop video stream
	const closeWebcam = useCallback(() => {
		if (streamRef.current) {
			streamRef.current.getTracks().forEach((track) => {
				if (track.readyState === 'live') {
					track.stop() // Stop the webcam stream
				}
			})
			streamRef.current = null // Clear the reference
		}
		setIsUsingWebcam(false)
		setIsModalOpen(false)
	}, [])

	// Take a photo from the webcam video feed
	const takePhoto = useCallback(() => {
		const video = videoRef.current

		if (!video) {
			console.error('Video is not available')
			setError('Unable to capture photo. Video feed is not available.')
			return
		}

		// Create a canvas dynamically and capture the image
		const canvas = document.createElement('canvas')
		canvas.width = video.videoWidth
		canvas.height = video.videoHeight

		const context = canvas.getContext('2d')
		context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)

		const imageDataURL = canvas.toDataURL('image/png') // Capture photo as base64
		setPhoto(imageDataURL) // Set the photo
		setHasPhoto(true)

		// Stop the webcam stream but keep the modal open for retake/upload options
		if (streamRef.current) {
			streamRef.current.getTracks().forEach((track) => track.stop())
			streamRef.current = null
		}
	}, [])

	// Reset photo and restart the webcam
	const resetPhoto = useCallback(() => {
		setHasPhoto(false)
		setPhoto(null)

		// If the user selected a photo from the library, re-trigger the file input
		if (!isUsingWebcam && fileInputRef.current) {
			fileInputRef.current.click() // Trigger file input again
		}

		// Restart the webcam if it was previously being used
		if (isUsingWebcam) {
			startWebcam() // Reattach the webcam stream to the video element
		}
	}, [isUsingWebcam, startWebcam])

	const uploadPhoto = useCallback(() => {
		if (photo) {
			// Convert the base64 URL back to a Blob
			const byteString = atob(photo.split(',')[1])
			const mimeString = photo.split(',')[0].split(':')[1].split(';')[0]
			const arrayBuffer = new ArrayBuffer(byteString.length)
			const uintArray = new Uint8Array(arrayBuffer)
			for (let i = 0; i < byteString.length; i++) {
				uintArray[i] = byteString.charCodeAt(i)
			}
			const blob = new Blob([arrayBuffer], { type: mimeString })
			onUpload && onUpload(blob) // Pass the Blob to the parent component for upload

			// Reset state after upload
			setPhoto(null)
			setHasPhoto(false)
			setIsModalOpen(false)
		} else {
			setError('No photo to upload. Please select or capture a photo.')
		}
	}, [photo, onUpload])

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const uploadPhotoToBlob = useCallback(() => {
		if (photo) {
			const canvas = document.createElement('canvas')
			const img = new Image()

			img.src = photo
			img.onload = () => {
				// Set canvas dimensions to match the photo
				canvas.width = img.width
				canvas.height = img.height

				// Draw the photo onto the canvas
				const context = canvas.getContext('2d')
				context.drawImage(img, 0, 0)

				// Convert the canvas to a Blob and pass it to the parent component
				canvas.toBlob((blob) => {
					if (blob) {
						onUpload && onUpload(blob) // Pass the Blob to the parent component for upload
					}

					// Reset state after upload
					setPhoto(null)
					setHasPhoto(false)
					setIsModalOpen(false)
				}, 'image/png') // Set the MIME type to PNG
			}
		}
	}, [photo, onUpload])

	// Function to calculate the container dimensions
	const calculateDimensions = () => {
		const viewportHeight = window.innerHeight
		const maxContainerHeight = viewportHeight - 160 // Adjust for buttons and margins
		const maxContainerWidth = maxContainerHeight * (4 / 3)

		return {
			width: `${maxContainerWidth}px`,
			height: `${maxContainerHeight}px`
		}
	}

	const [containerDimensions, setContainerDimensions] = useState(
		calculateDimensions()
	)

	useEffect(() => {
		const handleResize = () => {
			setContainerDimensions(calculateDimensions())
		}
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<>
			{/* Button to select a photo from file input (always available) */}
			<Button
				type="primary"
				className="btn-style-1 h-style-1-grey btn-large h-color-grey-bg"
				icon={<CameraOutlined />}
				onClick={openFileInput} // Open file input directly
			>
				Select Photo from Library
			</Button>

			{/* Show webcam button only if webcam is supported */}
			{isWebcamSupported && (
				<Button
					type="primary"
					className="btn-style-1 h-style-1-grey btn-large h-color-grey-bg"
					icon={<CameraOutlined />}
					onClick={openWebcam} // Open webcam
					style={{ marginLeft: '16px' }}
				>
					Use Webcam
				</Button>
			)}

			<input
				type="file"
				ref={fileInputRef}
				accept="image/*"
				capture="environment" // Open camera by default on supported devices
				style={{ display: 'none' }} // Hide the input but trigger programmatically
				onChange={handleFileChange} // Handle file/photo capture
			/>

			<Drawer
				title="Capture or Select a Photo"
				open={isModalOpen}
				onClose={handleCancel} // Handle cancel properly
				footer={null}
				width="100%"
				styles={{
					body: {
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						padding: '16px'
					}
				}}
			>
				{error && (
					<Alert
						message="Error"
						description={error}
						type="error"
						showIcon
					/>
				)}

				{!hasPhoto && !error && isUsingWebcam && (
					<>
						<div
							style={{
								width: '100%',
								maxWidth: containerDimensions.width,
								height: containerDimensions.height,
								backgroundColor: '#000',
								position: 'relative',
								overflow: 'hidden'
							}}
						>
							<video
								ref={videoRef}
								autoPlay
								style={{
									width: '100%',
									height: '100%',
									objectFit: 'cover'
								}}
								onLoadedMetadata={() => {
									// setIsLoading(false)
								}}
							/>
						</div>

						<Button
							type="primary"
							className="btn-style-1 h-style-1-blue btn-large h-color-grey-bg"
							icon={<CameraOutlined />}
							onClick={takePhoto}
							style={{ marginTop: 16 }}
						>
							Take Photo
						</Button>
					</>
				)}

				{hasPhoto && (
					<>
						<div
							style={{
								width: '100%',
								maxWidth: containerDimensions.width,
								height: containerDimensions.height,
								backgroundColor: '#000',
								position: 'relative',
								overflow: 'hidden'
							}}
						>
							<img
								src={photo}
								alt="Captured"
								style={{
									width: '100%',
									height: '100%',
									objectFit: 'cover'
								}}
							/>
						</div>
						<div style={{ marginTop: 16, textAlign: 'center' }}>
							<Button
								icon={<DeleteOutlined />}
								onClick={resetPhoto}
								style={{ marginRight: 16 }}
							>
								Retake
							</Button>
							<Button
								type="primary"
								className="btn-style-1 h-style-1-blue btn-large h-color-grey-bg"
								icon={<UploadOutlined />}
								onClick={uploadPhoto}
							>
								Add photo
							</Button>
						</div>
					</>
				)}
			</Drawer>
		</>
	)
}

WebcamCapture.propTypes = {
	onUpload: PropTypes.func
}

export default WebcamCapture
