import { makeObservable, observable, action, computed } from 'mobx'
import _ from 'lodash'

import { DEFAULT_PG, DEFAULT_RPG } from '../config'
/**
 * @class UiLstStoreBase
 * @description This class represents the base store for managing UI list state, including search, filters, pagination, and item IDs.
 */
class UiLstStoreBase {
	/**
	 * @property {string} search - The search term for filtering the list.
	 * @property {Object} filter - The filter object containing various filter criteria.
	 * @property {boolean} del - The flag to indicate we require items marked for deletion.
	 * @property {number} pg - The current page number for pagination.
	 * @property {number} rpg - The number of results per page for pagination.
	 * @property {Array} ids - The array of item IDs in the list.
	 * @property {number} nr - The number of results returned by the server.
	 */
	search = ''
	filter = {}
	del = false
	pg = DEFAULT_PG
	rpg = DEFAULT_RPG
	ids = []
	nr = 0
	modals = {}
	filterExpanded = false

	/**
	 * @constructor
	 * @param {Object} rootStore - The root store that holds the global state and other stores.
	 * @param {Object} [defaultFilter={}] - The default filter object to initialize the filter state.
	 * @description Constructs an instance of UiLstStoreBase, initializes the store state, and makes it observable.
	 */
	constructor(rootStore, defaultFilter = {}) {
		this.rootStore = rootStore
		this.filter = defaultFilter
		makeObservable(this, {
			search: observable,
			filter: observable,
			del: observable,
			pg: observable,
			rpg: observable,
			ids: observable,
			nr: observable,
			modals: observable,
			filterExpanded: observable,
			setFilter: action,
			count: computed,
			setSearch: action,
			setDel: action,
			setPage: action,
			setResultsPerPage: action,
			setIds: action,
			addId: action,
			setNr: action,
			setModal: action
		})
	}

	setFilter(key, value) {
		this.filter[key] = value
	}

	setFilterExpanded(){
		this.filterExpanded = !this.filterExpanded
	}

	get count() {
		return this.ids.length
	}

	setSearch(searchTerm) {
		this.search = searchTerm
	}

	setDel(del) {
		this.del = del
	}

	setPage(pageNumber) {
		this.pg = pageNumber
	}

	setResultsPerPage(resultsPerPage) {
		this.rpg = resultsPerPage
	}

	setIds(ids) {
		this.ids = ids
	}

	addId(id) {
		this.ids.push(id)
	}

	setNr(nr) {
		this.nr = nr
	}

	// Modals
	setModal(key, value) {
		this.modals[key] = value
	}

	/*
	 * @method checkIfListIsModified
	 * @description Comparing the existing items modified date with the new items modified date. If nr is different, we will return true. If any item is modified, we will return true.
	 * @param {Array} ids - The array of item IDs in the list.
	 */
	checkIfListIsModified(ids, nr) {
		if (this.nr !== nr) {
			return true
		}

		if (!_.isEqual(_.sortBy(this.ids), _.sortBy(ids))) {
			return true
		}

		return false
	}
}

export default UiLstStoreBase
