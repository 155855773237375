import React from 'react'
import '../../App.css'
import { Col, Layout, Row } from 'antd'
import NavBarSide from './NavBarSide'
import { observer } from 'mobx-react-lite'
import PropTypes from 'prop-types'
import { siderStyle } from '../../design/app-style-config'
import { CloseCircleOutlined } from '@ant-design/icons'
import { isMobile } from 'react-device-detect'

const { Sider } = Layout

const Sidebar = observer(({ collapsed, setCollapsed }) => {
	return (
		<Sider
			trigger={null}
			collapsible
			collapsed={collapsed}
			onCollapse={(value) => setCollapsed(value)}
			width={isMobile ? '100%' : 200}
			height="calc(100vh - 40px)"
			style={siderStyle}
			hidden={isMobile && collapsed} 
		>
			<Row justify="space-between" style={{ padding: '20px 28px' }}>
				<Col>
					<Row gutter={5}>
						<Col>
							<img
								src="/webackit-logo-white.svg"
								alt="Webackit Logo"
								style={{ height: '30px' }}
							/>
						</Col>
						<Col
							style={{
								textAlign: 'right',
								alignSelf: 'center'
							}}
						>
							<span>v.1.0.0</span>
						</Col>
					</Row>
				</Col>

				{/* Show the close menu icon only for mobile devices */}
				{
					(isMobile && !collapsed) && <Col>
						<CloseCircleOutlined
							style={{
								fontSize: '25px',
								cursor: 'pointer'
							}}
							onClick={() => setCollapsed(true)}
						/>
					</Col>
				}
			</Row>
			<NavBarSide 
				setCollapsed={setCollapsed} 
			/>
		</Sider>
	)
})

Sidebar.propTypes = {
	collapsed: PropTypes.bool,
	setCollapsed: PropTypes.func
}

export default Sidebar
