/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { observer } from 'mobx-react-lite'
import {
	Button,
	Popconfirm,
	Popover,
	Select,
	Space,
	Switch,
	Tooltip
} from 'antd'
import { InfoOutlined } from '@ant-design/icons'

import { actUndoOrDeleteSprint } from '../../stores/ui-lst-sprints/actions'
import {
	actLoadMonthlyReports,
	actUndoOrDeleteReport
} from '../../stores/ui-lst-monthly-reports/actions'
import { useStores } from '../../stores/MobXProvider'

import {
	StatusTag,
	Filter,
	UserString,
	LstTable,
	FormatedTimestamp,
	CustomTable
} from '../../components/Shared'
import ProgressModal from '../../components/Form/ProgressModal'
import { runInAction } from 'mobx'
import { GeneralTourTitle } from '../../components/Shared/GeneralComponents'
import { getTourArrayByKeys } from '../../stores/tour/config'
import dayjs from 'dayjs'
import {
	newMonthlyReport,
	requireMonthlyReportsById,
	actSaveMonthlyReport
} from '../../stores/monthly-reports/actions'

/*
 * report's can be multiple types
 */
const LstItem = observer(({ id, isFirst }) => {
	const navigate = useNavigate()
	const { uiLstMonthlyReports, users } = useStores()
	const { loggedInUser } = users
	const isAdmin = loggedInUser.isAdmin
	const isSuperAdmin = loggedInUser.isSuperAdmin

	const report = requireMonthlyReportsById(id)

	const selfPermission =
		report.createdBy === loggedInUser.user_id || isAdmin || isSuperAdmin

	// Delete is active only for the creator of the report if is in draft mode or if the user is an admin
	const deleteIsDisabled = !isAdmin
		? !(
				report.progress === 'draft' &&
				(loggedInUser.user_id === report.createdBy ||
					loggedInUser.user_id === report.user_id)
		  )
		: false

	const [showConfirmUndoOrDelete, setConfirmUndoOrDelete] = useState(false)
	const confirmUndoOrDelete = () => {
		setConfirmUndoOrDelete(false)

		if (!!report.to_delete) {
			actUndoOrDeleteReport(id, false).catch(console.error)
		} else {
			actUndoOrDeleteReport(id, true).catch(console.error)
		}
	}
	const _actions = [
		{
			key: 'status',
			label: 'Set Progress',
			value: 'status',
			disabled: !!report.to_delete
		},
		{
			key: 'view',
			label: 'View',
			value: 'view'
		},
		{
			key: 'data',
			label: 'Edit',
			value: 'data',
			disabled: !!report.to_delete || !selfPermission
		}
	]

	// Delete / Undo actions
	if (report.createdBy === loggedInUser.user_id || isAdmin || isSuperAdmin) {
		if (report.to_delete) {
			_actions.push({
				key: 'undo-del',
				label: 'Undo Delete',
				value: 'undo-delete',
				disabled: deleteIsDisabled
			})
		} else {
			_actions.push({
				key: 'del',
				label: 'Delete',
				value: 'delete',
				disabled: deleteIsDisabled
			})
		}
	}

	const handleActions = (value) => {
		switch (value) {
			case 'view':
				navigate(`${id}/view`)
				break
			case 'data':
				navigate(`${id}/data`)
				break
			case 'undo-delete':
				setConfirmUndoOrDelete(true)
				break
			case 'delete':
				setConfirmUndoOrDelete(true)
				break
			case 'status':
				runInAction(() => {
					uiLstMonthlyReports.setModal('monthly-report-progress', {
						visible: true,
						itmId: id
					})
				})
				break
			default:
				break
		}
	}

	return (
		<tr>
			<td>
				<CustomTable
					data={[
						[
							{
								item: (
									<Space>
										<StatusTag
											isShort
											id={
												isFirst
													? 'target-itm-progress'
													: ''
											}
											tag={report.progress}
											style={{
												marginRight: 0,
												padding: '0 3px'
											}}
										/>
										<Tooltip title="View item">
											<Link
												to={`${id}/view`}
												id={
													isFirst
														? 'target-itm-link'
														: ''
												}
											>
												{report.displayName}
											</Link>
										</Tooltip>
									</Space>
								)
							}
						]
					]}
				/>
			</td>
			<td>
				<CustomTable
					data={[
						[
							{
								item: (
									<Space>
										<Tooltip title="View item">
											{report.hoursWorked}
										</Tooltip>
									</Space>
								)
							}
						]
					]}
				/>
			</td>
			<td id={isFirst ? 'target-itm-recurrence' : ''}>
				<CustomTable
					data={[
						[
							{
								item: (
									<Space>
										<Tooltip title="Created By">
											<UserString
												userId={report.createdBy}
												path="name"
											/>
										</Tooltip>
									</Space>
								)
							}
						]
					]}
				/>
			</td>

			<td>
				<Space direction="vertical">
					<Space>
						<Popover
							content={() => (
								<CustomTable
									data={[
										[
											{
												item: <strong>Info</strong>
											}
										],
										[
											'Updated last:',
											{
												item: (
													<FormatedTimestamp
														timestamp={
															report.modifiedTs
														}
														formatedAs="DD.MM.YYYY hh:mm"
													/>
												)
											}
										],
										[
											'Created at:',
											{
												item: (
													<FormatedTimestamp
														timestamp={
															report.createdTs
														}
														formatedAs="DD.MM.YYYY hh:mm"
													/>
												)
											}
										],
										[
											'Created by:',
											{
												item: (
													<UserString
														userId={
															report.createdBy
														}
														path="name"
													/>
												)
											}
										]
									]}
								/>
							)}
							trigger="hover"
						>
							<Button
								id={isFirst ? 'target-itm-info' : ''}
								color="default"
								icon={<InfoOutlined />}
							/>
						</Popover>
						<Select
							id={isFirst ? 'target-itm-actions' : ''}
							value="Actions"
							options={_actions}
							onChange={handleActions}
							style={{ width: 120 }}
						/>
					</Space>
					{report.toDeleteTs && (
						<>
							<span>
								Delete on:{' '}
								<FormatedTimestamp
									timestamp={report.toDeleteTs}
								/>
							</span>
						</>
					)}
				</Space>

				<Popconfirm
					title={
						report.to_delete
							? 'Confirm undo delete'
							: 'Confirm delete'
					}
					open={showConfirmUndoOrDelete}
					onConfirm={confirmUndoOrDelete}
					onCancel={() => setConfirmUndoOrDelete(false)}
					okType="link"
					placement="topLeft"
				/>
			</td>
		</tr>
	)
})

LstItem.propTypes = {
	id: PropTypes.string.isRequired,
	isFirst: PropTypes.bool
}

const MonthlyReportsLst = observer(() => {
	const navigate = useNavigate()
	const {
		uiLstMonthlyReports,
		tourStore,
		hasIntervalLoad,
		toggleIntervalLoad,
		appType,
		users
	} = useStores()
	const { loggedInUserId } = users
	const bySearch = uiLstMonthlyReports.search
	const byDel = uiLstMonthlyReports.del
	const byCategory = uiLstMonthlyReports.filter.category
	const byProgress = uiLstMonthlyReports.filter.progress
	const user_uid = uiLstMonthlyReports.filter.user_uid

	const intervalIdRef = useRef(null) // Use ref for intervalId
	const [errorKey, setErrorKey] = useState(null)
	const withIntervalLoad = hasIntervalLoad('monthly-reports')

	useEffect(() => {
		actLoadMonthlyReports().catch(console.error)
		// Function to cancel interval
		const cancelCheckAndLoad = () => {
			if (intervalIdRef.current) {
				clearInterval(intervalIdRef.current)
			}
		}
		// Function to check if any item in list is modified and reload items it if necessary
		const checkAndLoadItems = () => {
			if (withIntervalLoad) {
				if (errorKey !== 'not_found') {
					actLoadMonthlyReports(true)
						.then(
							(res) =>
								res &&
								actLoadMonthlyReports().catch(console.error)
						)
						.catch(console.error)
				} else if (errorKey === 'not_found') {
					cancelCheckAndLoad()
				}
			} else {
				cancelCheckAndLoad()
			}
		}

		// Polling interval to check for modifications every 5 seconds
		if (withIntervalLoad) {
			intervalIdRef.current = setInterval(checkAndLoadItems, 5000)
		} else {
			cancelCheckAndLoad()
		}

		// Clean up interval on component unmount
		return () => cancelCheckAndLoad()
	}, [
		bySearch,
		byDel,
		byCategory,
		byProgress,
		user_uid,
		withIntervalLoad,
		appType.appIs
	])

	const tourSprintLst = getTourArrayByKeys('sprintLst', ['all'])
	const tourFilter = getTourArrayByKeys('filter', ['all'])
	const startTour = () => {
		const hasItems = uiLstMonthlyReports.ids.length > 0
		if (!hasItems) {
			const newId = uuidv4()
			tourStore.setDeleteItemAct(() =>
				actUndoOrDeleteSprint(newId, true).catch(console.error)
			)

			const demoObj = {
				createdTs: dayjs().unix(),
				createdBy: loggedInUserId,
				for_user_id: loggedInUserId,
				platformIds: [],
				category_id: byCategory || '7',
				name: 'demoItem',
				progress: 'draft',
				recurrence: {
					daysOfWeek: [],
					dayOfMonth: '',
					months: [],
					next: ''
				},
				subtasks: [],
				comments: []
			}
			actSaveMonthlyReport(newId, demoObj)
				.then(() => {
					actLoadMonthlyReports().catch(console.error)
				})
				.catch(console.error)
		}
		tourStore.startTour('SprintsLst-tour', [
			{
				title: 'Sync on interval',
				description:
					'Enable or disable data synchronization on interval for the sprints list. Any additions or modifications will be automatically updated even if working in multiple tabs or list/items are updated by multiple users.',
				target: () => document.getElementById('target-interval-lst')
			},
			...tourFilter,
			...tourSprintLst
		])
	}

	return (
		<Space direction="vertical" style={{ width: '100%', padding: 20 }}>
			<GeneralTourTitle
				titleProps={{ text: 'Monthly Reports' }}
				buttonProps={{
					onClick: startTour
				}}
				rowProps={{
					justify: 'space-between'
				}}
			>
				<Switch
					id="target-interval-lst"
					style={{ marginRight: 10 }}
					tooltip="Sync data on interval"
					value={withIntervalLoad}
					checkedChildren="Sync on"
					unCheckedChildren="Sync off"
					defaultChecked
					onChange={() => toggleIntervalLoad('monthly-reports')}
				/>
			</GeneralTourTitle>
			<Filter
				uiStore={uiLstMonthlyReports}
				hide={['category', 'for-user']}
				addNew={() => {
					const newId = uuidv4()
					newMonthlyReport(newId).then(() => {
						navigate(`/monthly-reports/${newId}/data`)
					})
				}}
			/>

			<ProgressModal
				modalKey="monthly-report-progress"
				uiStore={uiLstMonthlyReports}
				requireItemById={requireMonthlyReportsById}
				loadItm={(id) => actLoadMonthlyReports(id)}
				saveItem={actSaveMonthlyReport}
			/>

			<LstTable
				LstItem={LstItem}
				store={uiLstMonthlyReports}
				className="table-pro"
			>
				<tr id="items-lst">
					<th id="target-monthly_report">Month</th>
					<th id="target-info">Hours</th>
					<th id="target-user">User</th>
					<th id="target-actions">Actions</th>
				</tr>
			</LstTable>
		</Space>
	)
})

export default MonthlyReportsLst
