import { get } from 'lodash'
import { runInAction } from 'mobx'
import rootStore from '../..'
import { getCompactLst } from '../../../modules/api-compact'
import { wrapWithLoading } from '../../general-actions'
import { responseHasError } from '../../users/actions'
import { PLAN_FOR_DELETION_NR_OF_DAYS, warnPt } from '../../../config'
import { requireMonthlyReportsById } from '../../monthly-reports/actions'
import dayjs from 'dayjs'
import { deleteLstItem } from '../../../modules/api-delete'
import UiLstMonthlyReportsStore from '..'

const uiLstMonthlyReportsStore = rootStore.uiLstMonthlyReports

/**
 * @async
 * @function actLoadMonthlyReports
 * @description Asynchronously loads reports from the server and updates the UI list store.
 * @param { function } navigate - The navigation function.
 * @returns {Promise<void>}
 */
export const actLoadMonthlyReports = wrapWithLoading(
	async (get_modified = false) => {
		const params = {
			// to complete
			del: uiLstMonthlyReportsStore.del,
			pg: uiLstMonthlyReportsStore.pg,
			rpg: uiLstMonthlyReportsStore.rpg,
			search: uiLstMonthlyReportsStore.search,
			// category_id: uiLstMonthlyReportsStore.filter?.category || '',
			progress: uiLstMonthlyReportsStore.filter?.progress || '',
			user_uid: uiLstMonthlyReportsStore.filter?.user_uid || '',
			get_modified: get_modified,
			orderBy: uiLstMonthlyReportsStore.filter?.orderBy,
			orderDir: uiLstMonthlyReportsStore.filter?.orderDir
		}

		const _response = await getCompactLst('monthly_reports', params)

		const hasError = responseHasError(_response)
		if (!hasError && _response.status === 'success' && !get_modified) {
			console.log('actLoadMonthlyReports() response:', _response)
			// to complete
			runInAction(() => {
				_actSync(
					get(_response, ['itms'], []),
					get(_response, ['nr'], 0)
				)
			})
		} else if (
			!hasError &&
			_response.status === 'success' &&
			get_modified
		) {
			// to complete
		} else {
			throw _response
		}
	},
	{ loadingMessage: 'Loading Monthly Reports' }
)

export const actUndoOrDeleteReport = wrapWithLoading(
	async (reportId, status = true) => {
		const plan_for_deletion = dayjs()
			.add(PLAN_FOR_DELETION_NR_OF_DAYS['monthly_reports'], 'day')
			.unix()
		const timestamp = status ? plan_for_deletion : false

		const _response = await deleteLstItem('monthly_reports', {
			id: reportId,
			to_delete: timestamp || false
		})
		const _storeItm = requireMonthlyReportsById(reportId)
		_storeItm.setToDelete(timestamp)

		const hasError = responseHasError(_response)
		if (!hasError && _response.status === 'success') {
			runInAction(() => {
				_storeItm.setToDelete(timestamp || false)

				UiLstMonthlyReportsStore.setIds(
					UiLstMonthlyReportsStore.ids.filter((id) => id !== reportId)
				)
				UiLstMonthlyReportsStore.setNr(UiLstMonthlyReportsStore.nr - 1)
			})
		} else {
			throw _response
		}
	},
	{ loadingMessage: 'Del/Undo Monthly Report' }
)

/**
 * @function _actSync
 * @description Syncs the server items with the store items.
 * @param {Array} srvItms - Server items.
 * @param {number} nr - Number of items.
 */
const _actSync = (srvItms, nr) => {
	warnPt(srvItms, nr)
	runInAction(() => {
		for (const _srvItm of srvItms) {
			const _storeItm = requireMonthlyReportsById(_srvItm.report_id)
			_storeItm.updateFromServer(_srvItm)
		}
		uiLstMonthlyReportsStore.setIds(srvItms.map((it) => it.report_id))
		uiLstMonthlyReportsStore.setNr(nr)
	})
}
