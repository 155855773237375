import React, { useEffect, useState } from 'react'
import { runInAction } from 'mobx'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { observer } from 'mobx-react-lite'
import {
	Button,
	Popconfirm,
	Popover,
	Select,
	Space,
	Tag,
	Typography
} from 'antd'
import { UserOutlined, InfoOutlined } from '@ant-design/icons'

import { useStores } from '../../stores/MobXProvider'
import {
	actLoadRequests,
	actUndoOrDeleteRequest
} from '../../stores/ui-lst-requests/actions'

import {
	StatusTag,
	Filter,
	UserString,
	LstTable,
	CustomTable,
	FormatedTimestamp
} from '../../components/Shared'
import {
	newRequest,
	requireRequestById,
	actLoadRequest,
	actSaveRequest
} from '../../stores/requests/actions'
import ProgressModal from '../../components/Form/ProgressModal'
import { DO_LOAD } from '../../config'

const { Title } = Typography

const LstItem = observer(({ id }) => {
	const { uiLstRequests, users } = useStores()
	const { loggedInUser } = users
	const isAdmin = loggedInUser.isAdmin
	const request = requireRequestById(id)

	// Load request if not loaded
	useEffect(() => {
		if (request?.loaded === DO_LOAD) {
			actLoadRequest(request.id).catch(console.error)
		}
	}, [request])

	// Delete is active only for the creator of the request if is in draft mode or if the user is an admin
	const deleteIsDisabled = !isAdmin
		? !(
				request.progress === 'not_started' &&
				(loggedInUser.user_id === request.createdBy ||
					loggedInUser.user_id === request.user_id)
		  )
		: false

	const [showConfirmUndoOrDelete, setConfirmUndoOrDelete] = useState(false)
	const confirmUndoOrDelete = () => {
		setConfirmUndoOrDelete(false)

		if (!!request.to_delete) {
			actUndoOrDeleteRequest(id, false).catch(console.error)
		} else {
			actUndoOrDeleteRequest(id, true).catch(console.error)
		}
	}

	const _actions = [
		{
			key: 'view',
			label: 'View',
			value: 'view'
		}
	]

	// give edit permission only the owner of the request
	if (loggedInUser.user_id == request.createdBy) {
		_actions.push({
			key: 'data',
			label: 'Edit',
			value: 'data'
		})
	}

	// if user has permissions to change progress
	// (only if current user created the request or request created for this user)
	if (
		loggedInUser.user_id == request.createdBy ||
		loggedInUser.user_id == request.forUserId
	) {
		_actions.push({
			key: 'status',
			label: 'Set Progress',
			value: 'status'
		})
	}

	// Delete / Undo actions
	if (request.to_delete) {
		_actions.push({
			key: 'undo-del',
			label: 'Undo Delete',
			value: 'undo-delete',
			disabled: deleteIsDisabled
		})
	} else {
		_actions.push({
			key: 'del',
			label: 'Delete',
			value: 'delete',
			disabled: deleteIsDisabled
		})
	}

	const handleActions = (value) => {
		switch (value) {
			case 'data':
				runInAction(() => {
					uiLstRequests.setModal('request-modal', {
						visible: true,
						itmId: id,
						mode: 'data'
					})
				})
				break
			case 'view':
				runInAction(() => {
					uiLstRequests.setModal('request-modal', {
						visible: true,
						itmId: id,
						mode: 'view'
					})
				})
				break
			case 'undo-delete':
				setConfirmUndoOrDelete(true)
				break
			case 'delete':
				setConfirmUndoOrDelete(true)
				break
			case 'status':
				runInAction(() => {
					uiLstRequests.setModal('request-progress', {
						visible: true,
						itmId: id
					})
				})
				break
			default:
				break
		}
	}

	return (
		<tr>
			<td>
				<CustomTable
					data={[
						[
							{
								item: (
									<Space>
										<StatusTag
											tag={request.priority}
											tagType={'PriorityTag'}
											style={{
												marginRight: 0,
												padding: '0 3px'
											}}
										/>
										<StatusTag
											isShort={true}
											tag={request.progress}
											tagType={'RequestStatusTag'}
											style={{
												marginRight: 0,
												padding: '0 3px'
											}}
										/>
										{request.name}
									</Space>
								)
							}
						]
					]}
				/>
			</td>
			<td>
				{loggedInUser.user_id == request.createdBy ? (
					<UserOutlined />
				) : (
					<Tag color="default">
						<UserString userId={request.createdBy} path="name" />
					</Tag>
				)}
			</td>
			<td>
				{loggedInUser.user_id == request.forUserId ? (
					<UserOutlined />
				) : (
					<Tag color="default">
						{request.forUserId && (
							<UserString
								userId={request.forUserId}
								path="name"
							/>
						)}
					</Tag>
				)}
			</td>
			<td>
				<Space>
					<Popover
						content={() => (
							<CustomTable
								data={[
									[
										{
											item: <strong>Info</strong>
										}
									],
									[
										'Updated last:',
										{
											item: (
												<FormatedTimestamp
													timestamp={
														request.modifiedTs
													}
													formatedAs="DD.MM.YYYY hh:mm"
												/>
											)
										}
									],
									[
										'Created at:',
										{
											item: (
												<FormatedTimestamp
													timestamp={
														request.createdTs
													}
													formatedAs="DD.MM.YYYY hh:mm"
												/>
											)
										}
									]
								]}
							/>
						)}
						trigger="hover"
					>
						<Button color="default" icon={<InfoOutlined />} />
					</Popover>

					<Space>
						<Select
							value="Actions"
							options={_actions}
							onChange={handleActions}
							style={{ width: 120 }}
						/>
					</Space>

					<Popconfirm
						title={
							request.to_delete
								? 'Confirm undo delete'
								: 'Confirm delete'
						}
						open={showConfirmUndoOrDelete}
						onConfirm={confirmUndoOrDelete}
						onCancel={() => setConfirmUndoOrDelete(false)}
						okType="link"
						placement="topLeft"
					/>
				</Space>
			</td>
		</tr>
	)
})
LstItem.propTypes = {
	id: PropTypes.string.isRequired
}
const RequestsLst = observer(() => {
	const { uiLstRequests } = useStores()

	const bySearch = uiLstRequests.search
	const byDel = uiLstRequests.del
	const byProgress = uiLstRequests.filter.progress
	const byPriority = uiLstRequests.filter.priority
	const for_user_uid = uiLstRequests.filter.for_user_uid
	const user_uid = uiLstRequests.filter.user_uid
	const orderBy = uiLstRequests.filter.orderBy
	const orderDir = uiLstRequests.filter.orderDir

	useEffect(() => {
		actLoadRequests()
	}, [
		bySearch, 
		byDel, 
		byProgress,
		byPriority,
		for_user_uid,
		user_uid,
		orderBy,
		orderDir,
	])

	return (
		<Space direction="vertical" style={{ width: '100%', padding: 20 }}>
			<Title
				level={1}
				style={{ fontSize: 25, marginTop: 0, marginBottom: 10 }}
			>
				Requests history
			</Title>
			<Filter
				uiStore={uiLstRequests}
				addNew={() => {
					const newId = uuidv4()
					newRequest(newId).then(() => {
						runInAction(() => {
							uiLstRequests.setModal('request-modal', {
								visible: true,
								itmId: newId
							})
						})
					})
				}}
				hide={['category']}
				progressOptions={[
					{
						key: 'by-progress',
						value: '',
						label: 'By Status'
					},
					{
						key: 'by-progress-not-read',
						value: 'not_read',
						label: 'Not read'
					},
					{
						key: 'by-progress-completed',
						value: 'completed',
						label: 'Completed'
					}
				]}
			/>
			<ProgressModal
				modalKey="request-progress"
				isForRequest={true}
				uiStore={uiLstRequests}
				requireItemById={requireRequestById}
				loadItm={(id) => actLoadRequest(id)}
				saveItem={actSaveRequest}
			/>
			<LstTable
				LstItem={LstItem}
				store={uiLstRequests}
				className="table-pro"
			>
				<tr>
					<th>Request</th>
					<th>Product Owner</th>
					<th>Request For</th>
					<th>Actions</th>
				</tr>
			</LstTable>
		</Space>
	)
})
export default RequestsLst
