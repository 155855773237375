import { makeObservable, observable, computed, action } from 'mobx'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'

/**
 * Class representing a Comment.
 */
export class Comment {
	id
	description
	sortId
	createdTs
	createdBy
	images = []
	web

	constructor({ id, description, sortId, web, createdBy, createdTs, images = [] }) {
		this.id = id || uuidv4()
		this.description = description
		this.sortId = sortId || 0
		this.web = web || ""
		this.createdBy = createdBy
		this.createdTs = createdTs || dayjs().unix()
		this.images = images || []

		makeObservable(this, {
			description: observable,
			sortId: observable,
			web: observable,
			createdTs: observable,
			createdBy: observable,
			images: observable,
			delete: action.bound,
			addImage: action.bound,
			removeImage: action.bound,
			setWeb: action.bound
		})
	}

	delete() {
		// Logic to delete this comment can be handled by the parent collection (Comments)
		// Here we just define the method for structure completeness
	}

	addImage(fileName) {
		this.images.push(fileName)
	}

	removeImage(fileName) {
		this.images = this.images.filter((image) => image !== fileName)
	}

	setWeb(web) {
		this.web = web
	}
}

/**
 * Class representing a collection of Comments.
 */
export class Comments {
	list = []

	constructor(list = []) {
		this.list = Array.isArray(list)
			? list.map((comment) => new Comment(comment))
			: []
		makeObservable(this, {
			list: observable,
			addComment: action.bound,
			deleteComment: action.bound,
			getCommentById: action.bound,
			sortedList: computed,
			commentCount: computed
		})
	}

	get sortedList() {
		return [...this.list].sort((a, b) => a.sortId - b.sortId);
	}

	prepareForSave() {
		if (!Array.isArray(this.list)) {
			return [] // Ensure the function returns an empty array if this.list is not an array
		}

		return this.list.map((comment) => comment)
	}

	updateComment(updatedComment) {
		const index = this.list.findIndex(
			(comment) => comment.id === updatedComment.id
		)
		if (index !== -1) {
			this.list[index] = updatedComment
		}
	}

	addComment(description, sortId, web, images, createdBy) {
		const comment = new Comment({ description, sortId, web, images, createdBy })
		this.list.push(comment)
	}

	deleteComment(commentId) {
		this.list = this.list.filter((comment) => comment.id !== commentId)
	}

	getCommentById(commentId) {
		return this.list.find((comment) => comment.id === commentId)
	}

	get commentCount() {
		return this.list.length
	}
}
