import React from 'react'
import { observer } from 'mobx-react-lite'
import PropTypes from 'prop-types'
import { Row, Col, Input, Checkbox, Button, ConfigProvider, Select } from 'antd'
import OptionsSelector from './OptionsSelector'
import AllowedCategories from './AllowedCategories'
import { useStores } from '../../stores/MobXProvider'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { BrowserView, MobileView } from 'react-device-detect'

const { Search } = Input

// Default filter options for filtering items by their progress status.
export const defaultFilterOptions = [
	{
		key: 'by-progress',
		value: '',
		label: 'By Status'
	},
	{
		key: 'by-progress-canceled',
		value: 'canceled',
		label: 'Canceled'
	},
	{
		key: 'by-progress-draft',
		value: 'draft',
		label: 'Draft'
	},
	{
		key: 'by-progress-in_review',
		value: 'in_review',
		label: 'In review'
	},
	{
		key: 'by-progress-active',
		value: 'active',
		label: 'Active'
	},
	{
		key: 'by-progress-pending',
		value: 'pending',
		label: 'Pending'
	},
	{
		key: 'by-progress-completed',
		value: 'completed',
		label: 'Completed'
	}
]

// Default priority options for sorting items by their priority level.
export const defaultPriorityOptions = [
	{
		key: 'by-priority',
		value: '',
		label: 'By Priority'
	},
	{
		key: 'by-priority-0',
		label: 'low',
		value: '0'
	},
	{
		key: 'by-priority-1',
		label: 'medium',
		value: '1'
	},
	{
		key: 'by-priority-2',
		label: 'high',
		value: '2'
	},
	{
		key: 'by-priority-3',
		label: 'urgent',
		value: '3'
	}
]

// Default order by options for sorting items based on various criteria.
export const defaultOrderByOptions = [
	{
		key: 'by-orderBy',
		value: '',
		label: 'Order by'
	},
	{
		key: 'by-orderBy-id',
		label: 'id',
		value: 'id'
	},
	{
		key: 'by-orderBy-category_id',
		label: 'category_id',
		value: 'category_id'
	},
	{
		key: 'by-orderBy-name',
		label: 'name',
		value: 'name'
	},
	{
		key: 'by-orderBy-to_delete',
		label: 'to_delete',
		value: 'to_delete'
	},
	{
		key: 'by-orderBy-modified',
		label: 'modified',
		value: 'modified'
	},
	{
		key: 'by-orderBy-priority',
		label: 'priority',
		value: 'priority'
	},
	{
		key: 'by-orderBy-progress',
		label: 'progress',
		value: 'progress'
	}
]

/*
 * Filter
 * @description Filter component for filtering list items.
 * @param {object} uiStore - The MobX store for the UI.
 * @param {function} addNew - The function to add a new item.
 * @param {array} progressOptions - The progress options.
 * @param {array} hide - ['search', 'progress', 'category', 'deleted', 'addNew']
 */
const Filter = ({
	uiStore,
	addNew,
	progressOptions = [],
	priorityOptions = [],
	userOptions = [],
	orderByOptions = [],
	hide = []
}) => {
	const { users } = useStores()
	const { loggedInUserId, loggedInUser } = users

	// progress options: enum('canceled','draft','in_review','active','pending','completed')
	const _progressOptions =
		progressOptions.length > 0 ? progressOptions : defaultFilterOptions

	// priority options: enum('0','1','2','3') represents - low, medium, high and urgent
	const _priorityOptions =
		priorityOptions.length > 0 ? priorityOptions : defaultPriorityOptions

	// progress options: enum('id','draft','name','to_delete','modified', 'priority', 'progress')
	const _orderByOptions =
		orderByOptions.length > 0 ? orderByOptions : defaultOrderByOptions

	// Generates user options based on the provided userOptions array
	// or defaults to the logged-in user's clients and team if no options are available.
	const _userOptions =
		userOptions.length > 0
			? userOptions
			: (userOptions = [
					...[loggedInUserId],
					...new Set([...loggedInUser.clients, ...loggedInUser.team])
			  ].map((uid) => {
					const user = users.requireUserById(uid)
					return {
						key: uid,
						label: user.name,
						value: uid
					}
			  }))

	const filterValue = uiStore.filter

	return (
		<ConfigProvider
			theme={{
				token: {
					colorPrimary: '#1b1d1f'
				},
				components: {
					Input: {
						colorTextPlaceholder: '#1b1d1f'
					},
					Select: {
						optionSelectedColor: '#fff'
					}
				}
			}}
		>
			{/* Display filter for larger devices (desktop and tablets) */}
			<BrowserView>
				<Row
					id="target-filter"
					align="middle"
					gutter={[10, 10]}
					style={{
						width: '100%',
						borderRadius: 4,
					}}
				>
					{/* First Row */}
					<Col id="target-filter-search">
						{!hide.includes('search') && (
							<Search
								defaultValue={uiStore.search || ''}
								placeholder="Search..."
								onSearch={(val) => uiStore.setSearch(val)}
								enterButton
								allowClear
							/>
						)}
					</Col>
					<Col id="target-filter-progress">
						{!hide.includes('progress') && (
							<OptionsSelector
								value={filterValue?.progress || ''}
								onChange={(val) => uiStore.setFilter('progress', val)}
								options={_progressOptions}
							/>
						)}
					</Col>
					<Col id="target-filter-priority">
						{!hide.includes('priority') && (
							<OptionsSelector
								value={filterValue?.priority || ''}
								onChange={(val) => uiStore.setFilter('priority', val)}
								options={_priorityOptions}
							/>
						)}
					</Col>
					<Col flex="auto" id="target-filter-category">
						{!hide.includes('category') && (
							<AllowedCategories
								value={filterValue?.category || ''}
								onChange={(val) => uiStore.setFilter('category', val)}
								optionStart={{ key: 'by-category', value: '', label: 'By category' }}
							/>
						)}
					</Col>
					<Col flex="auto">
						{addNew && !hide.includes('addNew') && (
							<Button id="target-filter-addnew" onClick={addNew}>
								Add new
							</Button>
						)}
					</Col>

					{/* Expand/Collapse Button */}
					<Col flex="auto">
						<Button onClick={() => uiStore.setFilterExpanded()}>
							{
								uiStore.filterExpanded ?
								<span>
									<UpOutlined />
								</span> :
								<span>
									<DownOutlined />
								</span>
							}
						</Button>
					</Col>

					{/* Second Row - Hidden by Default */}
					<div className={`filter-advanced ${uiStore.filterExpanded ? 'visible' : 'hidden'}`}>
						<Col flex="auto" id="target-filter-user">
							{!hide.includes('by-user') && (
								<OptionsSelector
									value={filterValue?.user_uid || ''}
									onChange={(val) => uiStore.setFilter('user_uid', val)}
									options={[{ key: 'by-user', value: '', label: 'By user' }, ..._userOptions]}
								/>
							)}
						</Col>
						<Col flex="auto" id="target-filter-for-user">
							{!hide.includes('for-user') && (
								<OptionsSelector
									value={filterValue?.for_user_uid || ''}
									onChange={(val) => uiStore.setFilter('for_user_uid', val)}
									options={[{ key: 'for-user', value: '', label: 'For user' }, ..._userOptions]}
								/>
							)}
						</Col>
						<Col flex="auto" id="target-filter-orderBy">
							{!hide.includes('orderBy') && (
								<FilterOrderBy
									value={filterValue?.orderBy || ''}
									orderDir={filterValue?.orderDir || ''}
									onChange={(val) => uiStore.setFilter('orderBy', val)}
									onOrderDirChange={(val) => uiStore.setFilter(
										'orderDir',
										val === filterValue?.orderDir ? '' : val
									)}
									options={_orderByOptions}
								/>
							)}
						</Col>
						<Col id="target-filter-deleted">
							{!hide.includes('deleted') && (
								<Checkbox
									checked={uiStore?.del || false}
									onChange={(e) => uiStore.setDel(e.target.checked)}
								>
									Deleted
								</Checkbox>
							)}
						</Col>
					</div>

				</Row>
			</BrowserView>

			{/* Display filter for mobile devices */}
			<MobileView>
				<Row
					id="target-filter"
					align="middle"
					gutter={[10, 10]}
					style={{
						width: '100%',
						borderRadius: 4,
					}}
				>
					{/* First Row */}
					<Col id="target-filter-search">
						{!hide.includes('search') && (
							<Search
								defaultValue={uiStore.search || ''}
								placeholder="Search..."
								onSearch={(val) => uiStore.setSearch(val)}
								enterButton
								allowClear
							/>
						)}
					</Col>

					{/* Second Row - Hidden by Default */}
					<Col className={`filter-advanced ${uiStore.filterExpanded ? 'visible' : 'hidden'}`} style={{ width: '100%' }}>
						<Row gutter={[10, 10]} style={{ width: '100%' }} align="middle">
							<Col span={12} id="target-filter-progress">
								{!hide.includes('progress') && (
									<OptionsSelector
										dropdownMinWidth='100px'
										value={filterValue?.progress || ''}
										onChange={(val) => uiStore.setFilter('progress', val)}
										options={_progressOptions}
									/>
								)}
							</Col>
							<Col span={12} id="target-filter-priority">
								{!hide.includes('priority') && (
									<OptionsSelector
										dropdownMinWidth='100px'
										value={filterValue?.priority || ''}
										onChange={(val) => uiStore.setFilter('priority', val)}
										options={_priorityOptions}
									/>
								)}
							</Col>
							<Col span={12} id="target-filter-category">
								{!hide.includes('category') && (
									<AllowedCategories
										dropdownMinWidth='100px'
										value={filterValue?.category || ''}
										onChange={(val) => uiStore.setFilter('category', val)}
										optionStart={{ key: 'by-category', value: '', label: 'By category' }}
									/>
								)}
							</Col>
							<Col span={12} id="target-filter-user">
								{!hide.includes('by-user') && (
									<OptionsSelector
										dropdownMinWidth='100px'
										value={filterValue?.user_uid || ''}
										onChange={(val) => uiStore.setFilter('user_uid', val)}
										options={[{ key: 'by-user', value: '', label: 'By user' }, ..._userOptions]}
									/>
								)}
							</Col>
							<Col span={12} id="target-filter-for-user">
								{!hide.includes('for-user') && (
									<OptionsSelector
										dropdownMinWidth='100px'
										value={filterValue?.for_user_uid || ''}
										onChange={(val) => uiStore.setFilter('for_user_uid', val)}
										options={[{ key: 'for-user', value: '', label: 'For user' }, ..._userOptions]}
									/>
								)}
							</Col>
							<Col span={12} id="target-filter-orderBy">
								{!hide.includes('orderBy') && (
									<FilterOrderBy
										dropdownMinWidth='100px'
										value={filterValue?.orderBy || ''}
										orderDir={filterValue?.orderDir || ''}
										onChange={(val) => uiStore.setFilter('orderBy', val)}
										onOrderDirChange={(val) => uiStore.setFilter(
											'orderDir',
											val === filterValue?.orderDir ? '' : val
										)}
										options={_orderByOptions}
									/>
								)}
							</Col>
							<Col span={12}>
								{addNew && !hide.includes('addNew') && (
									<Button
										style={{ width: '100%' }}
										id="target-filter-addnew"
										onClick={addNew}
									>
										Add new
									</Button>
								)}
							</Col>
							<Col span={12} id="target-filter-deleted">
								{!hide.includes('deleted') && (
									<Checkbox
										checked={uiStore?.del || false}
										onChange={(e) => uiStore.setDel(e.target.checked)}
									>
										Deleted
									</Checkbox>
								)}
							</Col>
						</Row>
					</Col>

					{/* Expand/Collapse Button */}
					<Col span={uiStore.filterExpanded && 24}>
						<Button
							onClick={() => uiStore.setFilterExpanded()}
							style={{ width: uiStore.filterExpanded ? '100%' : 'auto' }}
						>
							{
								uiStore.filterExpanded ?
									<span>
										<UpOutlined /> More Filters
									</span> :
									<span>
										<DownOutlined />
									</span>
							}
						</Button>
					</Col>
				</Row>
			</MobileView>
		</ConfigProvider>
	)
}

Filter.propTypes = {
	uiStore: PropTypes.object.isRequired,
	addNew: PropTypes.func,
	progressOptions: PropTypes.array,
	priorityOptions: PropTypes.array,
	userOptions: PropTypes.array,
	orderByOptions: PropTypes.array,
	hide: PropTypes.array
}

export default observer(Filter)


/**
 * FilterOrderBy component allows users to select filter options and specify
 * the order direction for sorting. It combines a dropdown selection with
 * ascending/descending order checkboxes for a user-friendly filtering interface.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.options - An array of options to display in the dropdown.
 * @param {string|number|Object|Array} props.value - The current selected value.
 * @param {Function} props.onChange - Callback function to handle value changes.
 * @param {string} props.orderDir - The current order direction ('asc' or 'desc').
 * @param {Function} props.onOrderDirChange - Callback function to handle order direction changes.
 * @returns {JSX.Element} The rendered FilterOrderBy component.
 */
const FilterOrderBy = ({ options, value, onChange, orderDir, onOrderDirChange, ...props }) => {
	const {
		style,
		className,
		placeholder,
		listHeight,
		listItemHeight,
		dropdownStyle,
		dropdownMinWidth = false,
		...rest
	} = props

	const minWidth = dropdownMinWidth
		? { minWidth: dropdownMinWidth }
		: { minWidth: '200px' }

	return (
		<div  style={{ display: 'flex', alignItems: 'center' }}>
            <Select
                allowClear
                options={options}
                value={value}
                onChange={onChange}
                placement="bottomLeft"
                placeholder={placeholder ?? '--'}
                className={className ?? 'antd-select-style-1 '}
                listHeight={listHeight ?? 150}
                listItemHeight={listItemHeight ?? 10}
                style={{
                    ...{ width: '100%', display: 'inline-block' },
                    ...style,
					...minWidth,
                }}
                dropdownStyle={{ ...minWidth, ...dropdownStyle }}

                // Render container for order direction checkboxes
                dropdownRender={menu => (
                    <>
                        <div style={{
                            display: 'flex',
                            backgroundColor: 'white',
                            borderRadius: '4px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            padding: '8px',
                            marginBottom: '8px'
                        }}>
                            <Checkbox
                                checked={orderDir === 'asc'}
                                onChange={() => onOrderDirChange('asc')}
                            >
                                Ascending
                            </Checkbox>
                            <Checkbox
                                checked={orderDir === 'desc'}
                                onChange={() => onOrderDirChange('desc')}
                            >
                                Descending
                            </Checkbox>
                        </div>
                        <div>{menu}</div>
                    </>
                )}
                {...rest}
            />
        </div>
	)
}

FilterOrderBy.propTypes = {
	options: PropTypes.array,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
		PropTypes.array,
		PropTypes.number
	]),
    orderDir: PropTypes.string,
	onChange: PropTypes.func,
	onOrderDirChange: PropTypes.func,
	style: PropTypes.object,
	className: PropTypes.string,
	placeholder: PropTypes.string,
	listHeight: PropTypes.number,
	listItemHeight: PropTypes.number,
	dropdownStyle: PropTypes.object,
	dropdownMinWidth: PropTypes.string
}
