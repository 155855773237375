import UsersStore from './users'
import SprintsStore from './sprints'
import UiLstSprintsStore from './ui-lst-sprints'
import WorkProgressStore from './work-progress'
import SubscriptionsStore from './subscriptions'
import AppTypeStore from './AppTypeStore'
import UiLstRequestsStore from './ui-lst-requests'
import RequestsStore from './requests'
import TourStore from './tour'
import { action, makeObservable, observable, runInAction } from 'mobx'
import UiLstMonthlyReportsStore from './ui-lst-monthly-reports'
import MonthlyReportsStore from './monthly-reports'
import { getCookie } from '../utils'
import SecuredStore from './secured'

class RootStore {
	categories = {
		1: { name: 'Marketing' },
		2: { name: 'Programming' },
		3: { name: 'Web Design' },
		4: { name: 'Graphic Design' },
		5: { name: 'Content Writing' },
		6: { name: 'Accountancy' },
		7: { name: 'Other' }
	}

	priorityOptions

	/**
	 * Specific id's will be added to the array
	 * If the id is in the array, the interval will start and the page will load data on interval set for that page
	 * If the id is not in the array, the interval will stop and the page will not load data on interval set for that page
	 * @type {Array}
	 * @memberof RootStore
	 */
	intervalLoad = []

	constructor() {
		makeObservable(this, {
			intervalLoad: observable,
			hasIntervalLoad: observable,
			toggleIntervalLoad: action.bound
		})
		this.tourStore = new TourStore()
		this.appType = new AppTypeStore(this)
		this.users = new UsersStore(this)
		this.subscriptions = new SubscriptionsStore(this)

		this.sprints = new SprintsStore(this)
		this.uiLstSprints = new UiLstSprintsStore(this)

		this.monthlyReports = new MonthlyReportsStore(this)
		this.uiLstMonthlyReports = new UiLstMonthlyReportsStore(this)

		this.requests = new RequestsStore(this)
		this.uiLstRequests = new UiLstRequestsStore(this)

		this.workProgress = new WorkProgressStore(this)
		this.secured = new SecuredStore(this)

		// TODO: Add this in utils not in constructor
		this.priorityOptions = [
			{
				key: 0,
				label: 'Low',
				value: 0
			},
			{
				key: 1,
				label: 'Medium',
				value: 1
			},
			{
				key: 2,
				label: 'High',
				value: 2
			},
			{
				key: 3,
				label: 'Urgent',
				value: 3
			}
		]
	}

	shouldShowCookiePopover = () => {
		const loggedInUserId = this.users.loggedInUserId

		// Check if there is a logged-in user
		if (loggedInUserId) {
			const user = this.users.requireUserById(loggedInUserId)

			// Check if the user has a cookie consent stored in their preferences
			if (user && user.cookieConsent !== undefined) {
				return false
			}
		}

		// If the user is not logged in or has no cookie consent set, check the cookie
		const cookieConsent = getCookie('cookieConsent')
		if (cookieConsent !== null) {
			return false
		}

		// If no cookie consent is set, show the popover
		return true
	}

	toggleIntervalLoad = (id) => {
		runInAction(() => {
			const index = this.intervalLoad.indexOf(id)
			if (index === -1) {
				this.intervalLoad.push(id)
			} else {
				this.intervalLoad.splice(index, 1)
			}
		})
	}

	hasIntervalLoad = (id) => {
		return this.intervalLoad.includes(id)
	}

	get userAllowedCategories() {
		const loggedInUserId = this.users.loggedInUserId
		const user = this.users.requireUserById(loggedInUserId)

		const plans = this.subscriptions.plans
		const userSubscriptions = user.subscriptions
		const userPermissions = user.allowed

		const userAllowedCategoryIds = Object.keys(
			userPermissions?.categories || {}
		)

		let allowedCategories = {}
		userSubscriptions.forEach((sub) => {
			const subscriptionId = sub.items.data[0].price.id
			const plan = plans.find((p) => p.id === subscriptionId)

			if (plan && sub.status === 'active') {
				const planCategoryName = plan.product.metadata.category
				const categoryId = Object.keys(this.categories).find(
					(key) => this.categories[key].name === planCategoryName
				)

				allowedCategories = {
					...allowedCategories,
					[categoryId]: this.categories[categoryId]
				}
			}
		})

		userAllowedCategoryIds.forEach((categoryId) => {
			if (!this.categories[categoryId]) {
				return
			}

			// Check if permission for category is in demo, if true, add to allowed categories
			const demo = userPermissions.categories[categoryId]?.demo
			if (demo === 'true' || demo === true) {
				allowedCategories = {
					...allowedCategories,
					[categoryId]: this.categories[categoryId]
				}
			}
		})

		return allowedCategories
	}
}

const rootStore = new RootStore()
export default rootStore
