import React, { useEffect, useState } from 'react'
import { Spin, Typography } from 'antd'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { useStores } from '../stores/MobXProvider'
import { toJS } from 'mobx'
import ActiveSubscriptions from '../components/Stripe/ActiveSubscriptions'
import { actLoadMonthlyReports } from '../stores/ui-lst-monthly-reports/actions'
import WebcamCapture from '../components/Resourcess/WebcamCapture'
const { Title } = Typography

const AppDashboard = () => {
	const navigate = useNavigate()
	const [loadingItm, setLoadingItm] = useState(true)

	const { users, uiLstSprints, appType } = useStores()
	const { appIsClient } = appType

	const { loggedInUserId } = users
	const user = users.requireUserById(loggedInUserId)

	useEffect(() => {
		actLoadMonthlyReports().catch(console.error)

		if (!users.isLoggedIn) {
			navigate('/logout')
			return
		}

		setLoadingItm(false)
	}, [loggedInUserId])

	if (loadingItm) {
		return (
			<div style={{ textAlign: 'center' }}>
				<p>Loading...</p>
				<Spin />
			</div>
		)
	}

	console.log('Loggedin user:', toJS(user.allowed))

	return (
		<>
			{loggedInUserId && (
				<>
					<Title
						level={4}
						style={{ fontSize: 25, marginTop: 0, marginBottom: 10 }}
					>
						Dashboard: {uiLstSprints.count} sprints
					</Title>
					<WebcamCapture onUpload={() => {}} />
					{appIsClient && <ActiveSubscriptions />}
				</>
			)}
		</>
	)
}

export default observer(AppDashboard)
