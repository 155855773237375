import React from "react";
import { Card, List, Typography } from "antd";
import { Link } from "react-router-dom";
import CustomList from "../../components/Shared/CustomList";

const { Title, Paragraph } = Typography;

const TermsAndConditions = () => {
  const introductionData = [
    { title: "Company name: Webackit Solutions S.R.L." },
    { title: "Address: Str. Splaiul Independenței, nr.202B, București, Romania" },
    { title: "Fiscal Number: 44553258" },
    { title: "VAT number: 44601134" },
    { title: "Commercial Register: J40/11711/2021" },
  ];

  const servicesData = [
    { title: "Our website allows clients to select and order services. These selections and orders are considered as initial estimates and are not final." },
    { title: "After an order is placed, Webackit Solutions S.R.L. will contact the client to discuss and finalize terms, specifications, and prices." },
    { title: "A formal contract will be drafted for each order upon mutual agreement in the post-order discussion. Work will commence only after the contract is signed and the invoice issued is paid in full." },
    { title: "Webackit Solutions S.R.L. reserves the right to decline an order based on the post-order discussion outcomes." },
  ];

  const pricingData = [
    { title: "Prices listed on our website are estimates and subject to change following the post-order discussion." },
    { title: "Payment terms will be specified in the contract for each order. Full payment is required before any work begins." },
    { title: "Webackit Solutions S.R.L. may need to engage external partners or contractors to fulfill certain services. These partnerships are secured with the client’s advance payment." },
  ];

  const copyrightData = [
    { title: "The content, logo, and other intellectual property on our website are owned by Webackit Solutions S.R.L. and are protected by copyright and trademark laws." },
    { title: "Unauthorized use of our intellectual property is strictly prohibited." },
    {
      title: "Registered trademark:",
      link: (
        <a
          href="https://euipo.europa.eu/eSearch/#details/trademarks/018874290"
          target="_blank"
          rel="noreferrer"
        >
          webackit
        </a>
      )
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <Title level={2} style={{ textAlign: "center" }}>
        Terms and Conditions for Webackit Solutions S.R.L.
      </Title>

      {/* Introduction Section */}
      <Title level={3}>Introduction</Title>
      <Paragraph>
        Welcome to Webackit Solutions S.R.L. By using our website and services,
        you agree to these Terms and Conditions. Please read them carefully.
      </Paragraph>
      <CustomList
        dataSource={introductionData}
        renderItem={(item) => (
          <List.Item>
            {item.title}
          </List.Item>
        )}
      />

      {/* Services and Contract Formation Section */}
      <Card>
        <Title level={3}>Services and Contract Formation</Title>
        <CustomList

          dataSource={servicesData}
          renderItem={(item) => (
            <List.Item>
              {item.title}
            </List.Item>
          )}
        />
      </Card>

      {/* Pricing and Payments Section */}
      <Title level={3}>Pricing and Payments</Title>

      <CustomList
        dataSource={pricingData}
        renderItem={(item) => (
          <List.Item>
            {item.title}
          </List.Item>
        )}
      />

      {/* Quality Guarantee Section */}
      <Card>
        <Title level={3}>Quality Guarantee</Title>
        <Paragraph>
          Webackit Solutions S.R.L. guarantees premium quality for all services
          provided.
        </Paragraph>
      </Card>

      {/* Copyright and Trademark Section */}
      <Title level={3}>Copyright and Trademark</Title>
      <CustomList
        dataSource={copyrightData}
        renderItem={(item) => (
          <List.Item>
            {item.title} {item.link && item.link}
          </List.Item>
        )}
      />

      {/* Governing Law and Limitation of Liability Section */}
      <Card>
        <Title level={3}>Governing Law</Title>
        <Paragraph>
          These Terms and Conditions are governed by and construed in accordance
          with the laws of Romania.
        </Paragraph>

        <Title level={3}>Limitation of Liability</Title>
        <Paragraph>
          Webackit Solutions S.R.L. is not liable for any indirect, special, or
          consequential damages arising out of or in connection with the use of
          our website or services.
        </Paragraph>
      </Card>

      {/* Changes to Terms and Conditions Section */}
      <Title level={3}>Changes to Terms and Conditions</Title>
      <Paragraph>
        Webackit Solutions S.R.L. reserves the right to modify these Terms and
        Conditions at any time. We will notify users of significant changes.
      </Paragraph>

      {/* Privacy Policy Section */}
      <Card>
        <Title level={3}>Privacy Policy</Title>
        <Paragraph>
          For information on how Webackit Solutions S.R.L. collects, uses,
          stores, and protects your personal data, please refer to our{" "}
          <Link to="/privacy-policy">Privacy Policy</Link>. Our Privacy Policy
          outlines our practices regarding data processing and explains the
          rights you have in relation to your personal information. We encourage
          you to review our Privacy Policy to better understand how we maintain
          your privacy and protect your data.
        </Paragraph>
      </Card>

      {/* Contact Information Section */}
      <Title level={3}>Contact Information</Title>
      <Paragraph>
        For any inquiries or questions regarding these Terms and Conditions,
        please contact us at:
      </Paragraph>
      <Paragraph>Email: contact@webackit.com</Paragraph>
      <Paragraph>Phone: +40 748 702 852</Paragraph>
    </div>
  );
};

export default TermsAndConditions;
