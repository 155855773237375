import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Routes, Route, useNavigate } from 'react-router-dom'
import { useStores } from '../../stores/MobXProvider'
import SecuredSearch from './SecuredSearch'

const SprintsMain = () => {
	const navigate = useNavigate()
	const { users } = useStores()
	const { loggedInUserId } = users
	useEffect(() => {
		if (!users.isLoggedIn) {
			navigate('/logout')
			return
		}
	}, [loggedInUserId])

	return (
		<Routes>
			<Route path="/" element={<SecuredSearch />} />
			<Route path="*" element={<div>Not found</div>} />
		</Routes>
	)
}
export default observer(SprintsMain)
