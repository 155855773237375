import { LstTypeBase } from './cls-lst-type-base'
import { makeObservable } from 'mobx'
export class SecuredItm {
	constructor(data) {
		// Initialize Secured item properties from data
		Object.assign(this, data)
	}
}

export class Secured extends LstTypeBase {
	constructor(list = []) {
		// Pass the Secured class and list to the parent class constructor
		super(SecuredItm, list)

		makeObservable(this, {
			// Add properties here if Secured has specific properties
		})
	}
}
