import React, { useState } from 'react'
import {
	Button,
	List,
	Modal,
	Form,
	Input,
	Tooltip,
	Tag,
	Space,
	Row,
	Col,
	Image,
	InputNumber
} from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react-lite'
import { Comment, Comments } from '../../modules/cls/comments'
import { useStores } from '../../stores/MobXProvider'
import { FormatedTimestamp, UserString } from '../Shared'
import { API_BASE_URL } from '../../config'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import UploadImage from '../Shared/UploadImage' 
import { nextSortId } from '../../utils'

const { TextArea } = Input

const EditComment = observer(({ comment, onSave, getNextSortId, onCancel }) => {
	const { users } = useStores()
	const { loggedInUserId } = users

	const [isModalVisible, setIsModalVisible] = useState(true)
	const editingComment =
		comment ||
		new Comment({
			description: '',
			sortId: getNextSortId(),
			images: [],
			createdBy: loggedInUserId
		})

	const [fileList, setFileList] = useState(
		editingComment.images.map((image) => ({
			uid: image,
			name: image,
			status: 'done',
			url: `${API_BASE_URL}/images/uploads/${image}`
		})) || []
	)
	const handleFileListChange = (newFileList) => {
		setFileList(
			newFileList.map((file) => ({
				uid: file.uid,
				name: file.name,
				status: file.status,
				url: file.url
			}))
		)
		editingComment.images = newFileList.map((file) => file.uid)
	}

	return (
		<Modal
			title={editingComment ? 'Edit Comment' : 'Add Comment'}
			open={isModalVisible}
			onCancel={() => {
				setIsModalVisible(false)
				onCancel()
			}}
			footer={null}
		>
			<Form
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				initialValues={editingComment || {}}
				onFinish={(values) => {
					const completeValues = values
					completeValues.images =
						fileList.map((file) => file.uid) || []
					onSave(completeValues)
				}}
			>
				<Form.Item
					label="Sort Id"
					name="sortId"
					rules={[
						{
							required: true,
							message: 'Please enter sortId'
						}
					]}
				>
					<InputNumber min={1}/>
				</Form.Item>
				<Form.Item
					label="Description"
					name="description"
					rules={[
						{ required: true, message: 'Please enter description' }
					]}
				>
					<TextArea rows={4} showCount maxLength={300} />
				</Form.Item>
				<Form.Item
					label="Related Link"
					name="web"
					rules={[
						{
							pattern: new RegExp(/^(https?):\/\/([\w.-]+(\/\S*)?)$/),
							message: "Must start with HTTP or HTTPS and follow URL format. Please check the format."
						}
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item label="Upload Images">
					<UploadImage
						fileList={fileList}
						onFileListChange={handleFileListChange}
					/>
				</Form.Item>

				<Form.Item>
					<Button type="primary" htmlType="submit">
						{editingComment ? 'Update' : 'Add'}
					</Button>
				</Form.Item>
			</Form>
		</Modal>
	)
})

const CommentsInput = observer(({ createdBy, value, onChange, ...props }) => {
	const { users } = useStores()
	const { loggedInUserId, loggedInUser } = users
	const isSuperAdmin = loggedInUser.isSuperAdmin

	const { disabled } = props
	// console.log('CommentsInput -> value', value)
	const comments = new Comments(value || [])
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [editingComment, setEditingComment] = useState(null)

	const showModal = () => {
		setEditingComment(null)
		setIsModalVisible(true)
	}

	const save = () => {
		onChange(comments.prepareForSave())
	}

	const getNextSortId = () => {
		return nextSortId(value);
	}

	const onSaveComment = (values) => {
		if (editingComment) {
			editingComment.description = values.description
			editingComment.sortId = values.sortId
			editingComment.web = values.web
			comments.updateComment(editingComment)
		} else {
			comments.addComment(
				values.description, 
				values.sortId, 
				values.web, 
				values.images, 
				createdBy
			)
		}
		save()
		setIsModalVisible(false)
	}

	const handleEdit = (commentId) => {
		const comment = comments.getCommentById(commentId)
		if (isSuperAdmin || loggedInUserId === comment.createdBy) {
			setEditingComment(comment)
			setIsModalVisible(true)
		} else {
			alert('You are not allowed to edit this comment')
		}
	}

	const handleDelete = (commentId) => {
		const comment = comments.getCommentById(commentId)
		if (isSuperAdmin || loggedInUserId === comment.createdBy) {
			comments.deleteComment(commentId)
			save()
		} else {
			alert('You are not allowed to delete this comment')
		}
	}

	return (
		<>
			<List
				header={
					!disabled && (
						<Button type="dashed" onClick={showModal}>
							Add Comment
						</Button>
					)
				}
				bordered
				dataSource={comments.sortedList}
				renderItem={(comment) => (
					<List.Item
						actions={
							!disabled &&
							(isSuperAdmin ||
								loggedInUserId === comment.createdBy)
								? [
										<Button
											key={comment.id + 'edit'}
											type="link"
											onClick={() =>
												handleEdit(comment.id)
											}
										>
											<EditOutlined />
										</Button>,
										<Button
											key={comment.id + 'delete'}
											type="link"
											onClick={() =>
												handleDelete(comment.id)
											}
										>
											<DeleteOutlined />
										</Button>
								  ]
								: []
						}
					>
						<Space direction="vertical">
							<Row gutter={5} align="middle">
								<Col>
									{loggedInUserId === comment.createdBy ? (
										<Tooltip title="Self comment">
											<Tag color="warning">
												<UserOutlined />
											</Tag>
										</Tooltip>
									) : (
										<Tooltip title="Comment by">
											<Tag color="default">
												<UserString
													userId={comment.createdBy}
													path="name"
												/>
											</Tag>
										</Tooltip>
									)}
								</Col>
								<Col
									style={{
										color: 'grey',
										fontSize: '12px'
									}}
								>
									<Tooltip title="Created on">
										<span>
											<FormatedTimestamp
												timestamp={comment.createdTs}
												formatedAs="DD.MM.YYYY hh:mm"
											/>
										</span>
									</Tooltip>
								</Col>
							</Row>
							<div>{comment.sortId} - {comment.description}</div>
							{comment?.images.length > 0 && (
								<div>
									{comment?.images &&
										comment?.images.map((imagePath) => (
											<Image
												key={imagePath}
												width={100}
												src={`${API_BASE_URL}/images/uploads/${imagePath}`}
											/>
										))}
								</div>
							)}
							{
								comment?.web &&
								<a
									href={comment?.web}
									target="_blank"
									rel="noreferrer"
								>
									Open link
								</a>
							}
						</Space>
					</List.Item>
				)}
			/>
			{isModalVisible && (
				<EditComment
					comment={editingComment}
					getNextSortId={getNextSortId}
					onSave={onSaveComment}
					onCancel={() => setIsModalVisible(false)}
				/>
			)}
		</>
	)
})

export default CommentsInput
