import { addGETParams } from '../config'
import ax from './api'

/*
 * getSecured - Get a secured item by name and key
 * @param {string} name - The name of the secured item
 * @param {string} key - The decryption key provided by the user
 * @returns {object} The encrypted secured data if the search is successful
 */
export const getSecured = async (name, key) => {
	const _url = addGETParams(`/secured/get.php`, { name, key })

	const { data } = await ax.get(_url)
	return data
}

/*
 * setSecured - Set a secured item on the server (create or update)
 * @param {string} securedId - The id of the secured item
 * @param {string} name - The name of the secured item
 * @param {object} full - The full (encrypted) data of the secured item
 * @returns {object} The response from the server
 */
export const setSecured = async (securedId, name, full, key) => {
	const _url = `/secured/set.php`

	// Prepare the payload for the POST request
	const payload = {
		secured_id: securedId,
		name,
		full,
		key
	}

	// Send the POST request
	return await ax.post(_url, payload)
}
