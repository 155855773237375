import { getSecured, setSecured } from '../../../modules/api-secured'
import { wrapWithLoading } from '../../general-actions'
import { responseHasError } from '../../users/actions'


/**
 * @async
 * @function actLoadSecured
 * @description Loads a secured item from the server by its ID and updates the store.
 * @param { function } navigate - The navigation function.
 * @param {string} securedId - The ID of the secured item to load.
 * @returns {Promise<void>}
 */
export const actLoadSecured = wrapWithLoading(
	async (name, key) => {
		const _response = await getSecured(name, key)

		const hasError = responseHasError(_response)
		if (!hasError && _response.status === 'success') {
			return _response
		} else {
			throw _response
		}
	},
	{ loadingMessage: 'Loading secured item' },
	true // Activate rethrow to propagate errors
)

/**
 * @async
 * @function actSaveSecured
 * @description Saves a secured item to the server.
 * @param {string} securedId - The ID of the secured item to save.
 * @param {Object} full - The full data of the secured item to save.
 * @returns {Promise<boolean>} True if the save was successful, false otherwise.
 */
export const actSaveSecured = wrapWithLoading(
	async (securedId, name, full, key) => {
		const _response = await setSecured(securedId, name, full, key)

		const { data } = _response

		const hasError = data ? responseHasError(data) : true
		if (hasError) {
			throw data
		} else {
			console.log('actSaveSecured() response:', data)
		}

		return true // No exceptions were thrown, so return true
	},
	{ loadingMessage: 'Saving secured item' }
)
