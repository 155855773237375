// Define the available environment options
const environmentOptions = ['dev', 'production']

// Set the current application environment
export const appEnvironment = environmentOptions[1]

export const APP_TYPE = {
	client: 'on-subscription.com',
	team: 'team.webackit.com'
}

// Define the base URL for API requests based on the current environment
export const API_BASE_URL =
	appEnvironment === 'production'
		? 'https://api.webackit.com'
		: 'http://localhost/on-subscription-api/'

export const LOGIN_API_TOKEN_EXPIRATION = 86400 // 24 hours

// Define constants for various application states
export const IS_NEW = -2
export const DO_LOAD = -1

// Define default pagination values
export const DEFAULT_PG = 1
export const DEFAULT_RPG = 25

// Define whether to show warnings in development mode
export const WARN_PT = true && process.env.NODE_ENV === 'development'

export const DATE_FORMAT = 'DD.MM.YYYY'
export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm'

export const PLAN_FOR_DELETION_NR_OF_DAYS = {
	sprints: 10,
	requests: 10,
	monthly_reports: 10
}

/**
 * Logs a warning message if any of the provided properties are undefined.
 * This function includes the identifier and caller information in the warning message.
 *
 * @param {string} identificator - Identifier for the warning message.
 * @param  {...any} props - Properties to check for undefined values.
 */
export function warnPt_(identificator, ...props) {
	if (WARN_PT) {
		const err = new Error()
		const callerLine = err.stack.split('\n')[2] // Get the caller line
		const match = callerLine.match(/at (.*?) /)
		const callerName = match ? match[1].trim() : 'Unknown caller'

		const undefinedProps = props
			.map((prop, index) =>
				prop === undefined ? `param-${index + 1}` : null
			)
			.filter((name) => name !== null)

		if (undefinedProps.length > 0) {
			console.warn(
				`${identificator} :: ${callerName} has undefined ::`,
				...undefinedProps
			)
		}
	}
}

/**
 * Logs a warning message if any of the provided properties are undefined.
 * This function includes the file and caller information in the warning message.
 *
 * @param  {...any} props - Properties to check for undefined values.
 */
export function warnPt(...props) {
	if (WARN_PT) {
		const err = new Error()
		const stackLines = err.stack.split('\n')

		// Extracting the caller line
		const callerLine = stackLines[2]
		const callerMatch = callerLine.match(/at (.*?) /)
		const callerName = callerMatch
			? callerMatch[1].trim()
			: 'Unknown caller'

		// Extracting and formatting file name
		const fileMatch = callerLine.match(/\/(.*?):\d+:\d+/)
		let fileName = fileMatch
			? fileMatch[1].split('/').pop()
			: 'Unknown file'

		// Formatting the filename according to specified rules
		fileName = fileName
			.replace(/.chunk\.js$/, '') // Remove 'chunk.js' if at the end
			.replace(/index_js/, 'index.js') // Replace 'index_js' with 'index.js'
			.replace(/_/g, '/') // Replace '_' with '/'
			.replace(
				'js-node/modules/ant-design/icons/es/icons/MinusCircleOutlined/js',
				''
			)

		const undefinedProps = props
			.map((prop, index) =>
				prop === undefined ? `param-${index + 1}` : null
			)
			.filter((name) => name !== null)

		if (undefinedProps.length > 0) {
			console.warn(
				`${fileName} :: ${callerName} has undefined ::`,
				...undefinedProps
			)
		}
	}
}

/**
 * Appends filter parameters to a given URL.
 * Ensures the URL structure remains intact by encoding each parameter.
 *
 * @param {string} url - The base URL to which filter parameters will be added.
 * @returns {string} - The URL with appended filter parameters.
 */
export const urlWithFilterParams = (url) => {
	const filter = {}
	let urlWithParams = url.indexOf('?') !== -1 ? url : url + '?' // Ensure the URL has a starting query parameter
	const params = Object.keys(filter)
		.filter((key) => filter[key] !== undefined && filter[key] !== null)
		.map((key) => {
			return `${encodeURIComponent(key)}=${encodeURIComponent(
				filter[key]
			)}`
		})
	urlWithParams += params.join('&')
	return urlWithParams
}

/**
 * Adds GET parameters to the specified URL.
 *
 * @param {string} url - The base URL to which GET parameters will be added.
 * @param {object} params - The parameters to add to the URL.
 * @returns {string} - The URL with appended GET parameters.
 */
export const addGETParams = (url, params) => {
	let urlWithParams = url

	// Check if the URL already contains parameters
	const hasParams = url.indexOf('?') !== -1

	const _params = Object.keys(params)
		.filter((key) => params[key] !== undefined && params[key] !== null)
		.map((key) => {
			return `${encodeURIComponent(key)}=${encodeURIComponent(
				params[key]
			)}`
		})

	if (_params.length > 0) {
		urlWithParams += hasParams ? '&' : '?'
		urlWithParams += _params.join('&')
	}

	return urlWithParams
}
/**
 * Recursively cleans an object by removing properties with empty values, empty arrays, empty objects, or empty keys.
 * @param {Object} obj - The object to clean.
 * @returns {Object} The cleaned object.
 */
export function cleanObject(obj) {
	if (typeof obj !== 'object' || obj === null) {
		return obj
	}

	// Iterate over all object keys
	Object.keys(obj).forEach((key) => {
		const value = obj[key]
		if (key.trim() === '') {
			delete obj[key]
		} else if (Array.isArray(value)) {
			// Remove empty arrays
			if (value.length === 0) {
				delete obj[key]
			}
		} else if (
			typeof value === 'object' &&
			value !== null &&
			!(value instanceof Date)
		) {
			// Recurse for nested objects
			cleanObject(value)
			// Remove now-empty objects
			if (Object.keys(value).length === 0) {
				delete obj[key]
			}
		} else if (value === '' || value === null || value === undefined) {
			// Remove empty strings and null/undefined values
			delete obj[key]
		}
	})

	return obj
}

export function dateTimeNow() {
	return new Date()
		.toLocaleString('ro-RO')
		.replace(',', '')
		.replace(/:.. /, ' ')
}
