import React, { useEffect } from 'react'
import { Form, Input, Button, Typography } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { v4 as uuidv4 } from 'uuid'
import { register } from '../modules/api-user'
import { useStores } from '../stores/MobXProvider'
import { startError } from '../stores/work-progress/actions'

const { Text } = Typography // Import Typography for text styling

const UserRegister = () => {
	const { users, appType } = useStores()
	const { appIsClient, appIsTeam } = appType

	const [form] = Form.useForm()
	const navigate = useNavigate()

	const curentUser = users.loggedInUserId
	useEffect(() => {
		if (curentUser) {
			// Redirect to dashboard if user is already logged in
			navigate('/dashboard')
		}
	}, [curentUser, navigate])

	const onFinish = async (values) => {
		const newId = uuidv4()
		values.user_id = newId
		if (appIsClient) {
			values.user_type = 'client'
		} else if (appIsTeam) {
			values.user_type = 'team'
		}

		// check password match
		if (values.password !== values.password_match) {
			startError('Passwords do not match')
			return
		}

		try {
			register(values)
				.then((response) => {
					if (response.status === 'success') {
						const userId = response.user.user_id

						users.setLoggedInUser(userId, response.apiToken)
						const user = users.requireUserById(userId)
						user.updateFromServer(response.user)
						navigate('/dashboard')
					} else {
						startError(response.message)
					}
				})
				.catch((error) => {
					console.error('Failed to fetch user info:', error)
				})
		} catch (error) {
			alert('Registration failed, please try again later.')
			console.error('Registration error:', error)
		}
	}

	return (
		<Form form={form} name="register" onFinish={onFinish} layout="vertical">
			<Form.Item
				name="email"
				label="Email"
				rules={[
					{
						required: true,
						message: 'Please input your Email!',
						type: 'email'
					}
				]}
			>
				<Input placeholder="Email" />
			</Form.Item>
			<Form.Item
				name="password"
				label="Password"
				rules={[
					{ required: true, message: 'Please input your Password!' }
				]}
			>
				<Input.Password placeholder="Password" />
			</Form.Item>
			<Form.Item
				name="password_match"
				label="Password match"
				rules={[
					{
						required: true,
						message: 'Please input your password confirmation!',
						validator: (_, value) => {
							if (
								!value ||
								form.getFieldValue('password') === value
							) {
								return Promise.resolve()
							}
							return Promise.reject(
								new Error(
									'The two passwords that you entered do not match!'
								)
							)
						}
					}
				]}
			>
				<Input.Password placeholder="Password" />
			</Form.Item>
			<Form.Item>
				<Button type="primary" htmlType="submit">
					Register
				</Button>
			</Form.Item>
			<Form.Item>
				<Text style={{ display: 'block', textAlign: 'center' }}>
					Already have an account? <Link to="/login">Login now</Link>
				</Text>
			</Form.Item>
		</Form>
	)
}

export default observer(UserRegister)
