import { makeAutoObservable } from 'mobx'
// TODO: decide if by user choice we will display a list of secured items titles
/**
 * @class SecuredStore
 * @description This class represents the store for secured. It manages the state of secured and is part of the root store.
 */
class SecuredStore {
	/**
	 * @property {Object} secured - An object containing secured indexed by their IDs.
	 */
	secured = {}

	/**
	 * @constructor
	 * @param {Object} rootStore - The root store that holds the global state and other stores.
	 * @description Constructs an instance of SecuredStore and makes it observable.
	 */
	constructor(rootStore) {
		this.rootStore = rootStore
		makeAutoObservable(this)
	}
}

export default SecuredStore
