import { makeAutoObservable } from 'mobx'

/**
 * @class MonthlyReportsStore
 * @description This class represents the store for reports. It manages the state of reports and is part of the root store.
 */
class MonthlyReportsStore {
	/**
	 * @property {Object} reports - An object containing reports indexed by their IDs.
	 */
	reports = {}

	/**
	 * @constructor
	 * @param {Object} rootStore - The root store that holds the global state and other stores.
	 * @description Constructs an instance of MonthlyReportsStore and makes it observable.
	 */
	constructor(rootStore) {
		this.rootStore = rootStore
		makeAutoObservable(this)
	}
}

export default MonthlyReportsStore
