import React, { useState, useEffect } from 'react'
import { Space, Modal, Spin, Drawer, Table } from 'antd'
import { HistoryOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { observer } from 'mobx-react-lite'
import { runInAction, toJS } from 'mobx'
import { FormatedTimestamp } from '../../Shared/FormatedTimestamp'
import { DATE_TIME_FORMAT, DO_LOAD } from '../../../config'
import ProgressInput from '../ProgressInput'
import { Subtasks } from '../../../modules/cls/subtasks'
import { useStores } from '../../../stores/MobXProvider'
import { SubtasksList } from './SubtasksList'
import { UserString } from '../../Shared'

/**
 *
 * @ProgressModal
 * @param {CustomClass} CustomClass
 * @param {string} modalKey
 * @param {object} uiStore
 * @param {function} requireItemById
 * @param {function} loadItm
 * @param {function} saveItem
 *
 * @returns {JSX.Element}
 */
const ProgressModal = ({
	CustomClass = Subtasks,
	modalKey,
	uiStore,
	requireItemById,
	loadItm,
	saveItem,
	isForRequest=false
}) => {
	const { users } = useStores()
	const { loggedInUserId, loggedInUser } = users
	const isAdmin = loggedInUser.isAdmin
	const isSuperAdmin = loggedInUser.isSuperAdmin

	const [loadingItm, setLoadingItm] = useState(true)
	const cleanModalObj = { visible: false, itmId: null }

	const { visible: historyIsOpen, item: historyValue } = get(
		uiStore,
		['modals', 'history-modal'],
		{ visible: false, item: {} }
	)

	const { visible, itmId } = get(uiStore.modals, modalKey, cleanModalObj)
	const _itm = itmId ? requireItemById(itmId) : null

	// LOAD ITM
	useEffect(() => {
		if (itmId) {
			if (_itm.loaded === DO_LOAD) {
				loadItm(itmId).finally(() => {
					setLoadingItm(false)
					// console.log('Curent COMPACT:', toJS(_itm.compact))
					// console.log('Curent FULL:', toJS(_itm.full))
					// console.log('Curent HISTORY:', toJS(_itm.history))
				})
			} else {
				setLoadingItm(false)
			}
		}
	}, [
		itmId
		// , _itm?.loaded
	])

	// 3. EXIT IF NO ITEM
	if (!_itm) return null

	// RECURRING
	const withRecurrence = _itm.recurrenceType !== ''

	// 4. SAVE ITEM
	const _doSave = () => {
		runInAction(() => {
			if (_itm) {
				_itm.setRecurrence(toJS(_itm.recurrence))
				_itm.setHistoryInFull()

				// With recurrence, we also have to check if the next date is completed
				if (
					withRecurrence &&
					_itm.recurrenceNext &&
					// If the progress is set as completed, we need to check if the next date is completed
					_itm.progress === 'completed'
				) {
					// If it's not deadline, we need to check if the next date is completed
					if (_itm.recurrenceType !== 'deadline') {
						// If the next date is completed, we need to set the next recuring date
						_itm.recurrence.completed(_itm.recurrenceNext)
						_itm.setRecurrenceNext()
						_itm.recurrenceNext && _itm.setProgress('active')
					}
				}

				saveItem(itmId, toJS(_itm.full))
			}
		})
	}

	// SUBTASKS
	const subtasks = _itm.subtasks.list
	const withSubtasks = subtasks.length > 0

	// HISTORY
	const historyCategory = withRecurrence
		? //+ recurrence
		  withSubtasks
			? 'recurrence_subtasks'
			: 'recurrence'
		: // - recurrence
		withSubtasks
		? 'subtasks'
		: 'progress'

	const generateSubtaskFullHistory = (subtaskID, getAllValues = false) => {
		const subtaskHistory = _itm.history.getFullHistoryItem(
			historyCategory,
			subtaskID,
			getAllValues
		)
		// get subtask data and add history key with value
		const subtask = subtasks.find((subtask) => subtask.id === subtaskID)
		// return promise
		return new Promise((resolve) => {
			resolve({
				...subtask,
				history: subtaskHistory
			})
		})
	}
	const generateSubtasksHistory = (wait = false) => {
		const getSubtasksHistory = () => {
			const subtasksHistory = toJS(subtasks).map((subtask) => {
				const historyKey = withRecurrence
					? _itm.recurrenceNext + '_' + subtask.id
					: subtask.id

				const subtaskHistory = _itm.history.getHistoryItem(
					historyCategory,
					historyKey,
					true
				)

				if (!subtaskHistory) return subtask

				const { value, ...props } = subtaskHistory
				return {
					...subtask,
					status: value || 'active',
					history: props
				}
			})
			return subtasksHistory
		}

		if (wait) {
			return new Promise((resolve) => {
				resolve(getSubtasksHistory())
			})
		} else {
			return getSubtasksHistory()
		}
	}

	const subtasksHistory = withSubtasks ? generateSubtasksHistory() : null
	const _subtasks = new CustomClass(subtasksHistory || [])
	// console.log('_subtasks', withSubtasks, toJS(_subtasks))
	const generateSubtasksStatus = (usingSubtasks) => {
		return usingSubtasks.reduce(
			(acc, { status }) => {
				if (status === 'completed' || status === 'canceled') {
					acc.completed++
				} else if (status === 'pending') {
					acc.pending++
				} else {
					acc.active++
				}
				return acc
			},
			{ active: 0, pending: 0, completed: 0 }
		)
	}

	return (
		<>
			<Modal
				width={800}
				keyboard={true}
				open={visible}
				onCancel={() => {
					runInAction(() => {
						uiStore.setModal(modalKey, cleanModalObj)
					})
				}}
				title="Progress"
				footer={false}
				styles={{
					header: {
						textAlign: 'center',
						transform: 'scale(1.5)',
						background: 'unset'
					}
				}}
			>
				{loadingItm ? (
					<div style={{ textAlign: 'center' }}>
						<p>Loading...</p>
						<Spin />
					</div>
				) : (
					<>
						{
							// Canceled sprint
							_itm.progress === 'canceled' ? (
								<>
									<p style={{ textAlign: 'center' }}>
										Sprint was canceled{' '}
										<i>
											(if required, ask admin to restore)
										</i>
									</p>
									{isAdmin && (
										<ProgressInput
											type={isForRequest ? 'request' : 'sprint'}
											value={_itm.progress}
											onChange={(value) => {
												_itm.setProgress(value)
												_doSave()
											}}
										/>
									)}
								</> // With subtasks
							) : subtasks.length > 0 ? (
								// With subtasks + No Recurring
								_itm.recurrenceType === '' ? (
									<>
										<p>{_itm.displayName}</p>
										<SubtasksList
											uiStore={uiStore}
											getHistory={
												generateSubtaskFullHistory
											}
											_subtasks={_subtasks}
											_doSave={(subtaskId, value) => {
												runInAction(() => {
													_itm.history.addHistory(
														'subtasks',
														subtaskId,
														{
															value,
															createdBy:
																loggedInUserId
														}
													)
												})

												const curentSubtasks =
													generateSubtasksHistory()
												const curentSubtasksStatus =
													generateSubtasksStatus(
														curentSubtasks
													)
												const count =
													curentSubtasks.length
												if (
													curentSubtasksStatus[
														'completed'
													] === count
												) {
													_itm.setProgress(
														'completed'
													)
												} else if (
													curentSubtasksStatus[
														'pending'
													] > 0 ||
													curentSubtasksStatus[
														'completed'
													] > 0
												) {
													_itm.setProgress('pending')
												} else {
													_itm.setProgress('active')
												}
												_doSave()
											}}
										/>
									</>
								) : (
									// Without subtasks + Recurring
									_itm.recurrenceType !== '' && (
										<>
											<p>
												Recurring with subtasks:{' '}
												{_itm.displayName}
											</p>
											{_itm.recurrenceNext ? (
												<>
													<p>
														Date:
														<FormatedTimestamp
															timestamp={
																_itm.recurrenceNext
															}
														/>
													</p>
													<SubtasksList
														uiStore={uiStore}
														getHistory={
															generateSubtaskFullHistory
														}
														_subtasks={_subtasks}
														_doSave={(
															subtaskId,
															value
														) => {
															runInAction(() => {
																_itm.history.addHistory(
																	historyCategory,
																	_itm.recurrenceNext +
																		'_' +
																		subtaskId,
																	{
																		value,
																		createdBy:
																			loggedInUserId
																	}
																)

																const curentSubtasks =
																	generateSubtasksHistory()
																const curentSubtasksStatus =
																	generateSubtasksStatus(
																		curentSubtasks
																	)
																const count =
																	curentSubtasks.length
																if (
																	curentSubtasksStatus[
																		'completed'
																	] === count
																) {
																	_itm.setProgress(
																		'completed'
																	)
																} else if (
																	curentSubtasksStatus[
																		'pending'
																	] > 0 ||
																	curentSubtasksStatus[
																		'completed'
																	] > 0
																) {
																	_itm.setProgress(
																		'pending'
																	)
																} else {
																	_itm.setProgress(
																		'active'
																	)
																}
																_doSave()
															})
														}}
													/>
												</>
											) : (
												<p>Finished progress</p>
											)}
										</>
									)
								)
							) : // Not Recurring (0 subtasks)
							_itm.recurrenceType === '' ? (
								<>
									<p id="track-no-sub-no-rec">
										{_itm.displayName}
									</p>
									<Space>
										<ProgressInput
											type={isForRequest ? 'request' : 'sprint'}
											value={_itm.progress}
											onChange={(value) => {
												_itm.setProgress(value)
												// _itm.history.clearHistory('progress')
												_itm.history.addHistory(
													'progress',
													_itm.id,
													{
														value,
														createdBy:
															loggedInUserId
													}
												)
												_doSave()
											}}
										/>
										<HistoryOutlined
											key="info"
											onClick={() => {
												if (
													!!generateSubtaskFullHistory
												) {
													generateSubtaskFullHistory(
														_itm.id
													).then((item) => {
														runInAction(() => {
															uiStore.setModal(
																'history-modal',
																{
																	visible: true,
																	item
																}
															)
														})
													})
												}
											}}
										/>
									</Space>
								</>
							) : (
								// Recurring (0 subtasks)
								_itm.recurrenceType !== '' && (
									<>
										<p>
											Recurring: {_itm.displayName}{' '}
											{_itm.recurrenceNext}
										</p>
										{_itm.recurrenceNext ? (
											<>
												<p>
													Date:
													<FormatedTimestamp
														timestamp={
															_itm.recurrenceNext
														}
													/>
												</p>
												<Space>
													<ProgressInput
														type={isForRequest ? 'request' : 'sprint'}
														value={_itm.progress}
														onChange={(value) => {
															_itm.setProgress(
																value
															)
															_itm.history.addHistory(
																'recurrence',
																_itm.recurrenceNext,
																{
																	value,
																	createdBy:
																		loggedInUserId
																}
															)
															_doSave()
														}}
													/>
													<HistoryOutlined
														key="info"
														onClick={() => {
															if (
																!!generateSubtaskFullHistory
															) {
																generateSubtaskFullHistory(
																	_itm.recurrenceNext,
																	true
																).then(
																	(item) => {
																		runInAction(
																			() => {
																				uiStore.setModal(
																					'history-modal',
																					{
																						visible: true,
																						item
																					}
																				)
																			}
																		)
																	}
																)
															}
														}}
													/>
												</Space>
											</>
										) : (
											<p>Finished progress</p>
										)}
									</>
								)
							)
						}
					</>
				)}
			</Modal>

			<Drawer
				title="History"
				onClose={() =>
					runInAction(() => {
						delete uiStore.modals['history-modal']
					})
				}
				open={historyIsOpen}
			>
				<p>{historyValue.name}</p>

				<Table
					dataSource={
						historyValue.history?.map((item, index) => ({
							...item,
							key: index
						})) || []
					}
					columns={[
						{
							title: 'Status',
							dataIndex: 'value',
							key: 'value'
						},
						{
							title: 'Date',
							dataIndex: 'createdTs',
							key: 'createdTs',
							render: (createdTs) => (
								<FormatedTimestamp
									timestamp={createdTs}
									formatedAs={
										isSuperAdmin && DATE_TIME_FORMAT
									}
								/>
							)
						},
						{
							title: 'Action by',
							dataIndex: 'createdBy',
							key: 'createdBy',
							render: (createdBy) => {
								return (
									<UserString
										userId={createdBy}
										path="name"
									/>
								)
							}
						}
					]}
				/>
			</Drawer>
		</>
	)
}

ProgressModal.propTypes = {
	CustomClass: PropTypes.func,
	modalKey: PropTypes.string.isRequired,
	uiStore: PropTypes.object.isRequired,
	requireItemById: PropTypes.func.isRequired,
	loadItm: PropTypes.func.isRequired,
	saveItem: PropTypes.func.isRequired,
	isForRequest: PropTypes.bool,
}

export default observer(ProgressModal)
