import ax from './api'

export const getAvailablePlans = async () => {
	const { data } = await ax.get('/stripe/plans.php')
	return data
}

export const searchCustomers = async ({ email }) => {
	const { data } = await ax.post('/stripe/search-customers.php', {
		email
	})
	return data
}

/*
 * getStripeStatus - Get the status of the user's subscription from the server
 * @param {string} controlerId - Id of the user that has permission to view or controll specific list items
 * @param {object} params - Additional parameters to send to the server
 * @returns {object} The response from the server
 */
export const getStripeStatus = async ({ customerId }) => {
	// const _url = addGETParams(`/stripe/subscription-status.php`, {customerId})

	const { data } = await ax.post('/stripe/subscription-status.php', {
		customerId
	})
	return data
}

export const createCheckoutSession = async ({ customerId, email, priceId }) => {
	const { data } = await ax.post('/stripe/checkout-session.php', {
		customerId,
		email,
		priceId
	})
	return data
}

export const cancelSubscription = async (payload) => {
	const { data } = await ax.post('/stripe/cancel-subscription.php', payload)
	return data
}

export const resumeSubscription = async (payload) => {
	const { data } = await ax.post('/stripe/resume-subscription.php', payload)
	return data
}
