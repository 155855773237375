import { addGETParams } from '../config'
import ax from './api'
/*
* setSprint - Set a sprint item on the server
* @param {string} sprintId - The id of the sprint item
* @param {object} full - The full object of the sprint item
* @returns {object} The response from the server

*/
export const setSprint = async (sprintId, full) => {
	// Convert full object to JSON string
	const fullStr = JSON.stringify(full)

	// Send POST request with data in the body
	try {
		const response = await ax.post(`/sprint/set.php`, {
			sprint_id: sprintId,
			full: fullStr
		})
		return response
	} catch (error) {
		console.error('Error in setSprint:', error)
		throw error
	}
}

export const toTeamSprint = async (sprintId) => {
	const _url = addGETParams(`/sprint/to-team.php`, {
		sprint_id: sprintId
	})

	return await ax.post(_url)
}
