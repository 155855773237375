/* eslint-disable @typescript-eslint/no-unused-vars */
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import {
	Space,
	ConfigProvider,
	Form,
	Input,
	Card,
	Select,
	Row,
	Col,
	Tag,
	Tooltip,
	Switch,
	Button,
	InputNumber
} from 'antd'
import {
	WarningOutlined,
	InfoCircleOutlined,
	HistoryOutlined,
	EyeOutlined,
	SettingOutlined
} from '@ant-design/icons'

import Title from 'antd/es/typography/Title'
import { runInAction, toJS } from 'mobx'
import dayjs from 'dayjs'
import { isMobile } from 'react-device-detect'

import { IS_NEW } from '../../config'
import { actSaveSprint, requireSprintById } from '../../stores/sprints/actions'
import { useStores } from '../../stores/MobXProvider'

import {
	RecurrenceFormItems,
	CommentsInput,
	SubtasksInput,
	SubmitButton
} from '../../components/Form'
import {
	Features,
	FormatedTimestamp,
	UserString
} from '../../components/Shared'
import ProgressInput from '../../components/Form/ProgressInput'
import AllowedCategories from '../../components/Shared/AllowedCategories'
import AssignPlatformsInput from '../../components/Form/AssignPlatformsInput'
import { getTourArrayByKeys } from '../../stores/tour/config'
import '../../design/styles/patterns.css' // Create a CSS file to handle custom styles
import { GeneralTourTitle } from '../../components/Shared/GeneralComponents'
import ProgressModal from '../../components/Form/ProgressModal'

const SprintData = ({ viewOnly = false }) => {
	const navigate = useNavigate()
	const { itmId } = useParams()
	const {
		uiLstSprints,
		users,
		tourStore,
		hasIntervalLoad,
		toggleIntervalLoad,
		priorityOptions
	} = useStores()
	const { loggedInUserId, loggedInUser } = users
	const isAdmin = loggedInUser.isAdmin
	const isSuperAdmin = loggedInUser.isSuperAdmin

	const withIntervalLoad = hasIntervalLoad(itmId)

	const _itm = requireSprintById(itmId)
	// console.log('--- SprintData', toJS(_itm))

	const selfPermission =
		_itm.createdBy === loggedInUser.user_id || isAdmin || isSuperAdmin

	// If user has no permission to edit, set viewOnly to true
	if (!selfPermission && _itm.loaded !== IS_NEW) {
		viewOnly = true
	}

	// Function to generate initial recurrence form values
	const recurrenceInitialValues = (_itm) => ({
		recurrenceNext: _itm.recurrenceNext
			? dayjs.unix(_itm.recurrenceNext)
			: null,
		recurrenceType: _itm.recurrenceType,
		recurrenceStartEnd: _itm.recurrenceStartEnd.map((date) =>
			dayjs.unix(date)
		),
		recurrenceTimes: _itm.recurrenceTimes.map((time) =>
			dayjs(time, 'HH:mm')
		),
		recurrenceDaysOfWeek: _itm.recurrenceDaysOfWeek,
		recurrenceDayOfMonth: _itm.recurrenceDayOfMonth,
		recurrenceMonths: _itm.recurrenceMonths
	})

	// Function to generate initial form values
	const generateInitialFormValues = () => ({
		// user_id: _itm?.user_id || loggedInUserId,
		for_user_id: _itm?.for_user_id || loggedInUserId,
		category_id: _itm.category_id,
		name: _itm.name,
		progress: _itm.progress,

		// Generate initial values for recurrence fields
		...recurrenceInitialValues(_itm),

		subtasks: toJS(_itm.getSubtasks),
		comments: toJS(_itm.getComments),
		platformIds: _itm.platformIds,
		createdBy: _itm.createdBy || loggedInUserId,
		priority: _itm.priority || 0,
		effortEstimate: _itm.effortEstimate || 0
	})

	const _doSave = (vals) => {
		if (vals !== null) {
			runInAction(() => {
				// Set creator and timestamp
				!_itm.createdBy && _itm.setCreator(loggedInUserId)

				// Reset last full load
				_itm.loaded === IS_NEW && _itm.resetLastFullLoad()

				// Form values
				// _itm.setUser_id(vals.user_id)
				_itm.setForUser_id(vals.for_user_id)
				_itm.setPlatformIds(vals.platformIds)
				_itm.setCategory_id(vals?.category_id)
				_itm.setName(vals?.name)
				_itm.setProgress(vals.progress)
				isSuperAdmin && _itm.setCreatedBy(vals.createdBy)
				_itm.setPriority(vals.priority)
				_itm.setEffortEstimate(vals.effortEstimate)

				// Recurrence
				_itm.setRecurrenceType(vals.recurrenceType)
				_itm.setRecurrenceStartEnd(
					// save as array of timestamps
					vals.recurrenceStartEnd &&
						vals.recurrenceStartEnd.map((date) => date.unix())
				)
				_itm.setRecurrenceTimes(
					vals.recurrenceTimes &&
						vals.recurrenceTimes.map((time) => time.format('HH:mm'))
				)
				_itm.setRecurrenceDaysOfWeek(vals.recurrenceDaysOfWeek || [])
				_itm.setRecurrenceDayOfMonth(vals.recurrenceDayOfMonth || '')
				_itm.setRecurrenceMonths(vals.recurrenceMonths || [])

				if (
					vals.recurrenceType !== '' &&
					!_itm.recurrenceNext &&
					_itm.progress !== 'completed'
				) {
					_itm.setRecurrenceNext()
				}
				// Subtasks
				_itm.setSubtasks(vals.subtasks)
				// Comments
				_itm.setComments(vals.comments)
			})
			// console.warn('recurrence', toJS(_itm.full.recurrence))
			actSaveSprint(itmId, toJS(_itm.full))
				.then(() => {
					navigate('/sprints')
				})
				.catch(console.error)
		}
	}

	const [form] = Form.useForm()

	// Update form when _itm.modified changes
	useEffect(() => {
		form.setFieldsValue(generateInitialFormValues())
	}, [_itm.modified]) // Track _itm.modified

	const [formIsDisabled, setFormIsDisabled] = React.useState(
		!!_itm.to_delete ?? viewOnly
			? true
			: isAdmin
			? false
			: !['draft'].includes(_itm.progress)
	)

	// Track changes to _itm.to_delete to update form disabled state and tag visibility
	useEffect(() => {
		if (!viewOnly || !!_itm.to_delete) {
			withIntervalLoad && toggleIntervalLoad(itmId)
		}
		setFormIsDisabled(
			viewOnly
				? true
				: !!_itm.to_delete ??
						(isAdmin ? false : !['draft'].includes(_itm.progress))
		)
		form.setFieldsValue(generateInitialFormValues())
	}, [_itm.to_delete, viewOnly])

	const userIdTrack = Form.useWatch('for_user_id', form)

	const [features, setFeatures] = React.useState({
		recurrence: _itm.recurrenceType !== '',
		subtasks: toJS(_itm.getSubtasks)?.length > 0,
		comments: viewOnly ? true : toJS(_itm.getComments)?.length > 0
	})

	//set default progress value to 'active' is user is admin or superAdmin for new sprint
	useEffect(() => {
		if (_itm.loaded == IS_NEW && (isAdmin || isSuperAdmin)) {
			form.setFieldsValue({
				progress: 'active'
			})
		}
	}, [isAdmin, isSuperAdmin])

	// on features change, set all related fields to empty
	const onFeaturesChange = (features) => {
		if (!features.recurrence) {
			form.setFieldsValue({
				recurrenceType: '',
				recurrenceStartEnd: [],
				recurrenceMonthsTrack: [],
				recurrenceDaysOfWeek: [],
				recurrenceDayOfMonth: '',
				recurrenceMonths: [],
				recurrenceTimes: []
			})
		}
		if (!features.subtasks) {
			form.setFieldsValue({
				subtasks: []
			})
		}
		if (!features.comments) {
			form.setFieldsValue({
				comments: []
			})
		}
	}

	const onSetProgressChange = () => {
		runInAction(() => {
			uiLstSprints.setModal('sprint-progress', {
				visible: true,
				itmId: itmId
			})
		})
	}

	/*
	 * Tour Reccurence
	 * This is a tour for the recurrence fields. Manually added to the tour due to possible scenario were list item might not have all recurrence types.
	 */
	let tourRecurrence = features.recurrence
		? getTourArrayByKeys('recurrence', ['section'])
		: []

	/*
	 * Tour features
	 * This is a tour for the features of the sprint.
	 */
	const tourFeatures =
		!viewOnly && !formIsDisabled
			? getTourArrayByKeys('features', ['all'])
			: []

	const tourForm = getTourArrayByKeys('form', [
		'section',
		'name',
		'category_id',
		'user_id',
		// 'for_user_id',
		'platformIds',
		'progress'
	])

	/**
	 * Start tour:
	 * This function starts the tour for the sprint form.
	 * It includes the general form, subtasks, comment and recurrence fields.
	 */
	const startTour = () => {
		tourStore.startTour('SprintData-tour', [
			!!!_itm.to_delete && {
				title: 'Sync on interval',
				description:
					'Enable or disable data synchronization on interval for the sprint. Any additions or modifications will be automatically updated even if working in multiple tabs or is updated by another user.',
				target: () => document.getElementById('target-interval-itm')
			},
			// Features
			...tourFeatures,

			// General info
			...getTourArrayByKeys('info', ['name', 'modified']),

			// General form
			...getTourArrayByKeys('form', ['section']),

			// Recurrence
			...tourRecurrence
		])
	}

	const startTourForm = () => {
		tourStore.startTour('SprintData-tour', [
			// General form
			...tourForm
		])
	}

	return (
		<Space direction="vertical" style={{ width: '100%' }}>
			<GeneralTourTitle
				titleProps={{ text: 'Sprint' }}
				buttonProps={{
					onClick: startTour
				}}
				rowProps={{
					justify: 'space-between'
				}}
			>
				{!!_itm.to_delete && (
					<Tooltip
						title={
							<>
								Delete on{' '}
								<FormatedTimestamp
									timestamp={_itm.toDeleteTs}
								/>
							</>
						}
					>
						<Tag color="red" icon={<WarningOutlined />}>
							Marked for deletion
						</Tag>
					</Tooltip>
				)}
				{!!!_itm.to_delete && (
					<Switch
						id="target-interval-itm"
						style={{ marginRight: 10 }}
						tooltip="Sync data on interval"
						value={withIntervalLoad}
						checkedChildren="Sync on"
						unCheckedChildren="Sync off"
						defaultChecked
						onChange={() => toggleIntervalLoad(itmId)}
					/>
				)}
			</GeneralTourTitle>

			{!viewOnly && !formIsDisabled && (
				<Features
					features={features}
					setFeatures={(val) => {
						setFeatures(val)
						onFeaturesChange(val)
					}}
				/>
			)}
			<Card
				title={
					<span id="target-info-name">
						{viewOnly ? (
							<Tooltip title="View sprint">
								<EyeOutlined id="target-mode" /> {_itm?.name}
							</Tooltip>
						) : _itm.loaded === IS_NEW ? (
							'Create new sprint'
						) : (
							<Tooltip title="Edit sprint">
								<SettingOutlined /> {_itm?.name}
							</Tooltip>
						)}{' '}
					</span>
				}
				style={{ width: '100%' }}
				extra={
					<div id="target-info-modified">
						<Tooltip title="Last updated">
							<FormatedTimestamp
								timestamp={_itm.modifiedTs}
								formatedAs="DD.MM.YYYY hh:mm"
							/>{' '}
							<HistoryOutlined />
						</Tooltip>
					</div>
				}
			>
				<ProgressModal
					modalKey="sprint-progress"
					uiStore={uiLstSprints}
					requireItemById={requireSprintById}
					loadItm={(id) => actLoadSprint(id)}
					saveItem={(itemId, itemValue) => {
						actSaveSprint(itemId, itemValue),
							form.setFieldsValue({
								progress: _itm.progress
							})
					}}
				/>
				<ConfigProvider>
					<Form
						layout="vertical"
						form={form}
						name="sprint"
						initialValues={generateInitialFormValues()}
						onFinish={_doSave}
						className={viewOnly && 'view-only-styles'}
					>
						<Row
							gutter={[50, 50]}
							style={
								features.subtasks && {
									borderBottom: '1px solid #f0f0f0'
								}
							}
						>
							<Col
								id="target-form"
								className="section-pattern-dots"
								span={features.recurrence ? 12 : 24}
								style={{
									// backgroundColor: 'grey',
									...(features.recurrence
										? {
												borderRight:
													'1px solid #f0f0f0',
												paddingRight: '25px'
										  }
										: {})
								}}
							>
								<Space
									direction="vertical"
									style={{ width: '100%' }}
								>
									<GeneralTourTitle
										align="end"
										titleProps={{
											text: 'General',
											h: Title,
											level: 5
										}}
										buttonProps={{
											onClick: startTourForm,
											disabled: false,
											style: { marginBottom: 4 }
										}}
									/>
									<div id="target-form-name">
										<Form.Item
											label="Name"
											tooltip={{
												title: 'Is used for searching and filtering.',
												icon: <InfoCircleOutlined />
											}}
											name="name"
											rules={[
												{
													required: true,
													message:
														'Please provide a name!'
												}
											]}
										>
											<Input
												maxLength={100}
												showCount={!viewOnly}
												disabled={formIsDisabled}
											/>
										</Form.Item>
									</div>
									<Space direction={isMobile && "vertical"} style={{ width: '100%' }}>
										<div id="target-form-category_id">
											<Form.Item
												label="Category"
												tooltip={{
													title: 'Category of the sprint.',
													icon: <InfoCircleOutlined />
												}}
												name="category_id"
												rules={[
													{
														required: true,
														message:
															'Please select a category!'
													}
												]}
											>
												<AllowedCategories
													disabled={formIsDisabled}
												/>
											</Form.Item>
										</div>
										{isSuperAdmin && (
											<div id="target-form-user_id">
												<Form.Item
													label="Created by"
													tooltip={{
														title: 'Sprint will appear createdBy administrator of the user.',
														icon: (
															<InfoCircleOutlined />
														)
													}}
													name="createdBy"
													rules={[
														{
															required: true,
															message:
																'Please select a user!'
														}
													]}
												>
													<Select
														disabled={
															formIsDisabled
														}
														placeholder="Select a user"
														allowClear
														style={{
															minWidth: '200px'
														}}
														options={[
															...[loggedInUserId],
															...new Set([
																...loggedInUser.clients,
																...loggedInUser.team
															])
														].map((createdBy) => {
															return {
																label: (
																	<UserString
																		key={
																			createdBy
																		}
																		userId={
																			createdBy
																		}
																		path={[
																			'name'
																		]}
																	/>
																),
																value: createdBy
															}
														})}
													/>
												</Form.Item>
											</div>
										)}
										<div id="target-form-user_id">
											<Form.Item
												label="For user"
												tooltip={{
													title: 'Sprint will appear for this user and administrator of the user.',
													icon: <InfoCircleOutlined />
												}}
												name="for_user_id"
												rules={[
													{
														required: true,
														message:
															'Please select a user!'
													}
												]}
											>
												<Select
													disabled={formIsDisabled}
													placeholder="Select a user"
													allowClear
													style={{
														minWidth: '200px'
													}}
													options={[
														...[loggedInUserId],
														...new Set([
															...loggedInUser.clients,
															...loggedInUser.team
														])
													].map((for_user_id) => {
														return {
															label: (
																<UserString
																	key={
																		for_user_id
																	}
																	userId={
																		for_user_id
																	}
																	path={[
																		'name'
																	]}
																/>
															),
															value: for_user_id
														}
													})}
												/>
											</Form.Item>
										</div>
									</Space>
									<div id="target-form-platformIds">
										{userIdTrack && (
											<Form.Item
												label="Platform"
												tooltip={{
													title: 'Platform where the sprint is being developed. If platform is not here, add it from your profile.',
													icon: <InfoCircleOutlined />
												}}
												name="platformIds"
											>
												<AssignPlatformsInput
													user_id={userIdTrack}
													disabled={formIsDisabled}
												/>
											</Form.Item>
										)}
									</div>
									<div id="target-form-progress">
										<Row align="bottom" gutter={6}>
											<Col>
												<Form.Item
													label="Progress"
													tooltip={{
														title: 'Current progress of the sprint. You might want to directly define as completed.',
														icon: (
															<InfoCircleOutlined />
														)
													}}
													name="progress"
													rules={[
														{
															required: true,
															message:
																'Please provide a progress!'
														}
													]}
												>
													<ProgressInput
														viewOnly={viewOnly}
														disabled={
															formIsDisabled || _itm.loaded !== IS_NEW
														}
													/>
												</Form.Item>
											</Col>
											{viewOnly && (
												<Col>
													<Form.Item>
														<Button
															htmlType="Set Progress"
															type="primary"
															onClick={(e) => {
																e.preventDefault()
																onSetProgressChange()
															}}
															ghost
														>
															Set Progress
														</Button>
													</Form.Item>
												</Col>
											)}

											<Col>
												<Form.Item
													label="Priority"
													tooltip={{
														title: 'Priority of the sprint.',
														icon: (
															<InfoCircleOutlined />
														)
													}}
													name="priority"
													rules={[
														{
															required: true,
															message:
																'Please provide a priority!'
														}
													]}
												>
													<Select
														disabled={
															formIsDisabled
														}
														placeholder="Select a priority"
														allowClear
														style={{
															minWidth: '200px'
														}}
														options={
															priorityOptions
														}
													/>
												</Form.Item>
											</Col>
											<Col>
												<Form.Item
													label="Effort Estimate"
													tooltip={{
														title: 'Effort Estimated for the sprint.',
														icon: (
															<InfoCircleOutlined />
														)
													}}
													name="effortEstimate"
													rules={[
														{
															required: true,
															message:
																'Please provide a effortEstimate!'
														}
													]}
												>
													<InputNumber
														min={0}
														max={24}
														step={1}
														disabled={
															formIsDisabled
														}
													/>
												</Form.Item>
											</Col>
										</Row>
									</div>
								</Space>
							</Col>
							{features.recurrence && (
								<Col span={12}>
									<RecurrenceFormItems
										form={form}
										disabled={formIsDisabled}
									/>
								</Col>
							)}
						</Row>
						<Row
							gutter={[50, 50]}
							style={
								!viewOnly && {
									borderBottom: '1px solid #f0f0f0'
								}
							}
						>
							{features.subtasks && (
								<Col
									span={features.comments && !isMobile ? 12 : 24}
									style={
										features.comments && {
											borderRight: '1px solid #f0f0f0',
											paddingRight: '25px'
										}
									}
								>
									<Title level={5}>Subtasks</Title>
									<Form.Item
										label="Subtasks items"
										name="subtasks"
										tooltip={{
											title: 'Subtasks can also have comments.',
											icon: <InfoCircleOutlined />
										}}
									>
										<SubtasksInput
											displaySubtaskDescription
											createdBy={loggedInUserId}
											disabled={formIsDisabled}
										/>
									</Form.Item>
								</Col>
							)}
							{features.comments && (
								<Col span={features.subtasks && !isMobile ? 12 : 24}>
									<Title level={5}>Comments</Title>
									<Form.Item
										label="Comments"
										name="comments"
										tooltip={{
											title: 'Comments can be added to the sprint.',
											icon: <InfoCircleOutlined />
										}}
									>
										<CommentsInput
											createdBy={loggedInUserId}
											disabled={false}
										/>
									</Form.Item>
								</Col>
							)}
						</Row>
						{/* {!viewOnly && ( */}
						<SubmitButton>
							{_itm.loaded === IS_NEW ? 'Create' : 'Update'}
						</SubmitButton>
					</Form>
				</ConfigProvider>
			</Card>
		</Space>
	)
}
SprintData.propTypes = {
	viewOnly: PropTypes.bool
}

export default observer(SprintData)
