import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Space, Typography } from 'antd'
import { blogData } from './BlogLst'
import dayjs from 'dayjs'

const { Title, Paragraph } = Typography

const BlogItm = () => {
    const { page } = useParams()

    const [pageContent, setPageContent] = useState({});

    //TODO: Replace with Real API call (Simulate API call to get  data)
    const getPageContent = (page) => {
        const content = blogData.find((item) => item.link === page);
        return content || {};
    }

    useEffect(() => {
        setPageContent(getPageContent(page))
    }, [page])

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <Typography>
                <Title level={2}>{pageContent?.title}</Title>
                <Paragraph>Categories: {pageContent?.category}</Paragraph>
                <Paragraph>Post Date: {dayjs?.unix(pageContent.createdTs)?.format('MMMM D, YYYY')}</Paragraph>
            </Typography>
        </Space>
    )
}

export default BlogItm