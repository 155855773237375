import React from 'react'
import { observer } from 'mobx-react-lite'
import { Routes, Route } from 'react-router-dom'
import BlogLst from './BlogLst'
import BlogItm from './BlogItm'

const BlogMain = () => {
	return (
		<Routes>
			<Route path="/" element={<BlogLst />} />
			<Route path="/:page" element={<BlogItm />} />
			<Route path="*" element={<div>Not found</div>} />
		</Routes>
	)
}
export default observer(BlogMain)
