import { Button, Form, Input, Modal, Popconfirm, Select } from 'antd'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { stripeCancelSubscription, stripeResumeSubscription } from '../../stores/subscriptions/actions'
import { useStores } from '../../stores/MobXProvider'

const SubscriptionActions = ({ subId, type }) => {
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [form] = Form.useForm()
	const { users } = useStores()
	const { loggedInUser } = users

	const feedbackOptions = [
		{
			value: 'customer_service',
			label: 'Customer service was less than expected'
		},
		{
			value: 'low_quality',
			label: 'Quality was less than expected'
		},
		{
			value: 'missing_features',
			label: 'Some features are missing'
		},
		{
			value: 'other',
			label: 'Other reason'
		},
		{
			value: 'switched_service',
			label: "I'm switching to a different service"
		},
		{
			value: 'too_complex',
			label: 'Ease of use was less than expected'
		},
		{
			value: 'too_expensive',
			label: "It's too expensive"
		},
		{
			value: 'unused',
			label: "I don't use the service enough"
		}
	]

	const _initialValues = {
		cancellation_details: {
			comment: '',
			feedback: ''
		}
	}

	const cancelSubscription = (values) => {
		stripeCancelSubscription(loggedInUser?.email, subId, values)

		setIsModalVisible(false)
		form.resetFields()
	}

	const resumeSubscription = () => {
		stripeResumeSubscription(loggedInUser?.email, subId)
		setIsModalVisible(false)
	}

	return (
		<div>
			{
				type== 'resume' && <Popconfirm
					title="Want to resume subscription?"
					open={isModalVisible}
					onConfirm={resumeSubscription}
					onCancel={() => setIsModalVisible(false)}
					okType="link"
					placement="topLeft"
				/>
			}
			
			<Button
				danger
				type="primary"
				onClick={() => setIsModalVisible(true)}
			>
				{
					type== 'cancel' 
						? 'Stop Renewal'
						: 'Resume Subscription'
				}
			</Button>

			{
				type== 'cancel' && <Modal
					title="Stop renewal of subscription"
					open={isModalVisible}
					onCancel={() => setIsModalVisible(false)}
					footer={null}
				>
					<Form
						form={form}
						layout="vertical"
						initialValues={_initialValues}
						onFinish={cancelSubscription}
					>
						<Form.Item
							label="Comment"
							name={['cancellation_details', 'comment']}
						>
							<Input.TextArea rows={4} />
						</Form.Item>
						<Form.Item
							label="Feedback"
							name={['cancellation_details', 'feedback']}
							rules={[
								{
									required: true,
									message: 'Please provide your feedback!'
								}
							]}
						>
							<Select
								placeholder="Select feedback type"
								options={feedbackOptions}
							/>
						</Form.Item>

						<Form.Item>
							<Button type="primary" htmlType="submit">
								Confirm & stop renewal
							</Button>
						</Form.Item>
					</Form>
				</Modal>
			}
		</div>
	)
}

SubscriptionActions.propTypes = {
	subId: PropTypes.string.isRequired,
	type: PropTypes.oneOf(['cancel', 'resume']).isRequired
}

export default observer(SubscriptionActions)
