import { List } from 'antd'
import React from 'react'

const CustomList = ({ ...props }) => {
  return (
    <List
        {...props}
    />
  )
}

export default CustomList