import UiLstStoreBase from '../UiLstStoreBase'

/**
 * @class UiLstSprintsStore
 * @extends UiLstStoreBase
 * @description This class represents the store for the UI list of sprints. It extends the UiLstStoreBase class and initializes the sprints store with default filters for progress, category, and deadline.
 */
class UiLstSprintsStore extends UiLstStoreBase {
	/**
	 * @constructor
	 * @param {object} rootStore - The root store that holds the global state and other stores.
	 * @description Constructs an instance of UiLstSprintsStore with the specified root store and initializes the store with default filters.
	 */
	constructor(rootStore) {
		// Call the parent constructor with the root store and default filters
		super(rootStore, {
			progress: 'draft, in_review, pending, active',
			category: '',
			deadline: '',
			priority: '',
			user_uid: '',
			for_user_uid: '',
			orderBy: 'priority',
			orderDir: 'desc'
		})
	}
}

export default UiLstSprintsStore
