/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { observer } from 'mobx-react-lite'
import {
	Button,
	Popconfirm,
	Popover,
	Select,
	Space,
	Switch,
	Tag,
	Tooltip
} from 'antd'
import { UserOutlined, InfoOutlined } from '@ant-design/icons'

import {
	actLoadSprint,
	actSaveSprint,
	actToTeamSprint,
	newSprint,
	requireSprintById
} from '../../stores/sprints/actions'
import {
	actUndoOrDeleteSprint,
	actLoadSprints
} from '../../stores/ui-lst-sprints/actions'
import { useStores } from '../../stores/MobXProvider'

import {
	StatusTag,
	Filter,
	UserString,
	LstTable,
	FormatedTimestamp,
	BetweenDates,
	CustomTable
} from '../../components/Shared'
import { recurrenceLabels } from '../../components/Form/recurrence/labels'
import ProgressModal from '../../components/Form/ProgressModal'
import { runInAction } from 'mobx'
import { GeneralTourTitle } from '../../components/Shared/GeneralComponents'
import { getTourArrayByKeys } from '../../stores/tour/config'
import { DueIn } from '../../components/Shared/FormatedTimestamp'
import dayjs from 'dayjs'

/*
 * Sprint's can be multiple types
 */
const LstItem = observer(({ id, isFirst }) => {
	const navigate = useNavigate()
	const { uiLstSprints, users, categories, appType } = useStores()
	const { loggedInUser } = users
	const isAdmin = loggedInUser.isAdmin
	const isSuperAdmin = loggedInUser.isSuperAdmin

	const sprint = requireSprintById(id)
	const selfPermission =
		sprint.createdBy === loggedInUser.user_id || isAdmin || isSuperAdmin

	const hasRecurrence = !!sprint.recurrenceNext
	const hasBacklog = !!sprint.sprint_team_count

	// Delete is active only for the creator of the sprint if is in draft mode or if the user is an admin
	const deleteIsDisabled = !isAdmin
		? !(
				sprint.progress === 'draft' &&
				loggedInUser.user_id === sprint.createdBy
		  )
		: false

	const [showConfirmUndoOrDelete, setConfirmUndoOrDelete] = useState(false)
	const confirmUndoOrDelete = () => {
		setConfirmUndoOrDelete(false)

		if (!!sprint.to_delete) {
			actUndoOrDeleteSprint(id, false).catch(console.error)
		} else {
			actUndoOrDeleteSprint(id, true).catch(console.error)
		}
	}
	const _actions = [
		{
			key: 'status',
			label: 'Set Progress',
			value: 'status',
			disabled: !!sprint.to_delete
		},
		{
			key: 'view',
			label: 'View',
			value: 'view'
		},
		{
			key: 'data',
			label: 'Edit',
			value: 'data',
			disabled: !!sprint.to_delete || !selfPermission
		}
	]

	// Delete / Undo actions
	if (sprint.createdBy === loggedInUser.user_id || isAdmin || isSuperAdmin) {
		if (sprint.to_delete) {
			_actions.push({
				key: 'undo-del',
				label: 'Undo Delete',
				value: 'undo-delete',
				disabled: deleteIsDisabled
			})
		} else {
			_actions.push({
				key: 'del',
				label: 'Delete',
				value: 'delete',
				disabled: deleteIsDisabled
			})
		}
	}

	// To team action
	if ((isAdmin || isSuperAdmin) && appType.appIsClient) {
		_actions.push({
			key: 'to-team',
			label: 'To Team',
			value: 'to-team'
		})
	}

	const handleActions = (value) => {
		switch (value) {
			case 'view':
				navigate(`${id}/view`)
				break
			case 'data':
				navigate(`${id}/data`)
				break
			case 'undo-delete':
				setConfirmUndoOrDelete(true)
				break
			case 'delete':
				setConfirmUndoOrDelete(true)
				break
			case 'status':
				runInAction(() => {
					uiLstSprints.setModal('sprint-progress', {
						visible: true,
						itmId: id
					})
				})
				break
			case 'to-team':
				actToTeamSprint(id).catch(console.error)
			default:
				break
		}
	}

	return (
		<tr>
			<td>
				<CustomTable
					data={[
						[
							{
								item: (
									<Space>
										<StatusTag
											tag={sprint.priority}
											tagType={'PriorityTag'}
											style={{
												marginRight: 0,
												padding: '0 3px'
											}}
										/>
										<StatusTag
											isShort
											id={
												isFirst
													? 'target-itm-progress'
													: ''
											}
											tag={sprint.progress}
											style={{
												marginRight: 0,
												padding: '0 3px'
											}}
										/>
										<Tooltip title="View item">
											<Link
												to={`${id}/view`}
												id={
													isFirst
														? 'target-itm-link'
														: ''
												}
											>
												{sprint.name}
											</Link>
										</Tooltip>
									</Space>
								)
							}
						]
					]}
				/>
			</td>
			<td>
				<CustomTable
					data={[
						[
							// 'For user:',
							{
								item:
									loggedInUser.user_id ===
									sprint.for_user_id ? (
										<Tooltip title="Assigned to you">
											<UserOutlined
												id={
													isFirst
														? 'target-itm-user'
														: ''
												}
											/>
										</Tooltip>
									) : (
										<Tooltip title="Assigned to user">
											<Tag
												color="default"
												id={
													isFirst
														? 'target-itm-user'
														: ''
												}
											>
												<UserString
													userId={sprint.for_user_id}
													path="name"
												/>
											</Tag>
										</Tooltip>
									)
							}
						],
						[
							// 'Category:',
							{
								item: (
									<Tooltip title="Category">
										<span
											id={
												isFirst
													? 'target-itm-category'
													: ''
											}
										>
											{categories[sprint?.category_id]
												?.name || '-'}
										</span>
									</Tooltip>
								)
							}
						]
					]}
				/>
			</td>
			<td id={isFirst ? 'target-itm-recurrence' : ''}>
				{sprint.recurrenceType && (
					<CustomTable
						data={[
							[
								'Next:',
								{
									item: sprint.recurrenceNext ? (
										<FormatedTimestamp
											timestamp={sprint.recurrenceNext}
										/>
									) : sprint.progress !== 'completed' ? (
										'*requires update'
									) : (
										'completed'
									)
								}
							],
							sprint.recurrenceNext &&
								sprint.progress !== 'completed' && [
									'In:',
									{
										item: (
											<DueIn
												timestamp={
													sprint.recurrenceNext
												}
											/>
										)
									}
								]
						]}
					/>
				)}
			</td>

			<td>
				<Space direction="vertical">
					<Space>
						<Popover
							content={() => (
								<CustomTable
									data={[
										[
											{
												item: <strong>Info</strong>
											}
										],
										[
											'Updated last:',
											{
												item: (
													<FormatedTimestamp
														timestamp={
															sprint.modifiedTs
														}
														formatedAs="DD.MM.YYYY hh:mm"
													/>
												)
											}
										],
										[
											'Created at:',
											{
												item: (
													<FormatedTimestamp
														timestamp={
															sprint.createdTs
														}
														formatedAs="DD.MM.YYYY hh:mm"
													/>
												)
											}
										],
										[
											'Created by:',
											{
												item: (
													<UserString
														userId={
															sprint.createdBy
														}
														path="name"
													/>
												)
											}
										],
										[
											'Assigned to:',
											{
												item: (
													<UserString
														userId={
															sprint.for_user_id
														}
														path="name"
													/>
												)
											}
										],
										hasBacklog && [
											{
												item: <strong>Backlog</strong>
											}
										],
										hasBacklog && [
											'Tasks(n):',
											{
												item: (
													<span>
														{
															sprint.sprint_team_count
														}
													</span>
												)
											}
										],
										hasBacklog && [
											'Progress:',
											{
												item: (
													<span>
														{sprint.progress_team}
													</span>
												)
											}
										],
										hasRecurrence && [
											{
												item: (
													<strong>Recurrence</strong>
												)
											}
										],
										hasRecurrence && [
											'Type:',
											{
												item:
													recurrenceLabels[
														sprint.recurrenceType
													] ||
													'*' + sprint.recurrenceType
											}
										],
										hasRecurrence && [
											'Next:',
											{
												item: sprint.recurrenceNext ? (
													<FormatedTimestamp
														timestamp={
															sprint.recurrenceNext
														}
													/>
												) : sprint.progress !==
												  'completed' ? (
													'*requires update'
												) : (
													'completed'
												)
											}
										],
										hasRecurrence &&
											sprint.recurrenceType !==
												'deadline' && [
												'Start/End:',
												{
													item: (
														<BetweenDates
															timestamps={
																sprint.recurrenceStartEnd
															}
														/>
													)
												}
											]
									]}
								/>
							)}
							trigger="hover"
						>
							<Button
								id={isFirst ? 'target-itm-info' : ''}
								color="default"
								icon={<InfoOutlined />}
							/>
						</Popover>
						<Select
							id={isFirst ? 'target-itm-actions' : ''}
							value="Actions"
							options={_actions}
							onChange={handleActions}
							style={{ width: 120 }}
						/>
					</Space>
					{sprint.toDeleteTs && (
						<>
							<span>
								Delete on:{' '}
								<FormatedTimestamp
									timestamp={sprint.toDeleteTs}
								/>
							</span>
						</>
					)}
				</Space>

				<Popconfirm
					title={
						sprint.to_delete
							? 'Confirm undo delete'
							: 'Confirm delete'
					}
					open={showConfirmUndoOrDelete}
					onConfirm={confirmUndoOrDelete}
					onCancel={() => setConfirmUndoOrDelete(false)}
					okType="link"
					placement="topLeft"
				/>
			</td>
		</tr>
	)
})

LstItem.propTypes = {
	id: PropTypes.string.isRequired,
	isFirst: PropTypes.bool
}

export const sprintsLstProgressOptions = [
	{
		key: 'by-progress',
		value: 'draft, in_review, pending, active',
		label: 'By Status'
	},
	{
		key: 'by-progress-canceled',
		value: 'canceled',
		label: 'Canceled'
	},
	{
		key: 'by-progress-draft',
		value: 'draft',
		label: 'Draft'
	},
	{
		key: 'by-progress-in_review',
		value: 'in_review',
		label: 'In review'
	},
	{
		key: 'by-progress-active',
		value: 'active',
		label: 'Active'
	},
	{
		key: 'by-progress-pending',
		value: 'pending',
		label: 'Pending'
	},
	{
		key: 'by-progress-completed',
		value: 'completed',
		label: 'Completed'
	}
]

const SprintsLst = observer(() => {
	const navigate = useNavigate()
	const {
		uiLstSprints,
		tourStore,
		hasIntervalLoad,
		toggleIntervalLoad,
		appType,
		users
	} = useStores()
	const { loggedInUserId } = users
	const pg = uiLstSprints.pg
	const bySearch = uiLstSprints.search
	const byDel = uiLstSprints.del
	const byCategory = uiLstSprints.filter.category
	const byProgress = uiLstSprints.filter.progress
	const byPriority = uiLstSprints.filter.priority
	const for_user_uid = uiLstSprints.filter.for_user_uid
	const user_uid = uiLstSprints.filter.user_uid
	const orderBy = uiLstSprints.filter.orderBy
	const orderDir = uiLstSprints.filter.orderDir

	const intervalIdRef = useRef(null) // Use ref for intervalId
	const [errorKey, setErrorKey] = useState(null)
	const withIntervalLoad = hasIntervalLoad('sprints')

	useEffect(() => {
		actLoadSprints().catch(console.error)

		// Function to cancel interval
		const cancelCheckAndLoad = () => {
			if (intervalIdRef.current) {
				clearInterval(intervalIdRef.current)
			}
		}
		// Function to check if any item in list is modified and reload items it if necessary
		const checkAndLoadItems = () => {
			if (withIntervalLoad) {
				if (errorKey !== 'not_found') {
					actLoadSprints(true)
						.then(
							(res) =>
								res && actLoadSprints().catch(console.error)
						)
						.catch(console.error)
				} else if (errorKey === 'not_found') {
					cancelCheckAndLoad()
				}
			} else {
				cancelCheckAndLoad()
			}
		}

		// Polling interval to check for modifications every 5 seconds
		if (withIntervalLoad) {
			intervalIdRef.current = setInterval(checkAndLoadItems, 5000)
		} else {
			cancelCheckAndLoad()
		}

		// Clean up interval on component unmount
		return () => cancelCheckAndLoad()
	}, [
		pg,
		bySearch,
		byDel,
		byCategory,
		byProgress,
		byPriority,
		withIntervalLoad,
		user_uid,
		for_user_uid,
		orderBy,
		orderDir,
		appType.appIs
	])

	const tourSprintLst = getTourArrayByKeys('sprintLst', ['all'])
	const tourFilter = getTourArrayByKeys('filter', ['all'])
	const startTour = () => {
		const hasItems = uiLstSprints.ids.length > 0
		if (!hasItems) {
			const newId = uuidv4()
			tourStore.setDeleteItemAct(() =>
				actUndoOrDeleteSprint(newId, true).catch(console.error)
			)

			const demoObj = {
				createdTs: dayjs().unix(),
				createdBy: loggedInUserId,
				for_user_id: loggedInUserId,
				platformIds: [],
				category_id: byCategory || '7',
				name: 'demoItem',
				progress: 'draft',
				recurrence: {
					daysOfWeek: [],
					dayOfMonth: '',
					months: [],
					next: ''
				},
				subtasks: [],
				comments: []
			}
			actSaveSprint(newId, demoObj)
				.then(() => {
					actLoadSprints().catch(console.error)
				})
				.catch(console.error)
		}
		tourStore.startTour('SprintsLst-tour', [
			{
				title: 'Sync on interval',
				description:
					'Enable or disable data synchronization on interval for the sprints list. Any additions or modifications will be automatically updated even if working in multiple tabs or list/items are updated by multiple users.',
				target: () => document.getElementById('target-interval-lst')
			},
			...tourFilter,
			...tourSprintLst
		])
	}

	return (
		<Space direction="vertical" style={{ width: '100%', padding: 20 }}>
			<GeneralTourTitle
				titleProps={{ text: 'Sprint planning' }}
				buttonProps={{
					onClick: startTour
				}}
				rowProps={{
					justify: 'space-between'
				}}
			>
				<Switch
					id="target-interval-lst"
					style={{ marginRight: 10 }}
					tooltip="Sync data on interval"
					value={withIntervalLoad}
					checkedChildren="Sync on"
					unCheckedChildren="Sync off"
					defaultChecked
					onChange={() => toggleIntervalLoad('sprints')}
				/>
			</GeneralTourTitle>
			<Filter
				uiStore={uiLstSprints}
				addNew={() => {
					const newId = uuidv4()
					newSprint(newId).then(() => {
						navigate(`/sprints/${newId}/data`)
					})
				}}
				progressOptions={sprintsLstProgressOptions}
			/>

			<ProgressModal
				modalKey="sprint-progress"
				uiStore={uiLstSprints}
				requireItemById={requireSprintById}
				loadItm={(id) => actLoadSprint(id)}
				saveItem={actSaveSprint}
			/>

			<LstTable
				LstItem={LstItem}
				store={uiLstSprints}
				className="table-pro"
			>
				<tr id="items-lst">
					<th id="target-sprint">Overview</th>
					<th id="target-info">Information</th>
					<th id="target-recurrence">Recurrence</th>
					<th id="target-actions">Actions</th>
				</tr>
			</LstTable>
		</Space>
	)
})

export default SprintsLst
