import React from 'react'
import dayjs from 'dayjs'
import { DATE_FORMAT } from '../../config'
import CustomTag from './CustomTag'
import PropTypes from 'prop-types'
// Convert milliseconds to seconds if necessary
const seccondsFromTimestamp = (timestamp) => {
	// Convert milliseconds to seconds if necessary
	return String(timestamp).length === 13
		? Math.floor(timestamp / 1000)
		: timestamp
}

// Calculate the number of days until the timestamp
export const daysUntilTimestamp = (timestamp) => {
	const correctedTimestamp = seccondsFromTimestamp(timestamp)
	const now = dayjs()
	const then = dayjs.unix(correctedTimestamp)
	const days = then.diff(now, 'days')
	return days
}

export const DueIn = ({ timestamp, valueOnly = false }) => {
	const days = daysUntilTimestamp(timestamp)
	if (valueOnly) return days

	if (days === 0)
		return (
			<CustomTag color="warning" tooltip="Today">
				Today
			</CustomTag>
		)
	if (days < 0)
		return (
			<CustomTag color="error" tooltip="Overdue">
				{days}d
			</CustomTag>
		)

	return (
		<CustomTag color="default" tooltip="Days remaining">
			{days}d
		</CustomTag>
	)
}
DueIn.propTypes = {
	timestamp: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	valueOnly: PropTypes.bool
}

export const FormatedTimestamp = ({ timestamp, formatedAs = DATE_FORMAT }) => {
	if (!formatedAs) formatedAs = DATE_FORMAT
	const correctedTimestamp = seccondsFromTimestamp(timestamp)
	// Format the timestamp
	return dayjs.unix(correctedTimestamp).format(formatedAs)
}
