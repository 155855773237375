import React from 'react'
import { Form, Input, Button, Typography } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { login } from '../modules/api-user'
import { useStores } from '../stores/MobXProvider'
import { clearErrors, startError } from '../stores/work-progress/actions'
import { runInAction, toJS } from 'mobx'
import { actSaveUser } from '../stores/users/actions'
import { getCookie } from '../utils'

const { Text } = Typography

const LoginComponent = observer(() => {
	const { users, uiLstSprints } = useStores()
	const navigate = useNavigate()
	const [passwordVisible, setPasswordVisible] = React.useState(false)

	// Sets default sprints list filters based on user preferences.
	const setSprintsLstDefaultFilters = (filters) => {
		if(filters){
			uiLstSprints.filter = filters
		}
	}

	// Updates the user's cookie consent preference based on the stored cookie value.
	const updateUserCookieConsent = async (user) => {
		const consent = getCookie('cookieConsent');
		if (consent !== null) {
			runInAction(() => {
				user.setCookieConsent(consent);
			});
	
			actSaveUser(user.user_id, toJS(user.full), toJS(user.allowed)).then(() => {
				users.saveState();
			})
		}
	};

	const onFinish = async (values) => {
		try {
			login(values)
				.then((response) => {
					if (response.status === 'success') {
						const userId = response.user.user_id
						users.setLoggedInUser(userId, response.apiToken)
						const user = users.requireUserById(userId)
						user.updateFromServer(response.user)
						users.saveState()
						clearErrors()

						// Set default filters for sprints list
						setSprintsLstDefaultFilters(user?.preferences?.sprints?.filters);

						// Update user cookie consent preference
						updateUserCookieConsent(user);

						if (users.redirectOnLogin) {
							const url = users.redirectOnLogin
							users.clearRedirectOnLogin()
							navigate(url)
						} else {
							navigate('/dashboard')
						}
					} else {
						startError(response.message)
					}
				})
				.catch((error) => {
					startError(error.message)
					console.error('Failed to fetch user info:', error)
				})
		} catch (error) {
			console.error('Login failed:', error)
			startError('Login failed, please try again.')
		}
	}

	return (
		<Form
			name="login_form"
			initialValues={{ remember: true }}
			onFinish={onFinish}
			layout="vertical"
		>
			<Form.Item
				name="email"
				label="Email"
				rules={[
					{ required: true, message: 'Please input your email!' }
				]}
			>
				<Input placeholder="Email" />
			</Form.Item>

			<Form.Item
				name="password"
				label="Password"
				rules={[
					{ required: true, message: 'Please input your password!' }
				]}
			>
				<Input.Password
					placeholder="Password"
					visibilityToggle={{
						visible: passwordVisible,
						onVisibleChange: setPasswordVisible
					}}
				/>
			</Form.Item>

			<Form.Item>
				<Button
					type="primary"
					htmlType="submit"
					style={{ width: '100%' }}
				>
					Log In
				</Button>
			</Form.Item>

			<Form.Item>
				<Text style={{ display: 'block', textAlign: 'center' }}>
					Don't have an account?{' '}
					<Link to="/register">Register now</Link>
				</Text>
			</Form.Item>
		</Form>
	)
})

export default LoginComponent
