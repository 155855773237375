import { get } from 'lodash'
import { runInAction } from 'mobx'
import dayjs from 'dayjs'
import rootStore from '../..'
import { wrapWithLoading } from '../../general-actions'
import { getCompactLst } from '../../../modules/api-compact'
import { responseHasError } from '../../users/actions'
import { PLAN_FOR_DELETION_NR_OF_DAYS, warnPt } from '../../../config'
import { requireRequestById } from '../../requests/actions'
import { deleteLstItem } from '../../../modules/api-delete'
const _storeKey = 'uiLstRequests'

/**
 * @async
 * @function actLoadRequests
 * @description Asynchronously loads requests from the server and updates the UI list store.
 * @param { function } navigate - The navigation function.
 * @returns {Promise<void>}
 */
export const actLoadRequests = wrapWithLoading(
	async () => {
		const params = {
			del: rootStore[_storeKey].del,
			pg: rootStore[_storeKey].pg,
			rpg: rootStore[_storeKey].rpg,
			search: rootStore[_storeKey].search,
			progress: rootStore[_storeKey].filter?.progress || '',
			priority: rootStore[_storeKey].filter?.priority || '',
			user_uid: rootStore[_storeKey].filter?.user_uid || '',
			for_user_uid: rootStore[_storeKey].filter?.for_user_uid || '',
			orderBy: rootStore[_storeKey].filter?.orderBy,
			orderDir: rootStore[_storeKey].filter?.orderDir
		}

		const _response = await getCompactLst('requests', params)

		const hasError = responseHasError(_response)
		if (!hasError && _response.status === 'success') {
			runInAction(() => {
				_actSync(
					get(_response, ['itms'], []),
					get(_response, ['nr'], 0)
				)
			})
		} else {
			throw _response
		}
	},
	{ loadingMessage: 'Loading Requests' }
)

export const actUndoOrDeleteRequest = wrapWithLoading(
	async (requestId, status = true) => {
		warnPt(requestId)
		const plan_for_deletion = dayjs()
			.add(PLAN_FOR_DELETION_NR_OF_DAYS['requests'], 'day')
			.unix()
		const timestamp = status ? plan_for_deletion : false

		const _response = await deleteLstItem('requests', {
			id: requestId,
			to_delete: timestamp || false
		})

		const hasError = responseHasError(_response)
		if (!hasError && _response.status === 'success') {
			runInAction(() => {
				const _storeItm = requireRequestById(requestId)
				_storeItm.setToDelete(timestamp || false)

				rootStore[_storeKey].setIds(
					rootStore[_storeKey].ids.filter((id) => id !== requestId)
				)
				rootStore[_storeKey].setNr(rootStore[_storeKey].nr - 1)
			})
		} else {
			throw _response
		}
	},
	{ loadingMessage: 'Deleting Request' }
)

/**
 * @function _actSync
 * @description Syncs the server items with the store items.
 * @param {Array} srvItms - Server items.
 * @param {number} nr - Number of items.
 */
const _actSync = (srvItms, nr) => {
	warnPt(srvItms, nr)
	runInAction(() => {
		for (const _srvItm of srvItms) {
			const _storeItm = requireRequestById(_srvItm.request_id)
			_storeItm.updateFromServer(_srvItm)
		}
		rootStore[_storeKey].setIds(srvItms.map((it) => it.request_id))
		rootStore[_storeKey].setNr(nr)
	})
}
