import { Button, Card, Col, ConfigProvider, Form, Input, Row, Space } from 'antd'
import React, { useRef } from 'react'
import { GeneralTitle } from '../../components/Shared'
import TextArea from 'antd/es/input/TextArea'
import { useStores } from '../../stores/MobXProvider'
import { actSendMail } from '../../stores/users/actions'
import { observer } from 'mobx-react-lite'

const ContactUs = () => {
    const [form] = Form.useForm()
    const { users } = useStores()
	const { loggedInUser } = users
    const messageRef = useRef(null)

    // Disable the email field if the user is logged in and has an email
    const isEmailFieldDisabled = loggedInUser?.email ? true : false

    // Set initial form values based on the logged-in user
    const _initialFormValues = {
		email: loggedInUser?.email || "",
		subject: "",
		body: ""
	}

    /**
	 * Handles form submission for saving contact form data
	 *
	 * @param {Object} vals - The form values.
	 */
	const _doSave = (vals) => {
		if (vals !== null) {
			console.log('--- Save', vals)
            // return;
            actSendMail(vals).then(
				(response) => {
					console.log('response', response)
                    
                    // Set success message
                    messageRef.current.style.color = 'green'
                    messageRef.current.textContent = 
                        response?.message || 'Your message has been sent successfully!'
                
                    // Clear the form
                    form.resetFields();
				}
			).catch((error) => {
                console.log('error', error)

                // Set error message
                messageRef.current.style.color = 'red'
                messageRef.current.textContent = 
                    response?.message || 'Failed to sent message!'
            })
			console.log('--- Contact us', vals)
		}
	}


	return (
		<Space direction="vertical" style={{ width: '100%' }}>
			<GeneralTitle text="Contact Us" />
			<Card
				style={{ width: '100%' }}
			>
				<ConfigProvider>
					<Form
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 16 }}
						form={form}
						name="profile"
						initialValues={_initialFormValues}
						onFinish={_doSave}
					>
                        <Row>
                            <Col flex="auto">
                                <Form.Item
                                    label="Email"
                                    tooltip="Please enter a valid email address where we can reach you."
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please provide a email address!'
                                        }
                                    ]}
                                >
                                    <Input 
                                        disabled={isEmailFieldDisabled} 
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Subject"
                                    tooltip="A brief subject for your message."
                                    name="subject"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please provide a subject for your message!'
                                        }
                                    ]}
                                >
                                    <Input showCount max={100} />
                                </Form.Item>
                                <Form.Item
                                    label="Body"
                                    tooltip="The details of your message."
                                    name="comment"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your message!'
                                        }
                                    ]}
                                >
                                   <TextArea rows={5} showCount maxLength={2000} />
                                </Form.Item>

                                <Form.Item style={{ marginBottom: 0 }}>
									<Button type="primary" htmlType="submit">
										Submit
									</Button>
                                    <span ref={messageRef} style={{ marginLeft: 16 }}></span>
								</Form.Item>
                            </Col>
                        </Row>
					</Form>
				</ConfigProvider>
			</Card>
		</Space>
	)
}

export default observer(ContactUs)
