import { action, computed, makeObservable, observable } from 'mobx'
import dayjs from 'dayjs'
import { Subtask, Subtasks } from '../index'
import { Sprints } from '../../sprints-ids'

/**
 * Class representing a TimedSubtask, extending Subtask.
 */
class TimedSubtask extends Subtask {
	hours

	constructor({ name, hours = 0, sprints, ...subtaskData }) {
		super({ ...subtaskData, name: name }) // Convert 'name' to dayjs object
		this.hours = hours

		this.sprints = new Sprints(sprints)

		makeObservable(this, {
			hours: observable,
			sprints: observable,
			sprintIds: computed,
			setSprintsIds: action,
			addSprintsId: action.bound,
			deleteSprintsId: action.bound
		})
	}

	get sprintIds() {
		return this.sprints.list
	}

	setSprintsIds(newIds) {
		this.sprints.replaceSprintIds(newIds)
	}

	get displayName() {
		let displayName = dayjs.unix(this.name)
		displayName = displayName.isValid()
			? displayName.format('YYYY-MM-DD')
			: dayjs(this.name).format('YYYY-MM-DD')

		return `${displayName} (${this.hours}hrs)`
	}

	addSprintsId(id) {
		this.sprints.addSprint(id)
	}

	deleteSprintsId(id) {
		this.sprints.removeSprint(id) 
	}

	// You can override or add specific methods from Subtask if needed
	get subtaskType() {
		return 'timed'
	}
}

/**
 * Class representing a collection of TimedSubtasks, extending Subtasks.
 * Contains additional properties and methods specific to TimedSubtasks.
 * @extends {Subtasks}
 * @class TimedSubtasks
 */
class TimedSubtasks extends Subtasks {
	constructor(list = []) {
		super(list)
		makeObservable(this)
	}

	get usedItemClass() {
		return TimedSubtask
	}

	addSubtask(
		name,
		hours,
		sprints,
		images,
		description,
		status,
		web,
		sortId,
		createdBy
	) {
		const subtask = new TimedSubtask({
			name,
			hours,
			sprints,
			images,
			description,
			status,
			web,
			sortId,
			createdBy
		})
		this.list.push(subtask)
	}

	// You can override or extend methods from Subtasks if needed
	get subtaskType() {
		return 'timed'
	}

	prepareForSave() {
		if (!Array.isArray(this.list)) {
			return [] // Ensure the function returns an empty array if this.list is not an array
		}

		return this.list.map((subtask) => {
			// Ensure subtask.comments.list is an array before mapping
			const comments = Array.isArray(subtask.comments.list)
				? subtask.comments.list.map((comment) => comment)
				: [] // Return an empty array if subtask.comments.list is not an array

			// Ensure subtask.sprints.list is an array before mapping
			const sprintsIds = Array.isArray(subtask.sprints.list)
				? subtask.sprints.list.map((sprint) => sprint)
				: [] // Return an empty array if subtask.sprints.list is not an array

			return {
				...subtask,
				comments,
				sprintsIds
			}
		})
	}
}

export { TimedSubtask, TimedSubtasks }
