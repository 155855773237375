import { addGETParams } from '../config'
import ax from './api'
/*
* setMonthlyReport - Set a monthly report item on the server
* @param {string} reportId - The id of the monthly report item
* @param {object} full - The full object of the monthly report item
* @returns {object} The response from the server

*/
export const setMonthlyReport = async (reportId, full) => {
	// Convert full object to JSON string
	const fullStr = JSON.stringify(full)
	const _url = addGETParams(`/monthly-reports/set.php`, {
		report_id: reportId,
		full: fullStr
	})

	return await ax.post(_url)
}
