import { runInAction } from 'mobx'
import rootStore from '../..' // Import the root store
import { Sprint } from '../../../modules/cls/sprint'
import { getFullItm } from '../../../modules/api-item'

import { setSprint, toTeamSprint } from '../../../modules/api-sprints'
import { warnPt } from '../../../config'
import { responseHasError } from '../../users/actions'
import { wrapWithLoading } from '../../general-actions'

const sprintsStore = rootStore.sprints

/**
 * @async
 * @function newSprint
 * @description Creates a new sprint item with the specified ID and marks it as new.
 * @param {string} newId - The ID of the new sprint item.
 * @returns {Promise<void>}
 */
export const newSprint = async (newId) => {
	warnPt(newId)
	runInAction(() => {
		const _storeItm = requireSprintById(newId)
		_storeItm.setNewFlag()
	})
}

/**
 * @function requireSprintById
 * @description Retrieves a sprint item by its ID from the store. If it doesn't exist, creates a new sprint item.
 * @param {string} sprintId - The ID of the sprint item to retrieve.
 * @returns {Sprint} The retrieved or newly created sprint item.
 */
export const requireSprintById = (sprintId) => {
	warnPt(sprintId)
	let sprint = sprintsStore.sprints[sprintId]
	if (sprint) {
		return sprint
	}
	runInAction(() => {
		sprintsStore.sprints[sprintId] = new Sprint(sprintId)
	})
	return sprintsStore.sprints[sprintId]
}




/**
 * @async
 * @function actLoadSprint
 * @description Loads a sprint item from the server by its ID and updates the store.
 * @param { function } navigate - The navigation function.
 * @param {string} sprintId - The ID of the sprint item to load.
 * @returns {Promise<void>}
 */
export const actLoadSprint = wrapWithLoading(
	async (sprintId) => {
		const _response = await getFullItm('sprints', {
			id: sprintId
		})

		const hasError = responseHasError(_response)
		if (!hasError && _response.status === 'success') {
			runInAction(() => {
				const _storeItm = requireSprintById(sprintId)
				_storeItm.updateFromServer(_response.data || {})
			})
		} else {
			throw _response
		}
	},
	{ loadingMessage: 'Loading Sprint' },
	true // Activate rethrow to propagate errors
)

/**
 * @async
 * @function actSaveSprint
 * @description Saves a sprint item to the server.
 * @param {string} sprintId - The ID of the sprint item to save.
 * @param {Object} full - The full data of the sprint item to save.
 * @returns {Promise<boolean>} True if the save was successful, false otherwise.
 */
export const actSaveSprint = wrapWithLoading(
	async (sprintId, full) => {
		const _response = await setSprint(sprintId, full)

		const { data } = _response

		console.log('actSaveSprint() response:', data)
		const hasError = data ? responseHasError(data) : true
		if (hasError) {
			throw data
		} else {
			const _storeItm = requireSprintById(sprintId)
			_storeItm.setModified(data.modified)
		}

		return true // No exceptions were thrown, so return true
	},
	{ loadingMessage: 'Saving Sprint' }
)

export const actToTeamSprint = wrapWithLoading(
	async (sprintId) => {
		const _response = await toTeamSprint(sprintId)

		const { data } = _response

		console.log('actToTeamSprint() response:', data)
		const hasError = data ? responseHasError(data) : true
		if (hasError) {
			throw data
		} else {
			const _storeItm = requireSprintById(sprintId)
			_storeItm.setModified(data.modified)
		}

		return true // No exceptions were thrown, so return true
	},
	{ loadingMessage: 'Moving Sprint to Team' }
)
