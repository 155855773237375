import React from 'react'
import { Card, Col, List, Row, Typography } from "antd"
import { Link } from "react-router-dom"
import CustomList from '../components/Shared/CustomList';

const { Title, Paragraph } = Typography

const websites = [
    'wordpress-shortcode.com',
    'wordpress-page-builder.com',
    'wordpress-database.com',
    'wordpress-ecommerce-theme.com',
    'wordpress-pricing.com',
    'wordpress-free.com',
    'wordpress-admin.com',
    'wordpress-logs.com',
    'wordpress-onepage.com',
    'developer-wordpress.com',
    'wordpress-api.com',
    'wordpress-development-services.com',
    'wordpress-homepage.com',
    'wordpress-landing-page.com',
    'pqazml.com',
    'on-subscription.com',
];

const cookieData = [
    "If you leave a comment, you may opt-in to saving your name, email address, and website in cookies. These are for your convenience and last for one year.",
    "Temporary cookies are set upon visiting the login page to check if your browser accepts cookies. These contain no personal data and are discarded when you close your browser.",
    "Upon login, cookies save your login information and screen display choices. Login cookies last two days, and screen options cookies last a year. Selecting “Remember Me” extends the login persistence to two weeks. Login cookies are removed upon logging out.",
    "Editing or publishing an article sets a cookie in your browser, indicating the post ID of the article edited. This expires after one day.",
    "Additional functional cookies may be used for site features like enabling “dark mode”, storing favorite posts/products, etc.",
];

const analyticsData = [
    {
        title: "Google Analytics",
        description: "This service tracks and reports website traffic, providing insights into user behavior, engagement, and site performance. Google Analytics collects anonymous data about your visit, such as pages viewed, duration of visit, and how you arrived at our site."
    },
    {
        title: "Facebook Analytics",
        description: "Used for understanding the interactions of users with our websites and Facebook pages. It helps us track user engagement, conversions, and the effectiveness of our social media campaigns."
    }
];

const PrivacyPolicy = () => {
    return (
        <div style={{ padding: "20px" }}>
            <Title level={1} style={{ textAlign: "center" }}>
                Privacy Policy
            </Title>

            {/* Company details Section */}
            <Title level={3}>Company details:</Title>
            <Paragraph>
                Company name: Webackit Solutions S.R.L
            </Paragraph>
            <Paragraph>
                Address: Str. Splaiul Independenței, nr.202B, București, Romania
            </Paragraph>
            <Paragraph>
                Fiscal Number: 44553258
            </Paragraph>
            <Paragraph>
                VAT number: 44601134
            </Paragraph>
            <Paragraph>
                Commercial Register: J40/11711/2021
            </Paragraph>

            {/* Privacy Policy Section */}
            <Card>
                <Title level={3}>
                    Privacy Policy of Webackit Solutions S.R.L.
                </Title>
                <Paragraph>
                    This Privacy Policy governs the manner in which Webackit Solutions S.R.L. collects, uses, maintains, and discloses information collected from users (each, a “User”) of our network of websites. While <a href='https://webackit.com/' target='_blank' rel="noreferrer">webackit.com</a> serves as our main website and primary point of contact, this policy extends to the following subsidiary websites operated by our company:
                </Paragraph>
                <Row gutter={4}>
                    {websites.map((site, index) => (
                        <Col key={index} xs={12}>
                            <Link to={`https://${site}`}>{site}</Link>
                        </Col>
                    ))}
                </Row>
            </Card>


            <Title level={3}>
                What personal data we collect and why we collect it
            </Title>
            <Title level={4}>
                Comments
            </Title>
            <Paragraph>
                When visitors leave comments on our site, we collect the data shown in the comments form. Additionally, we collect the visitor’s IP address and browser user agent string to aid in spam detection.
                An anonymized string (hash) created from your email address may be provided to the Gravatar service to check if you are using it. The Gravatar service’s privacy policy is here: https://automattic.com/privacy/. Upon approval of your comment, your profile picture is visible to the public in your comment’s context.
            </Paragraph>
            <Title level={4}>
                Media
            </Title>
            <Paragraph>
                If you upload images to the website, we recommend avoiding images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images on the website.            </Paragraph>

            <Title level={4}>
                Contact forms
            </Title>
            <Paragraph>
                When visitors complete a contact form, we collect the data shown in the form, and also the visitor’s IP address and browser user agent string for spam detection. This data is used strictly for customer service purposes. Some forms may be for marketing purposes and will include a checkbox specifying the usage; if checked, we will use the data for marketing purposes.
            </Paragraph>

            <Title level={4}>
                Cookies
            </Title>
            <Paragraph>
                We use various types of cookies on our site:            </Paragraph>
            <CustomList
                dataSource={cookieData}
                renderItem={(item) => (
                    <List.Item>
                        <Paragraph>{item}</Paragraph>
                    </List.Item>
                )}
            />

            <Title level={4}>
                Embedded content from other websites
            </Title>
            <Paragraph>
                Articles on this site may include embedded content (e.g., videos, images, articles). Such content from other websites behaves identically as if the visitor has visited those websites. These sites may collect data, use cookies, embed third-party tracking, and monitor your interaction with the embedded content, especially if you have an account and are logged in to those sites.            </Paragraph>

            <Title level={4}>
                Analytics
            </Title>
            <Paragraph>
                We use various analytics tools to understand and improve the user experience on our websites. Specifically, we employ Google Analytics and Facebook Analytics for this purpose. Below are details on how each of these tools processes data:
            </Paragraph>

            <Title level={4}>
                Analytics
            </Title>
            <Paragraph>
                We use various analytics tools to understand and improve the user experience on our websites. Specifically, we employ Google Analytics and Facebook Analytics for this purpose. Below are details on how each of these tools processes data:
            </Paragraph>
            <CustomList
                dataSource={analyticsData}
                renderItem={({ title, description }) => (
                    <List.Item>
                            <b>{title}:</b> {description} <Link to="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Google’s Privacy & Terms</Link>.
                    </List.Item>
                )}
            />
            <Paragraph>
                Both these tools help us tailor our content and marketing strategies to better suit our audience’s needs. We ensure that the data collected is handled in compliance with privacy laws and regulations.
            </Paragraph>
            <Paragraph>
                Please note that you can control the information that is shared with these analytics services. Most web browsers offer options to block cookies and similar tracking technologies. Additionally, you can opt-out of Google Analytics using the Google Analytics Opt-out Browser Add-on, and manage your Facebook ad preferences through your Facebook account settings.
            </Paragraph>

            <Card>
                <Title level={3}>
                    Who we share your data with
                </Title>
                <Paragraph>
                    Webackit Solutions S.R.L. does not sell, market, or rent personally identifiable information of users to others. We may share generic aggregated demographic information not linked to any personal identification with our business partners, trusted affiliates, and advertisers for the purposes outlined above. We may use third-party service providers to help operate our site and administer activities on our behalf, such as sending out newsletters or surveys. Your information may be shared with these third parties for limited purposes, only with your explicit consent.
                </Paragraph>
                <Title level={3}>
                    Electronic newsletters
                </Title>
                <Paragraph>
                    Users who opt to join our email list will receive emails containing company news, updates, product information, or related services. Users can unsubscribe at any time using the instructions at the bottom of each email, or by contacting us through our site. We may use third-party service providers for activities like sending out newsletters or surveys, and your information may be shared with these parties for these specific purposes, given your prior consent.
                </Paragraph>
                <Title level={3}>
                    How long we retain your data
                </Title>
                <Paragraph>
                    Comments and their metadata are retained indefinitely to automatically recognize and approve follow-up comments. For users who register on our website, we store the personal information provided in their user profiles. Users can modify or delete their personal information at any time (username cannot be changed). Website administrators can also see and edit this information.
                </Paragraph>
                <Title level={3}>
                    Your Rights Over Your Data
                </Title>
                <Paragraph>
                    If you have an account on our site or have left comments, you can request an exported file of your personal data we hold, including any data provided to us. You can also request the erasure of your personal data. This does not include data we must retain for administrative, legal, or security purposes.
                </Paragraph>
                <Title level={3}>
                    Where we send your data
                </Title>
                <Paragraph>
                    Visitor comments may be screened through an automated spam detection service.
                </Paragraph>
                <Title level={3}>
                    How we protect your data
                </Title>
                <Paragraph>
                    Webackit Solutions S.R.L. uses its custom WordPress theme and limits the use of public plugins, opting for highly secure options like “WooCommerce” or “Contact Form 7”. We develop our own plugins for additional functionalities to maintain high security standards and minimize vulnerabilities.
                </Paragraph>
                <Title level={3}>
                    Data Breach Procedures
                </Title>
                <Paragraph>
                    Despite high security, breaches may occur. We advise against using your real email password for your account. In a breach, we will temporarily disable checkout, notify affected users promptly, and advise on password changes. If your email password was used, change it immediately upon receiving our notification.
                </Paragraph>
                <Title level={3}>
                    Automated Decision Making and Profiling With User Data
                </Title>
                <Paragraph>
                    We may use customer data for personalized recommendations or discounts based on previous orders or site interactions. We track (with consent) user interactions within our site, like clicks on links or form inputs, to understand needs and preferences. This data helps enhance user experience, suggesting changes on less engaged parts of the site. We also assist users in finding previously accessed pages or information under “Previously Searched”. This data is kept for one year for relevance and is accessible only to Webackit Solutions S.R.L., after which it is automatically deleted.                                                  </Paragraph>
            </Card>

            <Title level={4}>
                Terms and Conditions
            </Title>
            <Paragraph>
                Please also visit our <Link to='/terms-and-conditions'>Terms and Conditions</Link> page, which outlines the use, disclaimers, and limitations of liability governing the use of our website and services at Webackit Solutions S.R.L. The Terms and Conditions provide detailed information about the rules and guidelines for using our website, and we strongly recommend that you review them to fully understand your rights and obligations as a user of our services.
            </Paragraph>


            {/* Acceptance of Terms Section */}
            <Card>
                <Title level={3}>
                    Acceptance of These Terms
                </Title>
                <Paragraph>
                    By using any of the websites within the Webackit Solutions S.R.L. network, you signify your acceptance of this Privacy Policy. If you do not agree with the terms of this policy, please refrain from using our sites. Your continued use of the sites following the posting of changes to this policy will be deemed your acceptance of those changes.
                </Paragraph>
            </Card>
        </div>
    )
}

export default PrivacyPolicy