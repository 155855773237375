import React from 'react'
import { Button, Col, Form, Input, Row, Space } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import { GeneralTitle } from '../../components/Shared'
import { actLoadSecured, actSaveSecured } from '../../stores/secured/actions'
import { observer } from 'mobx-react-lite'
import LstTypeInputBase from '../../components/Form/LstTypeInputBase'
import { Secured } from '../../modules/cls/secured'
import DynamicFormComponent from '../../components/Form/DynamicFormComponent'
import { toJS } from 'mobx'

const SecuredSearch = () => {
	const [form] = Form.useForm()

	const hasSecuredId = Form.useWatch('securedId', form) || ''
	const hasSearchKey = Form.useWatch('search_key', form) || ''

	/**
	 * Handles form submission for searching secured form data
	 *
	 * @param {Object} vals - The form values used in searching: {search_name, search_key}.
	 */
	const _doSearch = (vals) => {
		if (vals !== null) {
			actLoadSecured(vals.search_name, vals.search_key)
				.then((res) => {
					if (res.status === 'success') {
						const data = res.data
						// Populate the form with the retrieved secured data
						form.setFieldsValue({
							securedId: data.secured_id,
							name: data.name,
							full: JSON.parse(data.full),
							key: hasSearchKey // Keep the key in the form for saving purposes
						})
					} else if (res.status === 'error') {
						console.error('SecuredSearch Error:', res)
					}
				})
				.catch((err) => {
					console.log('SecuredSearch Error:', err)
				})
		}
	}

	/**
	 * Handles form submission for saving secured form data
	 *
	 * @param {Object} vals - The form values.
	 */
	const _doSave = async (vals) => {
		if (vals !== null) {
			// Send plaintext data and key to the backend
			actSaveSecured(vals.securedId, vals.name, toJS(vals.full), vals.key)
				.then((res) => {
					console.log('SecuredSearch: Save response', res)
				})
				.catch((err) => {
					console.error('SecuredSearch Save Error:', err)
				})
		}
	}

	return (
		<Space direction="vertical" style={{ display: 'block', padding: 20 }}>
			<GeneralTitle text="Secured" />
			<Form form={form} name="secured-search" layout="vertical">
				{/* Hidden field for securedId */}
				<Form.Item name="securedId" hidden>
					<Input />
				</Form.Item>

				{hasSecuredId === '' && (
					<Row align="middle" gutter={[10, 10]}>
						<Col>
							<Form.Item
								label="Search"
								tooltip="Enter the exact name."
								name="search_name"
								rules={[
									{
										required: true,
										message:
											'Please provide the exact name!'
									}
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col>
							<Form.Item
								label="Key"
								tooltip="Enter the exact key used in the name relation."
								name="search_key"
								rules={[
									{
										required: true,
										message: 'Please provide the exact key!'
									}
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col flex="auto">
							<Form.Item label=" ">
								<Button
									type="primary"
									onClick={() => {
										const search_name =
											form.getFieldValue('search_name')
										const search_key =
											form.getFieldValue('search_key')
										// Make sure the form is valid
										form.validateFields([
											'search_name',
											'search_key'
										]).then(() => {
											_doSearch({
												search_name,
												search_key
											})
										})
									}}
								>
									Search
								</Button>
							</Form.Item>
						</Col>
						<Col flex="auto" align="end">
							<Form.Item label=" ">
								<Button
									onClick={() => {
										form.resetFields()
										form.setFieldsValue({
											securedId: uuidv4()
										})
									}}
								>
									Add New
								</Button>
							</Form.Item>
						</Col>
					</Row>
				)}

				{hasSecuredId !== '' && (
					<Space
						direction="vertical"
						style={{ display: 'block', padding: 20 }}
					>
						<Space direction="horizontal">
							<Form.Item
								label="Name"
								name="name"
								tooltip="The name of the secured item."
								rules={[
									{
										required: true,
										message: 'Please enter a name!'
									}
								]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								label="Key"
								name="key"
								tooltip="The key used for encryption."
								rules={[
									{
										required: true,
										message: 'Please enter a key!'
									}
								]}
							>
								<Input />
							</Form.Item>
						</Space>
						<Form.Item
							label="Secured Item"
							name="full"
							tooltip="The full data of the secured item."
							rules={[
								{
									required: true,
									message: 'Please enter the secured data!'
								}
							]}
						>
							<LstTypeInputBase
								displayDescription
								noProgress
								UseLstClass={Secured}
								phaseTwoLimited
								additionalEditSection={(viewOnly) => {
									return (
										<Form.Item
											noStyle
											disabled={viewOnly}
											name="data"
											wrapperCol={{
												span: 24
											}}
										>
											<DynamicFormComponent
												viewOnly={viewOnly}
											/>
										</Form.Item>
									)
								}}
							/>
						</Form.Item>
						<Button
							type="primary"
							onClick={() => _doSave(form.getFieldsValue())}
						>
							Save
						</Button>
					</Space>
				)}
			</Form>
		</Space>
	)
}

export default observer(SecuredSearch)
