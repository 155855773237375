import '../App.css'
import {
	UserOutlined,
	CaretRightOutlined,
	PieChartOutlined,
	FireOutlined,
	LockOutlined,
	WechatOutlined,
	BarChartOutlined
} from '@ant-design/icons'
// Pages
import Login from './Login'
import Register from './Register'
import SprintsMain from './Sprints/SprintsMain'
import AppDashboard from './AppDashboard'
import UsersMain from './Users/UsersMain'
import Subscriptions from './Subscriptions'
import RequestsMain from './Requests/RequestsMain'
import HomePage from './main/HomePage'
import HomePageTeam from './main/HomePageTeam'
import BlogMain from './main/Blog/BlogMain'
import AboutUs from './main/AboutUs/AboutUs'
import MonthlyReportsMain from './MonthlyReports/MonthlyReportsMain'
import ContactUs from './main/ContactUs'
import TermsAndConditions from './main/TermsAndConditions'
import PrivacyPolicy from './PrivacyPolicy'
import SecuredMain from './Secured/SecuredMain'

/**
 * Array containing the menu routes configuration.
 * Each route includes path, label, component, and loggedInDisable properties.
 */
const routesMenu = [
	// { path: '/', component: Login },
	{
		key: 'login',
		path: '/login',
		label: 'Login',
		component: Login,
		loggedInDisable: true,
		published: true
	},
	{
		key: 'register',
		path: '/register',
		label: 'Register',
		component: Register,
		loggedInDisable: true,
		published: true
	}
]

/**
 * Array containing the sidebar routes configuration.
 * Each route includes path, label, component, loggedInActive properties and an icon.
 */
const routesSide = [
	{
		key: 'dashboard',
		path: '/dashboard',
		label: 'Dashboard',
		component: AppDashboard,
		loggedInActive: true,
		icon: PieChartOutlined,
		published: true
	},
	{
		key: 'management',
		label: 'Management',
		loggedInActive: true,
		icon: UserOutlined,
		appType: 'client',
		published: true,
		children: [
			{
				key: 'users',
				keyparent: 'management',
				path: '/users',
				pathWithChild: '/*',
				label: 'User list',
				component: UsersMain,
				loggedInActive: true,
				icon: CaretRightOutlined,
				appType: 'client',
				published: true
			},
			{
				key: 'subscriptions',
				keyparent: 'management',
				path: '/subscriptions',
				label: 'Subscriptions',
				component: Subscriptions,
				loggedInActive: true,
				icon: CaretRightOutlined,
				appType: 'client',
				published: true
			}
		]
	},
	{
		key: 'secured',
		path: '/secured',
		pathWithChild: '/*',
		label: 'Secured',
		component: SecuredMain,
		loggedInActive: true,
		icon: LockOutlined,
		published: true
	},
	{
		key: 'sprints',
		path: '/sprints',
		pathWithChild: '/*',
		label: 'Sprints',
		component: SprintsMain,
		loggedInActive: true,
		icon: FireOutlined,
		published: true
	},
	{
		key: 'monthly-reports',
		path: '/monthly-reports',
		pathWithChild: '/*',
		label: 'Monthly Reports',
		component: MonthlyReportsMain,
		loggedInActive: true,
		icon: BarChartOutlined,
		published: true
	},
	{
		key: 'requests',
		path: '/requests',
		pathWithChild: '/*',
		label: 'Requests',
		component: RequestsMain,
		loggedInActive: true,
		icon: WechatOutlined,
		published: true
	}
]

const routesPages = [
	{
		key: 'home',
		path: '/',
		label: 'Home',
		component: HomePage,
		loggedInActive: false,
		published: true,
		appType: 'client'
	},
	{
		key: 'home-team',
		path: '/',
		label: 'Home Team',
		component: HomePageTeam,
		loggedInActive: false,
		published: true,
		appType: 'team'
	},
	{
		key: 'blog',
		path: '/blog',
		pathWithChild: '/*',
		label: 'Blog',
		component: BlogMain,
		loggedInActive: false,
		published: true,
		appType: 'client'
	},
	{
		key: 'about-us',
		path: '/about-us',
		label: 'About Us',
		component: AboutUs,
		loggedInActive: false,
		published: false,
		appType: 'client'
	},
	{
		key: 'contact-us',
		path: '/contact-us',
		label: 'Contact Us',
		component: ContactUs,
		loggedInActive: false,
		published: true
	},
	{
		key: 'terms-and-conditions',
		path: '/terms-and-conditions',
		label: 'Terms and Conditions',
		component: TermsAndConditions,
		loggedInActive: false,
		published: true,
		appType: 'client'
	},
	{
		key: 'privacy-policy',
		path: '/privacy-policy',
		label: 'Privacy Policy',
		component: PrivacyPolicy,
		loggedInActive: false,
		published: true,
		appType: 'client'
	}
]

/**
 * Array containing all routes from the menu and sidebar including children flatten that have a path (not a group).
 */
const flattenRoutesList = []
function flattenRoutes(routes) {
	routes.forEach((route) => {
		if (route.path) flattenRoutesList.push(route)
		if (route.children) flattenRoutes(route.children)
	})
}
flattenRoutes(routesMenu)
flattenRoutes(routesSide)
flattenRoutes(routesPages)

export { routesMenu, routesSide, routesPages, flattenRoutesList }
