import { Button, Space } from 'antd'
import React, { useEffect } from 'react'
import { Filter } from '../Shared'
import { useStores } from '../../stores/MobXProvider'
import { observer } from 'mobx-react-lite'
import { runInAction, toJS } from 'mobx'
import { sprintsLstProgressOptions } from '../../pages/Sprints/SprintsLst'

/**
 * UserPreferencesInput Component
 * This component provides a UI for users to input and manage filters preferences
 *
 * @returns {JSX.Element} A React component that displays user preferences for sprint filters.
 */
const UserPreferencesInput = observer(({ user }) => {
    const { uiLstSprints } = useStores()

    // Get the current filter preferences for sprints
    const currentSprintFilters = toJS(uiLstSprints.filter)
    
    // Set the filter to the user's saved preferences
    useEffect(() => {
        if(user?.preferences?.sprints?.filters){
            uiLstSprints.filter = user?.preferences?.sprints?.filters
        }
    }, [user])

    // Function to update the user's sprint filter preferences.
    const setSprintFilterPreference = () => {
        // get the user filter preferences for sprints
        const filterValue = uiLstSprints.filter

        // Update the user's preferences for the sprint filters
        runInAction(() => {
            user.setPreferences({
                sprints: {
                    'filters': toJS(filterValue)
                }
            })
        })

        // Reset the filter to the current sprint filters
        uiLstSprints.filter = currentSprintFilters
    }

    return (
        <Space direction='vertical' style={{ width: '100%' }}>
            <div>
                <p>Sprint Filters:</p>
                <Filter
                    uiStore={uiLstSprints}
                    hide={['search', 'deleted', 'addNew']}
                    progressOptions={sprintsLstProgressOptions}
                />
            </div>
            <Button 
                type="default"
                style={{
                    backgroundColor: '#d9d9d9', borderColor: '#bfbfbf'
                }}
                onClick={setSprintFilterPreference}
            >
                Use Exact Values as Default Filter
            </Button>
        </Space>
    )
})

export default UserPreferencesInput