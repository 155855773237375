import React from 'react'
import {
	SyncOutlined,
	ThunderboltOutlined,
	EditOutlined,
	CloseCircleOutlined,
	EyeOutlined,
	CheckCircleFilled,
	SunFilled
} from '@ant-design/icons'
import PropTypes from 'prop-types'
import CustomTag from './CustomTag'

const StatusTag = ({ tag, fallback, isShort, tagType = 'StatusTag', ...props }) => {
	let tags = {}
	if(tagType == 'StatusTag'){
		tags = {
			canceled: (
				<CustomTag tooltip="Progress: canceled" color="error" {...props}>
					{!isShort ? 'canceled' : <CloseCircleOutlined />}
				</CustomTag>
			),
			draft: (
				<CustomTag tooltip="Progress: draft" color="warning" {...props}>
					{!isShort ? 'draft' : <EditOutlined />}
				</CustomTag>
			),
			in_review: (
				<CustomTag tooltip="Progress: in review" color="warning" {...props}>
					{!isShort ? 'in review' : <EyeOutlined />}
				</CustomTag>
			),
			active: (
				<CustomTag tooltip="Progress: active" color="default" {...props}>
					{!isShort && 'active'}
					<ThunderboltOutlined />
				</CustomTag>
			),
			pending: (
				<CustomTag
					tooltip="Progress: pending"
					icon={!isShort && <SyncOutlined spin />}
					color="processing"
					{...props}
				>
					{!isShort ? 'processing' : <SyncOutlined spin />}
				</CustomTag>
			),
			completed: (
				<CustomTag tooltip="Progress: completed" color="success" {...props}>
					{!isShort ? 'completed' : <CheckCircleFilled />}
				</CustomTag>
			),
			default: (
				<CustomTag tooltip="Progress: default" color="default" {...props}>
					-
				</CustomTag>
			)
		}
	}else if(tagType == 'PriorityTag'){
		tags = {
			1: (
				<CustomTag tooltip="Priority: medium" color="#FFD700" {...props}>
					<SunFilled />
				</CustomTag>
			),
			2: (
				<CustomTag tooltip="Priority: high" color="#FFA500" {...props}>
					<SunFilled />
				</CustomTag>
			),
			3: (
				<CustomTag tooltip="Priority: urgent" color="#FF0000" {...props}>
					<SunFilled />
				</CustomTag>
			),
			default: (
				<CustomTag tooltip="Priority: low" color="#808080" {...props}>
					<SunFilled />
				</CustomTag>
			)
		}
	}else if(tagType == 'RequestStatusTag'){
		tags = {
			completed: (
				<CustomTag tooltip="Status: completed" color="success" {...props}>
					{!isShort ? 'Completed' : <CheckCircleFilled />}
				</CustomTag>
			),
			not_read: (
				<CustomTag tooltip="Status: not read" color="warning" {...props}>
					{!isShort ? 'Not Read' : <EyeOutlined />}
				</CustomTag>
			),
			default: (
				<CustomTag tooltip="Status: unknown" color="default" {...props}>
					-
				</CustomTag>
			)
		};
	}

	if (!tag || !tags[tag]) return fallback || tags.default

	return tags[tag]
}

StatusTag.propTypes = {
	tag: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	tagType: PropTypes.oneOf(['StatusTag','PriorityTag', 'RequestStatusTag']),
	fallback: PropTypes.node,
	style: PropTypes.object,
	isShort: PropTypes.bool
}

export default StatusTag
