import { get } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { LstItmBase } from './lst-itm-base'
import { TimedSubtasks } from './subtasks/extensions/TimedSubtasks'
import dayjs from 'dayjs'

/**
 * Class representing a MonthlyReport item, extending the base list item class LstItmBase.
 * Contains additional properties and methods specific to MonthlyReport items.
 *
 * @class MonthlyReport
 * @extends {LstItmBase}
 */
export class MonthlyReport extends LstItmBase {
	monthly_report_id
	hours_worked
	/**
	 * Creates an instance of MonthlyReport.
	 * @param {string} id - The unique identifier for the MonthlyReport item.
	 */
	constructor(id) {
		super(id)
		this.monthly_report_id = id

		makeObservable(this, {
			monthly_report_id: observable,

			hoursWorked: computed,
			setHoursWorked: action.bound
		})
	}

	get displayName() {
		return dayjs.unix(this.name)?.format('YYYY-MM')
	}

	get hoursWorked() {
		return get(
			this.full,
			['hours_worked'],
			get(this.compact, ['hours_worked'], 0)
		)
	}

	setHoursWorked = (val) => {
		this.chgInFull(['hours_worked'], val)
	}

	get subtaskConstructor() {
		return new TimedSubtasks(this.full.subtasks || [])
	}
}
