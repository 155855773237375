import { makeObservable, observable, action } from 'mobx';

/**
 * Class representing a collection of related Sprints for a monthly report.
 */
export class Sprints {
    list = [];

    constructor(sprint = []) {
        // Ensure sprint.list is an array before mapping
        this.list = Array.isArray(sprint?.list)
			? sprint?.list.map((sprint) => sprint)
			: [] // Return an empty array if subtask.comments.list is not an array
        
        makeObservable(this, {
            list: observable,
            addSprint: action.bound,
            removeSprint: action.bound,
        });
    }

    addSprint(id) {
        this.list = this.list.concat([id]);
    }

    removeSprint(id) {
        this.list = this.list.filter(sprintId => sprintId !== id);
    }
    
    replaceSprintIds(newSprintIds) {
        this.list = newSprintIds;
    }
}