import React, { useEffect, useState } from 'react'
import { Input, Button, Select, Row, Col, Space } from 'antd'
import { DeleteOutlined, LinkOutlined } from '@ant-design/icons'

import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import { sortBy } from 'lodash'
import { isMobile } from 'react-device-detect'

const DynamicFormComponent = ({ value = [], onChange, viewOnly = false }) => {
	const [inputs, setInputs] = useState(() => sortBy(value, 'sortId'))

	useEffect(() => {
		const sortedInputs = sortBy(value, 'sortId')
		if (JSON.stringify(sortedInputs) !== JSON.stringify(inputs)) {
			setInputs(sortedInputs)
		}
	}, [value]) // Only run effect when 'value' changes

	const handleInputChange = (id, field, fieldValue) => {
		const newInputs = inputs.map((input) =>
			input.id === id ? { ...input, [field]: fieldValue } : input
		)
		setInputs(newInputs)
		if (onChange) {
			onChange(newInputs)
		}
	}

	const handleNestedChange = (id, newValue) => {
		const newInputs = inputs.map((input) =>
			input.id === id ? { ...input, value: newValue } : input
		)
		setInputs(newInputs)
		if (onChange) {
			onChange(newInputs)
		}
	}

	const addInput = () => {
		const newSortId =
			inputs.length > 0
				? Math.max(...inputs.map((input) => input.sortId)) + 1
				: 1
		const newInput = {
			type: 'text',
			label: '',
			value: '',
			sortId: newSortId,
			id: uuidv4()
		}
		const newInputs = [...inputs, newInput]
		setInputs(newInputs)
		if (onChange) {
			onChange(newInputs)
		}
	}

	const deleteInput = (id) => {
		const newInputs = inputs.filter((input) => input.id !== id)
		setInputs(newInputs)
		if (onChange) {
			onChange(newInputs)
		}
	}

	const validateUrl = (value) => {
		const urlPattern = /^(https?):\/\/([\w.-]+(\/\S*)?)$/
		return urlPattern.test(value)
	}

	const renderInput = (input) => {
		switch (input.type) {
			case 'text':
				return (
					<Input
						value={input.value}
						onChange={(e) =>
							handleInputChange(input.id, 'value', e.target.value)
						}
					/>
				)
			case 'password':
				return (
					<Input.Password
						value={input.value}
						onChange={(e) =>
							handleInputChange(input.id, 'value', e.target.value)
						}
					/>
				)
			case 'textarea':
				return (
					<Input.TextArea
						value={input.value}
						onChange={(e) =>
							handleInputChange(input.id, 'value', e.target.value)
						}
					/>
				)
			case 'link':
				if (viewOnly) {
					return (
						<a href={input.value} target="_blank" rel="noreferrer">
							<LinkOutlined /> {input.value}
						</a>
					)
				}
				return (
					<Input
						type="url"
						value={input.value}
						onChange={(e) => {
							const value = e.target.value
							handleInputChange(input.id, 'value', value)
							if (!validateUrl(value) && value.length > 8) {
								alert(
									'Invalid URL, start with https:// or http://'
								)
								handleInputChange(input.id, 'value', '')
							}
						}}
					/>
				)
			case 'children':
				return (
					<DynamicFormComponent
						viewOnly={viewOnly}
						value={input.value}
						onChange={(newValue) =>
							handleNestedChange(input.id, newValue)
						}
					/>
				)
			default:
				return null
		}
	}

	const deleteButton = (inputId) => (
		<DeleteOutlined onClick={() => deleteInput(inputId)} />
	)

	return (
		<Space style={{ width: '100%' }} direction="vertical">
			{inputs.map((input, index) => (
				<Row
					key={input.id}
					gutter={[10, 10]}
					className={
						index % 2 === 0
							? 'dynamic-form-wrapper-bg even'
							: 'dynamic-form-wrapper-bg odd'
					}
				>
					<Col xs={24} sm={4}>
						<Space direction="horizontal">
							{!viewOnly && !isMobile && (
								<Space>
									<span>{index + 1}</span>
									{deleteButton(input.id)}
								</Space>
							)}

							<Input
								style={{
									flex: 'auto'
								}}
								placeholder="Label"
								value={input.label}
								onChange={(e) =>
									handleInputChange(
										input.id,
										'label',
										e.target.value
									)
								}
							/>

							{!viewOnly && isMobile && (
								<Space>
									<span>{index + 1}</span>
									{deleteButton(input.id)}
								</Space>
							)}
						</Space>
					</Col>
					<Col
						xs={24}
						sm={20}
						className={
							viewOnly
								? 'dynamic-form-wrapper dynamic-form-wrapper-view-mode'
								: 'dynamic-form-wrapper'
						}
					>
						<Select
							key={input.id + '-select'}
							value={input.type}
							onChange={(value) =>
								handleInputChange(input.id, 'type', value)
							}
							options={[
								{ value: 'text', label: 'Text' },
								{ value: 'password', label: 'Password' },
								{ value: 'textarea', label: 'Textarea' },
								{
									value: 'link',
									label: (
										<>
											<LinkOutlined /> Link
										</>
									)
								},
								{ value: 'children', label: 'Children' }
							]}
						/>

						{renderInput(input)}
					</Col>
				</Row>
			))}
			{!viewOnly && (
				<Button
					className="dynamic-form-wrapper-add-input"
					type="dashed"
					onClick={addInput}
				>
					Add Input
				</Button>
			)}
		</Space>
	)
}

DynamicFormComponent.propTypes = {
	value: PropTypes.any,
	onChange: PropTypes.func,
	viewOnly: PropTypes.bool
}

export default DynamicFormComponent
