import { makeObservable, observable, computed, action } from 'mobx'
import { get, set, isArray } from 'lodash'
import { DO_LOAD } from '../../config'

/**
 * Class representing a User.
 * Contains user information and methods to manipulate and update user data.
 *
 * @class User
 */
export class User {
	loaded = -1 // -1 means not loaded, -2 means new flag, 1 means loaded compact, timestamp means loaded full

	user_id = ''
	email = ''

	// When full loaded
	allowed = {} // Contains user permissions
	full = {} // Contains other important user information
	userIds = [] // Contains list of all user IDs (assigned to admin only)

	/**
	 * Creates an instance of User.
	 * @param {string} userId - The unique identifier for the User.
	 */
	constructor(userId) {
		this.user_id = userId

		makeObservable(this, {
			loaded: observable,

			// ### Compact ###
			user_id: observable,
			email: observable,
			setEmail: action,

			// ### Full ###
			allowed: observable,
			full: observable,

			// ### Full helpers ###
			businessDetails: computed,
			setBusinessDetails: action.bound,

			// Grouping subscriptions
			subscriptions: computed,
			setSubscriptions: action.bound,

			// Grouping platforms
			platforms: computed,
			platformsIds: computed,
			setPlatforms: action.bound,

			// Grouping generals
			name: computed,
			setName: action.bound,

			clients: computed,
			setClients: action.bound,

			team: computed,
			setTeam: action.bound,

			preferences: computed,
			setPreferences: action.bound,

			cookieConsent: computed,
			setCookieConsent: action.bound,

			// Server Actions
			setNewFlag: action,
			resetLastFullLoad: action,
			updateFromServer: action
		})
	}

	/**
	 * Retrieves the user's cookieConsent.
	 *
	 * @returns {Object} The user's cookieConsent.
	 */
	get cookieConsent() {
		return get(this.full, ['cookieConsent'], undefined)
	}

	/**
	 * Sets the user's cookieConsent.
	 *
	 * @param {Object} value - The cookieConsent to set.
	 */
	setCookieConsent(value) {
		this.chgInFull(['cookieConsent'], value)
	}

	/**
	 * Retrieves the user's preferences.
	 *
	 * @returns {Object} The user's preferences, or an empty object if none exist.
	 */
	get preferences() {
		return get(this.full, ['preferences'], {})
	}

	/**
	 * Sets the user's preferences.
	 *
	 * @param {Object} value - The preferences to set.
	 */
	setPreferences(value) {
		this.chgInFull(['preferences'], value || {})
	}

	// ### COMPACT ###
	/**
	 * Sets the email of the User.
	 *
	 * @param {string} email - The email to set.
	 */
	setEmail(email) {
		this.email = email
	}

	// ### Full helpers ###
	/**
	 * Gets the name of the User.
	 * If the name is not set in the full object, returns the email.
	 *
	 * @returns {string} The name of the User.
	 */
	get name() {
		return get(this.full, ['name'], get(this, ['email'], this.user_id))
	}
	/**
	 * Sets the name of the User.
	 * Updates the full object with the new name.
	 *
	 * @param {string} value - The name to set.
	 */
	setName(value) {
		this.chgInFull(['name'], value)
	}

	/**
	 * Gets the clients associated with the User.
	 * Returns an empty array if no clients are set.
	 *
	 * @returns {Array<string>} The list of client IDs.
	 */
	get clients() {
		return get(this.full, ['clients'], [])
	}

	/**
	 * Sets the clients associated with the User.
	 * Ensures the value is an array of string IDs before updating the full object.
	 *
	 * @param {Array<string>} value - The list of client IDs to set.
	 */
	setClients(value) {
		if (isArray(value) && value.every((id) => typeof id === 'string')) {
			this.full.clients = value
		} else if (value) {
			console.error('Clients must be an array of string IDs')
		}
	}

	/**
	 * Get the team members associated with the User.
	 * Returns an empty array if no team members are set.
	 *
	 * @returns {Array<string>} The list of team member IDs.
	 */
	get team() {
		return get(this.full, ['team'], [])
	}

	/**
	 * Sets the team members associated with the User.
	 * Ensures the value is an array of string IDs before updating the full object.
	 *
	 * @param {Array<string>} value - The list of team member IDs to set.
	 * @returns {void}
	 */
	setTeam(value) {
		if (isArray(value) && value.every((id) => typeof id === 'string')) {
			this.full.team = value
		}
	}

	// ### Grouping platforms ###
	get platforms() {
		return get(this.full, ['platforms'], [])
	}
	get platformsIds() {
		return this.platforms.map((platform) => platform.id)
	}
	setPlatforms = (platforms) => {
		this.chgInFull(['platforms'], platforms || [])
	}

	// ### Grouping subscriptions ###
	get subscriptions() {
		return get(this.full, ['subscriptions'], [])
	}
	// Set subscriptions to an array of subscription objects
	setSubscriptions(value) {
		this.chgInFull(['subscriptions'], value || [])
	}

	// Get business details, object example: { account_name: '', address: { address_line_1: '', address_line_2: '', city: '', state: '', zip_code: '' }, mobile: { prefix: '', number: '', status: 'not-verified' } }
	get businessDetails() {
		return get(this.full, ['businessDetails'], { address: {} })
	}

	// Set business details
	setBusinessDetails(value) {
		this.chgInFull(['businessDetails'], value || {})
	}

	setAllowed(value) {
		this.allowed = value
	}

	// ### User helpers ###
	// read allowed and check if the user is super admin set to true
	get isSuperAdmin() {
		const superAdmin = get(this.allowed, ['superAdmin'], false)
		return superAdmin === true || superAdmin === 'true'
	}

	// read allowed and check if the user is admin set to true
	get isAdmin() {
		const admin = get(this.allowed, ['admin'], false)
		return admin === true || admin === 'true'
	}

	// ### Server Actions ###
	/**
	 * Sets the loaded flag to indicate the User is new.
	 */
	setNewFlag() {
		this.loaded = -2
	}

	/**
	 * Resets the loaded flag to the current timestamp.
	 */
	resetLastFullLoad() {
		this.loaded = Date.now()
	}

	/**
	 * Helper function to change a value in the full object.
	 * @param {Array} path - The path to the property in the full object.
	 * @param {any} val - The value to set.
	 */
	chgInFull = (path, val) => {
		if (get(this.full, path) !== val) {
			set(this.full, path, val)
		}
	}

	/**
	 * Converts the User instance to a JSON object.
	 * only values that are set are included in the JSON are saved to LocalStorage.
	 * @returns {object} The JSON representation of the User.
	 */
	toJSON() {
		return {
			userId: this.user_id,
			email: this.email,
			allowed: this.allowed,
			full: this.full,
			userIds: this.userIds
		}
	}

	/**
	 * Updates the User with data from the server.
	 * If the full data is a string, it attempts to parse it as JSON.
	 *
	 * @param {object} data - The data from the server.
	 */
	updateFromServer(data) {
		// deconstruct data, and manually treat allowed and full
		const { email, allowed, full, userIds } = data
		// console.log('updateFromServer', { email, allowed, full, userIds })

		if (email) {
			this.email = email
		}

		// Fix JSON parsing
		if (allowed) {
			if (typeof allowed === 'string') {
				try {
					this.allowed = JSON.parse(allowed)
				} catch (error) {
					console.error('Failed to parse allowed JSON:', error)
				}
			} else {
				this.allowed = allowed
			}
		}

		if (full) {
			if (typeof full === 'string') {
				try {
					const parsed = JSON.parse(full)
					// parse each key in full
					this.full = {}
					for (const key in parsed) {
						const val = parsed[key]
						if (typeof val === 'string') {
							try {
								this.full[key] = JSON.parse(val)
							} catch (error) {
								this.full[key] = val
							}
						} else {
							this.full[key] = val
						}
					}
				} catch (error) {
					console.error('Failed to parse full JSON:', error)
				}
			} else {
				this.full = full
			}
		}

		if (userIds) {
			this.userIds = userIds
		}

		// Assign data to class instance
		// Object.assign(this, rest)

		// Parse class instance
		if (this.full && Object.keys(this.full).length > 0) {
			this.resetLastFullLoad()
		} else {
			this.loaded = DO_LOAD
		}
	}
}

export default User
