import React from 'react'
import { DatePicker, Form, Switch, Table, Tooltip } from 'antd'
import PropTypes from 'prop-types'
import { useStores } from '../../stores/MobXProvider'

/**
 * Defines a mapping object for user-friendly labels corresponding to each permission key.
 * This mapping allows the use of descriptive labels in the UI while keeping the keys intact 
 * for backend processing and data handling.
 */
const permissionLabels = {
    roles: {
        admin: "Administrator",
        superAdmin: "Super Administrator",
        clientAdmin: "Client Administrator",
        client: "Client User",
        teamAdmin: "Team Administrator",
        team: "Team Member"
    },
    lst: {
        sprints: "Sprints Management",
        sprints_team: "Sprints Team Management",
        requests: "Request Management",
        monthly_reports: "Monthly Reports Management"
    },
    resources: {
        switchApp: "Switch Application",
        videoMeeting: "Video Meeting",
        upload_photos: "Upload Photos",
        upload_files: "Upload Files",
        chat: "Chat Feature",
        ai: "AI Assistance"
    }
};

/**
 * Permission component renders a table for managing user permissions.
 * Displays allowed features or categories along with their respective permissions.
 * Each permission can be toggled or set with a date using a Switch or DatePicker, respectively.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.data - The data object containing allowed permissions.
 * @param {Array<string>} props.permissions - An array of permission names to display.
 * @param {'categories' | 'lst' | 'resources' | 'roles'} props.type - The type of data to display - can be 'categories', 'lst', 'resources' and 'roles'.
 * @returns {JSX.Element} The rendered table displaying features/categories and their permissions.
 */
const PermissionsInput = ({ data, permissions, type }) => {
	const { users, categories } = useStores()
	const { loggedInUser } = users
	const isSuperAdmin = loggedInUser.isSuperAdmin

	let permissionTitle = 'Feature'
	switch (type) {
		case 'categories':
			permissionTitle = 'Category'
			break
		case 'resources':
			permissionTitle = 'Resource'
			break
		case 'roles':
			permissionTitle = 'Role'
			break
	}

	// Create data source for the table from the allowed permissions
	const dataSource = data
		? Object.keys(data).map((key) => {
				const item = data[key]
				return {
					key,
					feature:
						type == 'categories'
							? categories[key]?.name
							: permissionLabels[type][key], // Use readable label
					permissions: permissions.map((permission) => ({
						name: permission,
						checked: item[permission] || item
					}))
				}
		  })
		: []

	// build dynamic form name
	const getFormFieldName = (record) => {
		const isAdminOrSuperAdmin =
			record.key === 'admin' || record.key === 'superAdmin'
		return isAdminOrSuperAdmin
			? ['allowed', record.key]
			: ['allowed', type, record.key]
	}

	// Define the columns for the table
	const columns = [
		{
			title: permissionTitle,
			dataIndex: 'feature',
			key: 'feature'
		},
		...permissions.map((permission) => ({
			title: permission,
			dataIndex: permission,
			key: permission,
			render: (text, record) => {
				let fieldName = getFormFieldName(record)
				if (type == 'categories' || type == 'lst') {
					fieldName.push(permission)
				}

				// make some specific fields (admin & superAdmin) disable if loggedIn user is not an admin
				const disabled =
					!isSuperAdmin &&
					fieldName.some((val) =>
						['admin', 'superAdmin'].includes(val)
					)
				const tooltipTitle =
					'You do not have permission to modify this setting.'

				// Render DatePicker for start and end date permissions and switch for bool type permissions
				if (permission === 'start' || permission === 'end') {
					return (
						<Tooltip title={disabled ? tooltipTitle : ''}>
							<span>
								<Form.Item
									name={[
										'allowed',
										type,
										record.key,
										permission
									]}
									noStyle
								>
									<DatePicker
										format="YYYY-MM-DD"
										disabled={disabled}
									/>
								</Form.Item>
							</span>
						</Tooltip>
					)
				} else {
					return (
						<Tooltip title={disabled ? tooltipTitle : ''}>
							<span>
								<Form.Item
									name={fieldName}
									valuePropName="checked"
									noStyle
								>
									<Switch
										disabled={disabled}
										defaultChecked={text}
									/>
								</Form.Item>
							</span>
						</Tooltip>
					)
				}
			}
		}))
	]

	return (
		<Table dataSource={dataSource} columns={columns} pagination={false} />
	)
}

PermissionsInput.propTypes = {
	data: PropTypes.object.isRequired,
	permissions: PropTypes.array.isRequired,
	type: PropTypes.oneOf(['categories', 'lst', 'roles', 'resources'])
		.isRequired
}

export default PermissionsInput
