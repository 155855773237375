import React from 'react'
import { Row, Col, Card, Space, Typography, Tag } from 'antd'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

const { Meta } = Card
const { Paragraph } = Typography

export const blogData = [
	{
		id: 1,
		title: 'Understanding React Hooks',
		shortDesc: 'Explore how to harness YouTube ad analytics for creating data-driven, effective ad strategies and achieve strategic wins in your advertising efforts.',
		link: 'react-hooks',
		category: 'Programming',
		createdTs: 1728024861
	},
	{
		id: 2,
		title: 'Getting Started with Ant Design',
		shortDesc: 'Explore how to harness YouTube ad analytics for creating data-driven, effective ad strategies and achieve strategic wins in your advertising efforts.',
		link: 'ant-design',
		category: 'Programming',
		createdTs: 1728024861
	},
	{
		id: 3,
		title: 'Building Responsive Web Apps',
		shortDesc: 'Explore how to harness YouTube ad analytics for creating data-driven, effective ad strategies and achieve strategic wins in your advertising efforts.',
		image: 'https://via.placeholder.com/300x200',
		link: 'responsive-web-apps',
		category: 'Programming',
		createdTs: 1728024861
	},
	{
		id: 4,
		title: 'State Management in React',
		shortDesc: 'Explore how to harness YouTube ad analytics for creating data-driven, effective ad strategies and achieve strategic wins in your advertising efforts.',
		image: 'https://via.placeholder.com/300x200',
		link: 'state-management',
		category: 'Programming',
		createdTs: 1728024861
	},
	{
		id: 5,
		title: 'Exploring Next.js Features',
		shortDesc: 'Explore how to harness YouTube ad analytics for creating data-driven, effective ad strategies and achieve strategic wins in your advertising efforts.',
		image: 'https://via.placeholder.com/300x200',
		link: 'nextjs-features',
		category: 'Programming',
		createdTs: 1728024861
	},
	{
		id: 6,
		title: 'Using TypeScript with React',
		shortDesc: 'Explore how to harness YouTube ad analytics for creating data-driven, effective ad strategies and achieve strategic wins in your advertising efforts.',
		image: 'https://via.placeholder.com/300x200',
		link: '/typescript-react',
		category: 'Programming',
		createdTs: 1728024861
	}
]

const BlogList = () => {
	return (
		<>
			<h1>Blog List</h1>
			<p>Discover the latest articles from our blog.</p>
			<Row gutter={[16, 16]}>
				{blogData.map((blog) => (
					<Col xs={24} sm={12} lg={8} key={blog.id}>
						<Card
							hoverable
						>
							<Meta
								title={
									<Link to={`/blog/${blog.link}`}>{blog.title}</Link>
								}
								description={
									<Space direction="vertical">
										<Paragraph>{blog.shortDesc}</Paragraph>
										<Row gutter={[10,10]} justify="space-between">
											<Col>
												<Tag>
													{blog.category}
												</Tag>
											</Col>
											<Col>
												{dayjs.unix(blog.createdTs).format('MMMM D, YYYY')}
											</Col>
										</Row>
									</Space>
								}
							/>
						</Card>
					</Col>
				))}
			</Row>
		</>
	)
}

export default BlogList
