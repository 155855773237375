import { values } from 'lodash'
/**
 * Returns the next available sort ID for a collection.
 * @param {Array} col - The collection to check.
 * @returns {number} The next available sort ID.
 */
export const nextSortId = (col) => {
	let _max = 0

	for (const it of values(col)) {
		if (_max < it.sortId) {
			_max = it.sortId
		}
	}

	return _max + 1
}

/**
 * Sets a cookie with the specified name, value, and expiration days.
 *
 * @param {string} name - The name of the cookie.
 * @param {string} value - The value to store in the cookie.
 * @param {number} days - The number of days until the cookie expires. If set to 0 or omitted, the cookie will be a session cookie and will expire when the browser is closed.
 * 
 * @example
 * setCookie('username', 'JohnDoe', 7); // Sets a cookie that expires in 7 days
 */
export const setCookie = (name, value, days) => {
	let expires = "";
	if (days) {
		const date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		expires = "; expires=" + date.toUTCString();
	}
	document.cookie = name + "=" + (value || "") + expires + "; path=/"
}

/**
 * Retrieves the value of a cookie by its name.
 *
 * @param {string} name - The name of the cookie to retrieve.
 * @returns {string|null} The value of the cookie if found, otherwise null.
 * 
 * @example
 * const username = getCookie('username'); // Retrieves the value of the 'username' cookie
 */
export const getCookie = (name) => {
	const value = `; ${document.cookie}`
	const parts = value.split(`; ${name}=`)
	if (parts.length === 2) {
		return parts.pop().split(';').shift()
	}
	return null
}

export const statesOptions = [
	{ value: '', label: 'State' },
	{ value: 'AL', label: 'Alabama' },
	{ value: 'AK', label: 'Alaska' },
	{ value: 'AZ', label: 'Arizona' },
	{ value: 'AR', label: 'Arkansas' },
	{ value: 'AA', label: 'Armed Forces (AA)' },
	{ value: 'AE', label: 'Armed Forces (AE)' },
	{ value: 'AP', label: 'Armed Forces (AP)' },
	{ value: 'CA', label: 'California' },
	{ value: 'CO', label: 'Colorado' },
	{ value: 'CT', label: 'Connecticut' },
	{ value: 'DE', label: 'Delaware' },
	{ value: 'DC', label: 'District of Columbia' },
	{ value: 'FL', label: 'Florida' },
	{ value: 'GA', label: 'Georgia' },
	{ value: 'HI', label: 'Hawaii' },
	{ value: 'ID', label: 'Idaho' },
	{ value: 'IL', label: 'Illinois' },
	{ value: 'IN', label: 'Indiana' },
	{ value: 'IA', label: 'Iowa' },
	{ value: 'KS', label: 'Kansas' },
	{ value: 'KY', label: 'Kentucky' },
	{ value: 'LA', label: 'Louisiana' },
	{ value: 'ME', label: 'Maine' },
	{ value: 'MD', label: 'Maryland' },
	{ value: 'MA', label: 'Massachusetts' },
	{ value: 'MI', label: 'Michigan' },
	{ value: 'MN', label: 'Minnesota' },
	{ value: 'MS', label: 'Mississippi' },
	{ value: 'MO', label: 'Missouri' },
	{ value: 'MT', label: 'Montana' },
	{ value: 'NE', label: 'Nebraska' },
	{ value: 'NV', label: 'Nevada' },
	{ value: 'NH', label: 'New Hampshire' },
	{ value: 'NJ', label: 'New Jersey' },
	{ value: 'NM', label: 'New Mexico' },
	{ value: 'NY', label: 'New York' },
	{ value: 'NC', label: 'North Carolina' },
	{ value: 'ND', label: 'North Dakota' },
	{ value: 'OH', label: 'Ohio' },
	{ value: 'OK', label: 'Oklahoma' },
	{ value: 'OR', label: 'Oregon' },
	{ value: 'PA', label: 'Pennsylvania' },
	{ value: 'PR', label: 'Puerto Rico' },
	{ value: 'RI', label: 'Rhode Island' },
	{ value: 'SC', label: 'South Carolina' },
	{ value: 'SD', label: 'South Dakota' },
	{ value: 'TN', label: 'Tennessee' },
	{ value: 'TX', label: 'Texas' },
	{ value: 'UT', label: 'Utah' },
	{ value: 'VT', label: 'Vermont' },
	{ value: 'VA', label: 'Virginia' },
	{ value: 'WA', label: 'Washington' },
	{ value: 'WV', label: 'West Virginia' },
	{ value: 'WI', label: 'Wisconsin' },
	{ value: 'WY', label: 'Wyoming' }
]

export const countriesOptions = [
    { value: 'usa', label: 'United States of America' },
    { value: 'bel', label: 'Belgium' },
    { value: 'bgr', label: 'Bulgaria' },
    { value: 'cze', label: 'Czechia' },
    { value: 'dnk', label: 'Denmark' },
    { value: 'deu', label: 'Germany' },
    { value: 'grc', label: 'Greece' },
    { value: 'est', label: 'Estonia' },
    { value: 'irl', label: 'Ireland' },
    { value: 'ita', label: 'Italy' },
    { value: 'lva', label: 'Latvia' },
    { value: 'ltu', label: 'Lithuania' },
    { value: 'lux', label: 'Luxembourg' },
    { value: 'mlt', label: 'Malta' },
    { value: 'nld', label: 'Netherlands' },
    { value: 'pol', label: 'Poland' },
    { value: 'prt', label: 'Portugal' },
    { value: 'rou', label: 'Romania' },
    { value: 'svk', label: 'Slovakia' },
    { value: 'svn', label: 'Slovenia' },
    { value: 'esp', label: 'Spain' },
    { value: 'fin', label: 'Finland' },
    { value: 'fra', label: 'France' },
    { value: 'hrv', label: 'Croatia' },
    { value: 'hun', label: 'Hungary' },
    { value: 'aut', label: 'Austria' },
    { value: 'swe', label: 'Sweden' }
];
