import { runInAction } from 'mobx'
import rootStore from '../..' // Import the root store
import { getFullItm } from '../../../modules/api-item'

// import { setSprint, toTeamSprint } from '../../../modules/api-sprints'
import { warnPt } from '../../../config'
import { responseHasError } from '../../users/actions'
import { wrapWithLoading } from '../../general-actions'
import { MonthlyReport } from '../../../modules/cls/monthly-report'
import { setMonthlyReport } from '../../../modules/api-monthly-reports'

const monthlyReportsStore = rootStore.monthlyReports
/**
 * @async
 * @function newMonthlyReport
 * @description Creates a new monthly report item with the specified ID and marks it as new.
 * @param {string} newId - The ID of the new monthly report item.
 * @returns {Promise<void>}
 */
export const newMonthlyReport = async (newId) => {
	warnPt(newId)
	runInAction(() => {
		const _storeItm = requireMonthlyReportsById(newId)
		_storeItm.setNewFlag()
	})
}

/**
 * @function requireMonthlyReportsById
 * @description Retrieves a monthly report item by its ID from the store. If it doesn't exist, creates a new monthly report item.
 * @param {string} reportId - The ID of the monthly report item to retrieve.
 * @returns {MonthlyReport} The retrieved or newly created monthly report item.
 */
export const requireMonthlyReportsById = (reportId) => {
	warnPt(reportId)
	let report = monthlyReportsStore.reports[reportId]
	if (report) {
		return report
	}
	runInAction(() => {
		monthlyReportsStore.reports[reportId] = new MonthlyReport(reportId)
	})
	return monthlyReportsStore.reports[reportId]
}

/**
 * @async
 * @function actLoadMonthlyReport
 * @description Loads a monthly report item from the server by its ID and updates the store.
 * @param { function } navigate - The navigation function.
 * @param {string} reportId - The ID of the monthly report item to load.
 * @returns {Promise<void>}
 */
export const actLoadMonthlyReport = wrapWithLoading(
	async (reportId) => {
		const _response = await getFullItm('monthly_reports', {
			id: reportId
		})

		const hasError = responseHasError(_response)
		if (!hasError && _response.status === 'success') {
			runInAction(() => {
				const _storeItm = requireMonthlyReportsById(reportId)
				_storeItm.updateFromServer(_response.data || {})
			})
		} else {
			throw _response
		}
	},
	{ loadingMessage: 'Loading Monthly Report' },
	true // Activate rethrow to propagate errors
)

/**
 * @async
 * @function actSaveMonthlyReport
 * @description Saves a monthly report item to the server.
 * @param {string} reportId - The ID of the monthly report item to save.
 * @param {Object} full - The full data of the monthly report item to save.
 * @returns {Promise<boolean>} True if the save was successful, false otherwise.
 */
export const actSaveMonthlyReport = wrapWithLoading(
	async (reportId, full) => {
		const _response = await setMonthlyReport(reportId, full)

		const { data } = _response

		const hasError = data ? responseHasError(data) : true
		if (hasError) {
			throw data
		} else {
			const _storeItm = requireMonthlyReportsById(reportId)
			_storeItm.setModified(data.modified)
		}

		return true // No exceptions were thrown, so return true
	},
	{ loadingMessage: 'Saving Monthly Report' }
)
