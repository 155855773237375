import { makeObservable, observable, action, runInAction } from 'mobx'
class TourStore {
	open = false
	steps = []
	currentTour = ''
	deleteItemAct = () => {}

	constructor() {
		makeObservable(this, {
			open: observable,
			setOpen: action.bound,

			steps: observable,
			setSteps: action.bound,

			currentTour: observable,
			setCurrentTour: action.bound,

			startTour: action.bound,
			resetTour: action.bound,

			setDeleteItemAct: action.bound
		})
	}

	setOpen(isOpen) {
		this.open = isOpen
	}

	setSteps(steps) {
		this.steps = steps
	}

	setCurrentTour(tourName) {
		this.currentTour = tourName
	}

	startTour(tourName, steps) {
		runInAction(() => {
			this.setCurrentTour(tourName)
			this.setSteps(steps)
			this.setOpen(true)
		})
	}

	resetTour() {
		runInAction(() => {
			this.setSteps([])
			this.setCurrentTour('')
			this.setOpen(false)
			this.deleteItemAct()
		})
	}

	setDeleteItemAct(delMethod) {
		// Store the delete method to be executed later when needed
		this.deleteItemAct = delMethod
	}
}

// const tourStore = new TourStore()
export default TourStore
