import { getUserByUid, sendEmail, setUser } from '../../../modules/api-user'
import { runInAction } from 'mobx'
import { wrapWithLoading } from '../../general-actions'
import root from '../../'

export const responseHasError = (_response) => {
	if (_response?.status === 'error') {
		if (_response?.action === 'logout') {
			root.users.logout(window.location.pathname + window.location.search)
		}
		return true
	}
	return false
}

/**
 * @async
 * @function actLoadUser
 * @description Loads a user item from the server by its ID and updates the store.
 * @param {string} user_id - The ID of the user item to load.
 * @returns {Promise<void>}
 */
export const actLoadUser = wrapWithLoading(
	async (users, user_id, include_full = false) => {
		const _response = await getUserByUid(user_id, include_full)
		// TODO ::check if bug in destructure
		// Response is more complex, so we need to destructure it first
		const { data } = _response
		const hasError = responseHasError(data)
		if (!hasError && data.status === 'success') {
			const _storeItm = users.requireUserById(user_id)
			if (data?.user) {
				runInAction(() => {
					_storeItm.updateFromServer(data.user)
				})
			}
		} else {
			throw data // Throw the error, but only data value
		}
	},
	{ loadingMessage: 'Loading User' }
)

/**
 * @async
 * @function actSaveUser
 * @description Saves a user to the server.
 * @param {string} user_id - The ID of the user to save.
 * @param {Object} full - The full data of the user to save.
 * @returns {Promise<boolean>} True if the save was successful, false otherwise.
 */
export const actSaveUser = wrapWithLoading(
	async (user_id, full, allowed) => {
		await setUser(user_id, full, allowed)
			.then((response) => console.log('save user response', response))
			.catch((err) => {
				console.log('save user failed', err)
				throw err
			})
		return true // No exceptions were thrown, so return true
	},
	{ loadingMessage: 'Saving User' }
)

/**
 * @async
 * @function actSendMail
 * @description Sends an email using the provided values and handles loading state.
 * @param {Object} values - The data required to send the email (e.g., email, subject, body).
 * @returns {Promise<boolean>} Resolves to true if the email was sent successfully, false otherwise.
 */
export const actSendMail = wrapWithLoading(
	async (values) => {
		return sendEmail(values)
			.then((response) => {
				console.log('send email response', response)
				return response
			})
			.catch((err) => {
				console.log('send email failed', err)
				throw err
			})
	},
	{ loadingMessage: 'Sending Mail' }
)
