import React, { useEffect, useState } from 'react'
import {
	Button,
	List,
	Modal,
	Form,
	Input,
	DatePicker,
	InputNumber,
	Image,
	Row,
	Col,
	Select
} from 'antd'
import { observer } from 'mobx-react-lite'
import { Subtask, Subtasks } from '../../modules/cls/subtasks'
import ProgressInput from './ProgressInput'
import dayjs from 'dayjs'
const { TextArea } = Input
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { StatusTag } from '../Shared'
import { API_BASE_URL, DO_LOAD } from '../../config'
import UploadImage from '../Shared/UploadImage'
import { nextSortId } from '../../utils'
import Title from 'antd/es/typography/Title'
import { actLoadSprint, requireSprintById } from '../../stores/sprints/actions'
import { Link } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { TimedSubtask } from '../../modules/cls/subtasks/extensions/TimedSubtasks'
import { actLoadCompletedSprints } from '../../stores/ui-lst-sprints/actions'
import { useStores } from '../../stores/MobXProvider'

const DayPickerInMonth = observer(({ currentMonth, ...props }) => {
	const disabledDate = (current) => {
		const startOfCurrentMonth = currentMonth.startOf('month')
		const endOfCurrentMonth = currentMonth.endOf('month')
		return current < startOfCurrentMonth || current > endOfCurrentMonth
	}

	return (
		<DatePicker
			style={{ minWidth: '150px' }}
			defaultPickerValue={currentMonth}
			disabledDate={disabledDate}
			picker="date"
			{...props}
		/>
	)
})

const getCustomName = (name) => {
	const customName = dayjs.unix(name)
	return customName.isValid() ? customName : dayjs(name)
}

const EditSubtask = observer(
	({ subtaskType, subtask, onSave, onCancel, getNextSortId, ...props }) => {
		const [isModalVisible, setIsModalVisible] = useState(true)

		// Determine custom name based on subtask type
		const customName =
			subtaskType === 'timed'
				? {
						name: subtask?.name
							? getCustomName(subtask?.name)
							: props?.currentMonth
				  }
				: {}

		// Set the initial status based on the subtask type
		const subTaskStatus = subtaskType === 'timed' ? 'draft' : 'active'

		// Choose the appropriate subtask class based on the type
		let CustomSubtaskClass = subtaskType === 'timed' ? TimedSubtask :  Subtask

		// Create or edit the subtask based on whether one exists
		const editingSubtask = subtask || new CustomSubtaskClass({
			...customName,
			hours: 0,
			sprints: [],
			images: [],
			description: "",
			status: subTaskStatus,
			sortId: getNextSortId()
		})

		const [fileList, setFileList] = useState(
			editingSubtask.images.map((image) => ({
				uid: image,
				name: image,
				status: 'done',
				url: `${API_BASE_URL}/images/uploads/${image}`
			})) || []
		)

		// Handle changes to the file list
		const handleFileListChange = (newFileList) => {
			setFileList(
				newFileList.map((file) => ({
					uid: file.uid,
					name: file.name,
					status: file.status,
					url: file.url
				}))
			)
			editingSubtask.images = newFileList.map((file) => file.uid)
		}

		return (
			<Modal
				title={editingSubtask ? 'Edit Subtask' : 'Add Subtask'}
				open={isModalVisible}
				onCancel={() => {
					setIsModalVisible(false)
					onCancel() // Ensure modal close state is updated in parent
				}}
				footer={null}
				width={!isMobile && '50%'}
			>
				<Form
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					initialValues={editingSubtask || { status: 'active' }}
					onFinish={(values) => {
						const completeValues = values
						completeValues.images =
							fileList.map((file) => file.uid) || []
						onSave(completeValues)
					}}
				>
					<Form.Item
						label="Sort Id"
						name="sortId"
						rules={[
							{
								required: true,
								message: 'Please enter sortId'
							}
						]}
					>
						<InputNumber min={1} />
					</Form.Item>
					<Form.Item
						label="Name"
						name="name"
						rules={[
							{ required: true, message: 'Please enter name' }
						]}
					>
						{subtaskType === 'timed' ? (
							<DayPickerInMonth
								currentMonth={props?.currentMonth}
							/>
						) : (
							<Input />
						)}
					</Form.Item>
					{subtaskType === 'timed' && (
						<Form.Item
							label="Hours"
							name="hours"
							rules={[
								{
									required: true,
									message: 'Please enter hours'
								}
							]}
						>
							<InputNumber min={0} max={24} step={1} />
						</Form.Item>
					)}
					<Form.Item
						label="Description"
						name="description"
						rules={[
							{
								required: false,
								message: 'Please enter description'
							}
						]}
					>
						<TextArea rows={4} showCount />
					</Form.Item>
					<Form.Item
						label="Related Link"
						name="web"
						rules={[
							{
								pattern: new RegExp(/^(https?):\/\/([\w.-]+(\/\S*)?)$/),
								message: "Must start with HTTP or HTTPS and follow URL format. Please check the format."
							}
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item label="Upload Images">
						<UploadImage
							fileList={fileList}
							onFileListChange={handleFileListChange}
						/>
					</Form.Item>
					<Form.Item
						label="Status"
						name="status"
						rules={[
							{ required: true, message: 'Please select status' }
						]}
					>
						<ProgressInput
							type={
								subtaskType === 'timed'
									? 'monthly-report'
									: 'sprint'
							}
						/>
					</Form.Item>
					{
						subtaskType == 'timed' &&
							<Form.Item
								name="sprintIds"
								noStyle
							>
								<SprintList />
							</Form.Item>
					}
					<Form.Item>
						<Button type="primary" htmlType="submit">
							{editingSubtask ? 'Update' : 'Add'}
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		)
	}
)


/**
 * SprintList Component
 * Displays a list of related sprints with options to select and delete.
 * 
 * @param {Object} props - The component props
 * @param {Array} props.value - Currently selected sprint IDs
 * @param {Function} props.onChange - Function to call on sprint selection change
 * @param {Function} props.onDelete - Function to call on sprint deletion
 */
const SprintList = observer(({ value, onChange }) => {
	const { uiLstSprints, users } = useStores()
	const { loggedInUserId } = users
	const { ids } = uiLstSprints

	// Map sprint IDs to an array of options for the Select component
	const sprintOptions = ids.map((id) => {
		const sprint = requireSprintById(id)
		return {
			key: sprint.name,
			label: `${sprint.name} (${sprint.modified})`,
			value: id
		}
	})

	// Handle sprint deletion
	const handleDelete = (sprintId) => {
		onChange(
			value.filter((id) => id != sprintId)
		)
	}

	// Load completed sprints when the logged-in user ID changes
	useEffect(() => {
		actLoadCompletedSprints().catch(console.error)
	}, [loggedInUserId])

	return (
		<List
			style={{
				width: '100%',
				marginBottom: '5px',
				height: '300px',
				overflowY: 'auto',
				marginTop: 0
			}}
			header={
				<>
					<Title level={5}>Related Sprints</Title>
					<Row align="middle">
						<Select
							allowClear
							showSearch
							mode="multiple"
							style={{ width: '100%', marginRight: '10px' }}
							onChange={onChange}
							placeholder="Select Sprints"
							value={value}
							maxTagCount={1}
							maxTagPlaceholder={`+ more`}
							options={sprintOptions}
						/>
					</Row>
				</>
			}
			dataSource={value}
			renderItem={(sprintId) => (
				<List.Item
					actions={[
						<Button
							key={sprintId + 'delete'}
							type="link"
							onClick={() => {
								handleDelete(sprintId)
							}}
						>
							<DeleteOutlined />
						</Button>
					]}
				>
					<SprintDetails id={sprintId} />
				</List.Item>
			)}
		/>
	)
})

/**
 * SprintDetails Component
 * Displays detailed information about a single sprint.
 * 
 * @param {Object} props - The component props
 * @param {string} props.id - The ID of the sprint to display
 * @param {number} props.index - The index of the sprint in the list
 */
const SprintDetails = observer(({ id, index }) => {
	const sprint = requireSprintById(id)

	// Fetch sprint data if it hasn't been loaded yet
	useEffect(() => {
		// Fetch only if sprint is empty
		if (sprint.loaded === DO_LOAD) {
			actLoadSprint(id).catch(() => {})
		}
	}, [id])

	return (
		<Row wrap={false}>
			<Col flex="auto">
				<Link
					to={`/sprints/${id}/view`}
				>
					{index} {sprint.name}
				</Link>

				{sprint?.subtasks?.list.length > 0 && (
					<ul>
						{sprint.subtasks.list.map((subtask, subIndex) => (
							<li key={subIndex}>{subtask.name}</li>
						))}
					</ul>
				)}
			</Col>
		</Row>
	)
})

const SubtasksInput = observer(
	({ CustomClass = Subtasks, createdBy, value, onChange, ...props }) => {
		const { disabled } = props
		const subtasks = new CustomClass(value || [])
		
		// get the next sort ID for new subtasks
		const getNextSortId = () => {
			return nextSortId(value)
		}

		const subtasksType = subtasks.subtaskType // 'base' or 'timed'
		const subtasksIsTimed = subtasksType === 'timed'

		const [isModalVisible, setIsModalVisible] = useState(false)
		const [editingSubtask, setEditingSubtask] = useState(null)

		const showModal = () => {
			setEditingSubtask(null)
			setIsModalVisible(true)
		}

		const save = () => {
			onChange(subtasks.prepareForSave())
		}

		// Function to handle saving the edited subtask and creating new one
		const onSaveSubtask = (values) => {
			if (editingSubtask) {
				switch (subtasksType) {
					case 'timed':
						editingSubtask.name = values.name.unix()
						editingSubtask.hours = values.hours
						editingSubtask.setSprintsIds(values.sprintIds) // Set the new sprint IDs
						break
					default:
						editingSubtask.name = values.name
						break
				}

				editingSubtask.description = values.description
				editingSubtask.images = values.images
				editingSubtask.status = values.status
				editingSubtask.sortId = values.sortId
				editingSubtask.web = values.web
				subtasks.updateSubtask(editingSubtask)
			} else {
				subtasks.addSubtask(
					subtasksIsTimed ? values.name.unix() : values.name,
					subtasksIsTimed && values.hours,
					subtasksIsTimed && { list: values.sprintIds }, // Sprint IDs for timed subtasks
					values.images,
					values.description,
					values.status,
					values.web,
					values.sortId,
					createdBy
				)
			}
			save()
			setIsModalVisible(false)
		}

		// Function to handle editing a subtask
		const handleEdit = (subId) => {
			let subtask = subtasks.getSubtaskById(subId)

			//  Set the custom name of subtask's name if  it is a timed subtask
			if(subtask && subtasksIsTimed){
				subtask.setName(getCustomName(subtask?.name))
			}

			setEditingSubtask(subtask)
			setIsModalVisible(true)
		} 

		// Function to handle deleting a subtask
		const handleDelete = (subtaskId) => {
			subtasks.deleteSubtask(subtaskId)
			save()
		}

		return (
			<>
				<List
					header={
						!disabled && (
							<Button type="dashed" onClick={() => showModal(true)}>
								Add Subtask
							</Button>
						)
					}
					dataSource={subtasks.sortedList}
					renderItem={(subtask) => (
						<List.Item
							actions={
								!disabled
									? [
											<Button
												key={subtask.id + 'edit'}
												type="link"
												onClick={() =>
													handleEdit(subtask.id)
												}
											>
												<EditOutlined />
											</Button>,
											<Button
												key={subtask.id + 'delete'}
												type="link"
												onClick={() =>
													handleDelete(subtask.id)
												}
											>
												<DeleteOutlined />
											</Button>
									  ]
									: []
							}
						>
							<Row wrap={false}>
								<Col flex="auto">
									<StatusTag
										tag={subtask.status}
										style={{ marginRight: 3 }}
										isShort={true}
									/>
									{subtask.sortId} - {subtask.displayName}
									{props?.displaySubtaskDescription &&
										subtask?.description && (
											<div
												style={{
													whiteSpace: 'pre-line',
													fontSize: 12,
													color: '#7d7d7d',
													margin: '5px 0 0 0',
													padding: 0
												}}
											>
												{subtask.description}
											</div>
										)}
									{subtask?.images?.length > 0 && (
										<div>
											{subtask?.images &&
												subtask?.images?.map(
													(imagePath) => (
														<Image
															key={imagePath}
															width={100}
															style={{
																marginTop: '5px'
															}}
															preview={{
																maxScale: 20,
																destroyOnClose: true,
																imageRender:
																	() => (
																	<img
																		width="100%"
																		style={{
																			border: '2px solid #000',
																			borderRadius: '10px',
																			objectFit: 'contain',
																			maxHeight: '95vh',
																			maxWidth: '95vh',
																			boxShadow: '0 4px 25px rgba(0, 0, 0, 0.6)'
																		}}
																		src={`${API_BASE_URL}/images/uploads/${imagePath}`}
																	/>
																),
																toolbarRender:
																	() => null
															}}
															src={`${API_BASE_URL}/images/uploads/${imagePath}`}
														/>
													)
												)}
										</div>
									)}
									{subtask?.sprints?.list?.length > 0 && (
										<div style={{ marginTop: '5px' }}>
											<span>Related Sprints:</span>
											{subtask?.sprints?.list?.map(
												(id, index) => {
													return (
														<div key={id}>
															<SprintDetails
																id={id}
																index={`${ index + 1 })`}
															/>
														</div>
													)
												}
											)}
										</div>
									)}
									{
										subtask?.web && 
											<div
												style={{ marginTop: '5px' }}
											>
												<a 
													href={subtask?.web} 
													target="_blank" 
													rel="noreferrer"
												>
														Open link
												</a>
											</div>
									}
								</Col>
							</Row>
						</List.Item>
					)}
				/>
				{isModalVisible && (
					<EditSubtask
						subtaskType={subtasksType}
						subtask={editingSubtask}
						getNextSortId={getNextSortId}
						onSave={onSaveSubtask}
						onCancel={() => setIsModalVisible(false)}
						currentMonth={props?.currentMonth}
					/>
				)}
			</>
		)
	}
)

export default SubtasksInput
