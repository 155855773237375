import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Routes, Route, useNavigate } from 'react-router-dom'
import { useStores } from '../../stores/MobXProvider'
import { PageLoader } from '../../components/Shared'
import MonthlyReportsLst from './MonthlyReportsLst'
import MonthlyReportData from './MonthlyReportData'
import {
	actLoadMonthlyReport,
	requireMonthlyReportsById
} from '../../stores/monthly-reports/actions'

const MonthlyReportsMain = () => {
	const navigate = useNavigate()

	const { users } = useStores()
	const { loggedInUserId } = users
	useEffect(() => {
		if (!users.isLoggedIn) {
			navigate('/logout')
			return
		}
	}, [loggedInUserId])

	return (
		<Routes>
			<Route
				path=":itmId/*"
				element={
					<PageLoader
						forceLoad={true}
						requireItm={(id) => {
							return requireMonthlyReportsById(id)
						}}
						loadItm={(id) => actLoadMonthlyReport(id)}
						targetLst="monthly-reports"
					/>
				}
			>
				<Route path="data" element={<MonthlyReportData />} />
				<Route
					path="view"
					element={<MonthlyReportData viewOnly={true} />}
				/>
			</Route>
			<Route path="/" element={<MonthlyReportsLst />} />
			<Route path="*" element={<div>Not found</div>} />
		</Routes>
	)
}
export default observer(MonthlyReportsMain)
