import { runInAction } from 'mobx'
import rootStore from '../..' // Import the root store
import { Request } from '../../../modules/cls/request'
import { getFullItm } from '../../../modules/api-item'
import { setRequest } from '../../../modules/api-requests'
import { cleanObject, warnPt } from '../../../config'
import { responseHasError } from '../../users/actions'
import { wrapWithLoading } from '../../general-actions'

const requestsStore = rootStore.requests

/**
 * @async
 * @function newRequest
 * @description Creates a new request item with the specified ID and marks it as new.
 * @param {string} newId - The ID of the new request item.
 * @returns {Promise<void>}
 */
export const newRequest = async (newId) => {
	warnPt(newId)
	runInAction(() => {
		const _storeItm = requireRequestById(newId)
		_storeItm.setNewFlag()
	})
}

/**
 * @function requireRequestById
 * @description Retrieves a request item by its ID from the store. If it doesn't exist, creates a new request item.
 * @param {string} requestId - The ID of the request item to retrieve.
 * @returns {Request} The retrieved or newly created request item.
 */
export const requireRequestById = (requestId) => {
	warnPt(requestId)
	let request = requestsStore.requests[requestId]
	if (request) {
		return request
	}
	runInAction(() => {
		requestsStore.requests[requestId] = new Request(requestId)
	})
	return requestsStore.requests[requestId]
}

/**
 * @async
 * @function actLoadRequest
 * @description Loads a request item from the server by its ID and updates the store.
 * @param { function } navigate - The navigation function.
 * @param {string} requestId - The ID of the request item to load.
 * @returns {Promise<void>}
 */
export const actLoadRequest = wrapWithLoading(
	async (requestId) => {
		const _response = await getFullItm('requests', {
			id: requestId,
			column_id: 'request_id'
		})
		
		const hasError = responseHasError(_response)
		if (!hasError && _response.status === 'success') {
			const { data } = _response
			runInAction(() => {
				const _storeItm = requireRequestById(requestId)
				_storeItm.updateFromServer(data || {})
			})
		} else {
			throw _response
		}
	},
	{ loadingMessage: 'Loading Request' }
)

/**
 * @async
 * @function actSaveRequest
 * @description Saves a request item to the server.
 * @param {string} requestId - The ID of the request item to save.
 * @param {Object} full - The full data of the request item to save.
 * @returns {Promise<boolean>} True if the save was successful, false otherwise.
 */
export const actSaveRequest = wrapWithLoading(
	async (requestId, full) => {
		full = cleanObject(full)
		const _response = await setRequest(requestId, full)

		const hasError = responseHasError(_response)
		if (hasError) {
			throw _response
		}
		return true // No exceptions were thrown, so return true
	},
	{ loadingMessage: 'Saving Request' }
)
