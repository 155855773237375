import { get, set } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import dayjs from 'dayjs'

import { Subtasks } from './subtasks'
import { Comments } from './comments'
import { Recurrence } from './recurrence'
import { History } from './history'
/**
 * Base class for all classes that will be used in a list such as: sprints, requests, etc.
 * This class should be extended by all classes that will be used in a list.
 * Contains the basic structure for a list item.
 *
 * Structure:
 * - id: The id of the item.
 * - compact: The compact data of the item.
 * - full: The full data of the item.
 * - loaded: The timestamp of the last time the full data was loaded.
 *
 * Also contains:
 * - updateFromServer: Updates the item with the server data.
 * - chgInFull: Helper function to change a value in the full object.
 * - setNewFlag: Sets the loaded flag to -2.
 * - resetLastFullLoad: Resets the loaded flag to the current timestamp.
 *
 * @class LstItmBase
 */
export class LstItmBase {
	id = ''
	to_delete = false
	compact = {}
	full = {}
	loaded = -1
	subtasks
	comments
	recurrence
	history
	modified
	sprint_team_count
	progress_team

	constructor(id) {
		this.id = id
		this.subtasks = this.subtaskConstructor
		this.comments = new Comments(this.full.comments || [])
		this.recurrence = new Recurrence(this.full.recurrence || {})
		this.history = new History(this.full.history || {})

		makeObservable(this, {
			id: observable,
			subtasks: observable,
			comments: observable,
			recurrence: observable,
			history: observable,

			to_delete: observable,
			compact: observable,
			full: observable,
			loaded: observable,

			createdTs: computed,
			setCreatedTs: action.bound,

			createdBy: computed,
			setCreatedBy: action.bound,

			for_user_id: computed,
			setForUser_id: action.bound,

			category_id: computed,
			setCategory_id: action.bound,

			name: computed,
			setName: action.bound,
			displayName: computed,

			progress: computed,
			setProgress: action.bound,

			setNewFlag: action.bound,
			resetLastFullLoad: action.bound,
			updateFromServer: action,

			// Mark as to delete or undo delete
			toDeleteTs: computed,
			setToDelete: action.bound,

			// Platforms
			platformIds: computed,
			setPlatformIds: action.bound,

			// Subtasks
			getSubtasks: computed,
			setSubtasks: action.bound,

			// Comments
			getComments: computed,
			setComments: action.bound,

			// Recurrence
			setRecurrence: action.bound,
			recurrenceType: computed,
			setRecurrenceType: action.bound,

			recurrenceStartEnd: computed,
			setRecurrenceStartEnd: action.bound,

			recurrenceTimes: computed,
			setRecurrenceTimes: action.bound,

			recurrenceDaysOfWeek: computed,
			setRecurrenceDaysOfWeek: action.bound,

			recurrenceDayOfMonth: computed,
			setRecurrenceDayOfMonth: action.bound,

			recurrenceMonths: computed,
			setRecurrenceMonths: action.bound,

			recurrenceNext: computed,
			setRecurrenceNext: action.bound,

			// History
			setHistoryInFull: action.bound,

			// Modified
			modifiedTs: computed,
			setModified: action.bound,

			priority: computed,
			setPriority: action.bound,

			effortEstimate: computed,
			setEffortEstimate: action.bound
		})
	}

	get subtaskConstructor() {
		return new Subtasks(this.full.subtasks || [])
	}

	// Priority field (0 is default, higher numbers indicate higher priority)
	get priority() {
		return get(this.full, ['priority'], get(this.compact, ['priority'], 0)) // Default priority to 0
	}

	setPriority = (val) => {
		this.chgInFull(['priority'], val)
	}

	// Effort estimate for the item
	get effortEstimate() {
		return get(this.full, ['effortEstimate'], 0)
	}

	setEffortEstimate = (val) => {
		this.chgInFull(['effortEstimate'], val)
	}

	/*
	 * @returns {Array} - The assigned platform ids.
	 * - platform id is in direct relation with user.platforms
	 */
	get platformIds() {
		return get(this.full, ['platformIds'], [])
	}

	setPlatformIds = (val) => {
		this.chgInFull(['platformIds'], val)
	}

	get toDeleteTs() {
		return this.to_delete ? dayjs(this.to_delete).unix() : null
	}

	setToDelete(val) {
		this.to_delete = val
	}

	/**
	 * Timestamp when the item was created.
	 * @returns {number} The creation timestamp.
	 */
	get createdTs() {
		return get(this.full, ['createdTs'], get(this.compact, ['createdTs']))
	}

	/**
	 * Sets the creation timestamp to the current time.
	 */
	setCreatedTs = () => {
		this.chgInFull(['createdTs'], dayjs().unix())
	}

	/**
	 * User who created the item (user id).
	 * @returns {string} The id of the user who created the item.
	 */
	get createdBy() {
		return get(
			this.full,
			['createdBy'],
			get(this.compact, ['createdBy'], '')
		)
	}

	/**
	 * Sets the user id of the creator.
	 * @param {string} uid - The user id.
	 */
	setCreatedBy = (uid) => {
		this.chgInFull(['createdBy'], uid)
	}

	/**
	 * Sets the creation timestamp and user id of the creator.
	 * @param {string} uid - The user id.
	 */
	setCreator = (uid) => {
		this.setCreatedTs()
		this.setCreatedBy(uid)
	}

	/**
	 * Sets the loaded flag to -2.
	 */
	setNewFlag = () => {
		this.loaded = -2
	}

	/**
	 * Resets the loaded flag to the current timestamp.
	 */
	resetLastFullLoad = () => {
		this.loaded = dayjs().unix()
	}

	get for_user_id() {
		return get(
			this.full,
			['for_user_id'],
			get(this.compact, ['for_user_id'], '')
		)
	}

	setForUser_id = (val) => {
		this.chgInFull(['for_user_id'], val)
	}

	/**
	 * Category id associated with the item.
	 * @returns {string} The category id.
	 */
	get category_id() {
		return get(
			this.full,
			['category_id'],
			get(this.compact, ['category_id'], '')
		)
	}

	/**
	 * Sets the category id.
	 * @param {string} val - The category id.
	 */
	setCategory_id = (val) => {
		this.chgInFull(['category_id'], val)
	}

	/**
	 * Name of the item.
	 * @returns {string} The name of the item.
	 */
	get name() {
		return get(this.full, ['name'], get(this.compact, ['name'], ''))
	}

	get displayName() {
		return this.name
	}

	/**
	 * Sets the name of the item.
	 * @param {string} val - The name of the item.
	 */
	setName = (val) => {
		this.chgInFull(['name'], val)
	}

	/**
	 * Progress of the item.
	 * @returns {number} The progress of the item.
	 */
	get progress() {
		return get(
			this.full,
			['progress'],
			get(this.compact, ['progress'], 'draft')
		)
	}

	/**
	 * Sets the progress of the item.
	 * @param {number} val - The progress value.
	 */
	setProgress = (val) => {
		this.chgInFull(['progress'], val)
	}

	get getSubtasks() {
		return this.subtasks.list
	}
	setSubtasks = (subtasks) => {
		this.subtasks = new Subtasks(subtasks)
		this.chgInFull(['subtasks'], subtasks || [])
	}
	/**
	 * Adds a subtask to the Sprint.
	 *
	 * @param {string} name - The name of the subtask.
	 * @param {string} description - The description of the subtask.
	 * @param {string} status - The status of the subtask.
	 * @param {string} createdBy - The user who created the subtask.
	 */
	addSubtask(name, description, status, createdBy) {
		this.subtasks.addSubtask(name, description, status, createdBy)
		// Reflect changes in full object
		this.full.subtasks = this.subtasks.list
	}

	/**
	 * Deletes a subtask from the Sprint by id.
	 *
	 * @param {string} subtaskId - The id of the subtask to delete.
	 */
	deleteSubtask(subtaskId) {
		this.subtasks.deleteSubtask(subtaskId)
		// Reflect changes in full object
		this.full.subtasks = this.subtasks.list
	}

	/**
	 * Gets a subtask by id.
	 *
	 * @param {string} subtaskId - The id of the subtask to get.
	 * @returns {Subtask} The subtask with the given id.
	 */
	getSubtaskById(subtaskId) {
		return this.subtasks.getSubtaskById(subtaskId)
	}

	get getComments() {
		return this.comments.list
	}

	setComments = (comments) => {
		this.comments = new Comments(comments)
		this.chgInFull(['comments'], comments || [])
	}
	/**
	 * Adds a comment to the Sprint.
	 *
	 * @param {string} description - The description of the comment.
	 * @param {string} createdBy - The user who created the comment.
	 */
	addComment(description, images, createdBy) {
		this.comments.addComment(description, images, createdBy)
		// Reflect changes in full object
		this.full.comments = this.comments.list
	}

	/**
	 * Deletes a comment from the Sprint by id.
	 *
	 * @param {string} commentId - The id of the comment to delete.
	 */
	deleteComment(commentId) {
		this.comments.deleteComment(commentId)
		// Reflect changes in full object
		this.full.comments = this.comments.list
	}

	/**
	 * Gets a comment by id.
	 *
	 * @param {string} commentId - The id of the comment to get.
	 * @returns {Comment} The comment with the given id.
	 */
	getCommentById(commentId) {
		return this.comments.getCommentById(commentId)
	}

	setRecurrence = (recurrence) => {
		this.recurrence = new Recurrence(recurrence)
		this.chgInFull(['recurrence'], recurrence)
	}

	setHistoryInFull = () => {
		this.chgInFull(['history'], this.history.categories)
	}
	/**
	 * Sets the recurrence type of the Sprint.
	 * Possible value: deadline, day, week, month, year
	 * @param {string} type - The recurrence type to set.
	 */
	get recurrenceType() {
		return this.recurrence.type
	}

	/**
	 * Sets the recurrence type of the Sprint.
	 *
	 * @param {string} type - The recurrence type to set.
	 */
	setRecurrenceType(type) {
		this.recurrence.setType(type)
		this.chgInFull(['recurrence', 'type'], type)
	}

	/**
	 * Gets the start and end dates of the Sprint's recurrence.
	 * Defaults to an empty array if not set.
	 * Timestamp example value: [ 1717794000, 1722373200 ]
	 * @returns {Array} The start and end dates of the recurrence.
	 */
	get recurrenceStartEnd() {
		return get(this.recurrence, 'startEnd', [])
	}
	/**
	 * Sets the start and end dates of the Sprint's recurrence.
	 *
	 * @param {Array} startEnd - The start and end dates to set.
	 */
	setRecurrenceStartEnd(startEnd) {
		this.recurrence.setStartEnd(startEnd)
		this.chgInFull(['recurrence', 'startEnd'], startEnd)
	}

	/**
	 * Gets the recurrence times of the Sprint.
	 * Defaults to an empty array if not set.
	 *
	 * @returns {Array} The recurrence times.
	 */
	get recurrenceTimes() {
		return get(this.recurrence, 'times', [])
	}
	/**
	 * Sets the recurrence times of the Sprint.
	 * This is a between hours, if same hour than it's a deadline by that hour. Example value: [ "07:00", "20:00" ]
	 * @param {Array} times - The recurrence times to set.
	 */
	setRecurrenceTimes(times) {
		this.recurrence.setTimes(times)
		this.chgInFull(['recurrence', 'times'], times)
	}
	/**
	 * Gets the recurrence days of the week of the Sprint.
	 * Defaults to an empty array if not set.
	 * Possible value: [monday, tuesday, wednesday, thursday, friday, saturday, sunday]
	 * @returns {Array} The recurrence days of the week.
	 */
	get recurrenceDaysOfWeek() {
		return get(this.recurrence, 'daysOfWeek', [])
	}
	/**
	 * Sets the recurrence days of the week of the Sprint.
	 *
	 * @param {Array} daysOfWeek - The recurrence days of the week to set.
	 */
	setRecurrenceDaysOfWeek(daysOfWeek) {
		this.recurrence.setDaysOfWeek(daysOfWeek)
		this.chgInFull(['recurrence', 'daysOfWeek'], daysOfWeek)
	}
	/**
	 * Gets the recurrence day of the month.
	 * For values, check <DaysOfMonth /> component.
	 * Possible values:
		- first (first day of the month)
		- first-work-day (first work day of the month from monday to friday)
		- first-monday, first-tuesday, first-wednesday, first-thursday, first-friday, first-saturday, first-sunday

		-last (last day of the month)
		-last-work-day (last work day of the month from monday to friday)
		-last-monday, last-tuesday, last-wednesday, last-thursday, last-friday, last-saturday, last-sunday
	*
	 * @returns {string} The recurrence day of the month.
	 */
	get recurrenceDayOfMonth() {
		return get(this.recurrence, 'dayOfMonth', '')
	}

	/**
	 * Sets the recurrence day of the month.
	 * For values, check <DaysOfMonth /> component.
	 *
	 * @param {string} dayOfMonth - The recurrence day of the month to set.
	 */
	setRecurrenceDayOfMonth(dayOfMonth) {
		this.recurrence.setDayOfMonth(dayOfMonth)
		this.chgInFull(['recurrence', 'dayOfMonth'], dayOfMonth)
	}

	/**
	 * Gets the recurrence months of the Sprint.
	 * Defaults to an empty array if not set.
	 * * Possible values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
	 * Where 0 is January and 11 is December
	 * @returns {Array} The recurrence months.
	 */
	get recurrenceMonths() {
		return get(this.recurrence, 'months', [])
	}
	/**
	 * Sets the recurrence months of the Sprint.
	 *
	 * @param {Array} months - The recurrence months to set.
	 */
	setRecurrenceMonths(months) {
		this.recurrence.setMonths(months)
		this.chgInFull(['recurrence', 'months'], months)
	}

	/*
	 * Gets the next recurrence date.
	 */
	get recurrenceNext() {
		return get(this.recurrence, 'next', null)
	}

	setRecurrenceNext() {
		// Callback to set the Item progress to completed when the next recurrence is calculated and there is none left.
		const onCompletion = () => {
			this.setProgress('completed')
		}
		this.recurrence.calculateNext(onCompletion)
		this.chgInFull(['recurrence', 'next'], this.recurrence.next)
	}

	// modified comes from the server, added using new Date(), and it comes in the format 2024-08-29 11:12:48. I need to convert it to timestamp
	get modifiedTs() {
		return dayjs(this.modified).unix()
	}

	setModified(modified) {
		this.modified = modified
	}

	/**
	 * Updates the item with the data from the server.
	 * @param {object} srvJson - The server data.
	 */
	updateFromServer(srvJson) {
		// console.log('srvJson', srvJson)
		// Ensure srvJson.compact is parsed if it is a JSON string
		if (typeof srvJson.compact === 'string') {
			try {
				this.compact = JSON.parse(srvJson.compact)

				// Initialize recurrence with the compact data
				this.recurrence = new Recurrence(this.compact.recurrence || {})

				// Update the full object with related compact data
				this.setProgress(this.compact.progress)
				this.setName(this.compact.name)
			} catch (error) {
				console.error('Failed to parse compact JSON:', error)
				this.compact = null // Handle the error as needed
			}
		} else {
			this.compact = srvJson.compact
		}

		if (srvJson?.full) {
			if (typeof srvJson.full === 'string') {
				try {
					this.full = JSON.parse(srvJson.full)
				} catch (error) {
					console.error('Failed to parse full JSON:', error)
					this.full = null // Handle the error as needed
				}
			} else {
				this.full = srvJson.full
			}
			// Initialize subtasks with the loaded data
			this.subtasks = new Subtasks(this.full.subtasks || [])
			this.comments = new Comments(this.full.comments || [])
			this.recurrence = new Recurrence(this.full.recurrence || {})
			this.history = new History(this.full.history || {})
			this.resetLastFullLoad()
		}

		// Let's add to_delete, modified, sprint_team_count, progress_team to the item
		this.to_delete = srvJson?.to_delete || false
		this.modified = srvJson?.modified || null
		this.sprint_team_count = srvJson?.sprint_team_count || 0
		this.progress_team = srvJson?.progress_team || null
	}

	/**
	 * Helper function to change a value in the full object.
	 * @param {Array} path - The path to the property in the full object.
	 * @param {any} val - The value to set.
	 */
	chgInFull = (path, val) => {
		if (get(this.full, path) !== val) {
			set(this.full, path, val)
		}
	}
}
