import ax from './api'

/*
 * User get and set (update or insert)
 * @param {string} userId - The id of the user
 * $returns {object} The response from the server
 */
export const getUserByUid = async (userId, include_full = 'false') => {
	return await ax.get(
		`/user/get.php?user_id=${userId}&include_full=${include_full}`
	)
}

/*
 * Set a user on the server
 * @param {string} userId - The id of the user
 * @param {object} full - The full object of the user
 * @returns {object} The response from the server
 */
export const setUser = async (userId, full, allowed) => {
	// Convert full object to JSON string
	const fullStr = JSON.stringify(full)
	const allowedStr = JSON.stringify(allowed)

	// Send POST request with data in the body
	try {
		const response = await ax.post(`/user/set.php`, {
			user_id: userId,
			full: fullStr,
			allowed: allowedStr
		})
		return response
	} catch (error) {
		console.error('Error in setUser:', error)
		throw error
	}
}

/*
 * User login and registration
 * @param {object} values - The values to send to the server
 * @returns {object} The response from the server
 * @example
 * const values = {
  	username: 'myusername',
  	password: 'mypassword'
  }
 *
 */

export const login = async (values) => {
	const { data } = await ax.post('/user/login.php', values)
	return data
}

/*
 * Register a new user
 * @param {object} values - The values to send to the server
 * @returns {object} The response from the server
 * @example
 * const values = {
  	username: 'myusername',
  	password: 'mypassword'
  }
 *
 */
export const register = async (values) => {
	const { data } = await ax.post('/user/register.php', values)
	return data
}


/*
 * Sends an email using the specified values.
 * @param {object} values - The values to send to the server
 * @returns {object} The response from the server
 * @example
 * const values = {
    email: 'example@example.com',
    subject: 'Hello!',
    body: 'This is a test email.'
  }
 *
 */
export const sendEmail = async (values) => {
	const { data } = await ax.post('/user/email.php', values)
	return data
}