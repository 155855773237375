import React from 'react'
import { Form } from 'antd'
import PropTypes from 'prop-types'

/*
 * This component is a wrapper for the antd Form.Item component.
 * It provides additional functionality to hide form items based on certain conditions.
 */
const FormItem = ({ children, ...props }) => {
	const { viewOnly, hidden, ...rest } = props

	let overrideHidden = hidden
	// Easy way to control the visibility of a form item in viewOnly mode
	if (viewOnly && (rest?.value === '' || !rest?.value)) {
		// If the value is empty, hide the form item
		overrideHidden = true
	}

	return (
		<Form.Item noStyle={overrideHidden} hidden={overrideHidden} {...rest}>
			{children}
		</Form.Item>
	)
}

FormItem.propTypes = {
	children: PropTypes.node,
	hidden: PropTypes.bool,
	viewOnly: PropTypes.bool
}

export default FormItem
