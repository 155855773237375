/* eslint-disable @typescript-eslint/no-unused-vars */
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import {
	Space,
	ConfigProvider,
	Form,
	Input,
	Card,
	Row,
	Col,
	Tag,
	Tooltip,
	Switch,
	DatePicker,
	Button
} from 'antd'
import {
	WarningOutlined,
	InfoCircleOutlined,
	HistoryOutlined,
	EyeOutlined,
	SettingOutlined
} from '@ant-design/icons'

import Title from 'antd/es/typography/Title'
import { runInAction, toJS } from 'mobx'

import { IS_NEW } from '../../config'
import { useStores } from '../../stores/MobXProvider'

import {
	CommentsInput,
	SubtasksInput,
	SubmitButton
} from '../../components/Form'
import { Features, FormatedTimestamp } from '../../components/Shared'
import ProgressInput from '../../components/Form/ProgressInput'
import { getTourArrayByKeys } from '../../stores/tour/config'
import '../../design/styles/patterns.css' // Create a CSS file to handle custom styles
import { GeneralTourTitle } from '../../components/Shared/GeneralComponents'
import {
	actLoadMonthlyReport,
	actSaveMonthlyReport,
	requireMonthlyReportsById
} from '../../stores/monthly-reports/actions'
import dayjs from 'dayjs'
import { TimedSubtasks } from '../../modules/cls/subtasks/extensions/TimedSubtasks'
import ProgressModal from '../../components/Form/ProgressModal'
const { MonthPicker } = DatePicker

const MonthlyReportData = ({ viewOnly = false }) => {
	const navigate = useNavigate()
	const { itmId } = useParams()
	const {
		uiLstMonthlyReports,
		users,
		tourStore,
		hasIntervalLoad,
		toggleIntervalLoad
	} = useStores()
	const { loggedInUserId, loggedInUser } = users
	const isAdmin = loggedInUser.isAdmin
	const isSuperAdmin = loggedInUser.isSuperAdmin

	const withIntervalLoad = hasIntervalLoad(itmId)

	const user = users.requireUserById(loggedInUserId)

	const _itm = requireMonthlyReportsById(itmId)
	const selfPermission =
		_itm.createdBy === loggedInUser.user_id || isAdmin || isSuperAdmin
	// and item not new
	if (!selfPermission && _itm.loaded !== IS_NEW) {
		viewOnly = true
	}

	// Function to generate initial form values
	const generateInitialFormValues = () => ({
		name: _itm.name ? dayjs.unix(_itm.name) : dayjs().startOf('month'),
		progress: _itm.progress,
		hoursWorked: _itm.hoursWorked,
		subtasks: toJS(_itm.subtaskConstructor).list,
		comments: toJS(_itm.getComments)
	})

	const _doSave = (vals) => {
		if (vals !== null) {
			runInAction(() => {
				// Set creator and timestamp
				!_itm.createdBy && _itm.setCreator(loggedInUserId)

				// Reset last full load
				_itm.loaded === IS_NEW && _itm.resetLastFullLoad()

				// Form values
				_itm.setName(vals?.name ? vals?.name.unix() : '')
				_itm.setProgress(vals.progress)
				_itm.setHoursWorked(vals.hoursWorked)

				// Subtasks
				_itm.setSubtasks(vals.subtasks)
				// Comments
				_itm.setComments(vals.comments)
			})

			actSaveMonthlyReport(itmId, toJS(_itm.full))
				.then(() => {
					navigate('/monthly-reports')
				})
				.catch(console.error)
		}
	}

	const [form] = Form.useForm()
	const currentMonth = Form.useWatch('name', form)

	const subtasksTrack = Form.useWatch('subtasks', form)
	useEffect(() => {
		if (subtasksTrack) {
			form.setFieldsValue({
				hoursWorked: subtasksTrack.reduce(
					(sum, task) => sum + (task.hours || 0),
					0
				)
			})
		}
	}, [subtasksTrack])

	// Update form when _itm.modified changes
	useEffect(() => {
		form.setFieldsValue(generateInitialFormValues())
	}, [_itm.modified]) // Track _itm.modified

	const [formIsDisabled, setFormIsDisabled] = React.useState(
		!!_itm.to_delete ?? viewOnly
			? true
			: isAdmin
			? false
			: !['draft'].includes(_itm.progress)
	)

	//set default progress value to 'active' is user is admin or superAdmin for new monthly report
	useEffect(() => {
		if (_itm.loaded == IS_NEW && (isAdmin || isSuperAdmin)) {
			form.setFieldsValue({
				progress: 'draft'
			})
		}
	}, [isAdmin, isSuperAdmin])

	// Track changes to _itm.to_delete to update form disabled state and tag visibility
	useEffect(() => {
		if (!viewOnly || !!_itm.to_delete) {
			withIntervalLoad && toggleIntervalLoad(itmId)
		}
		setFormIsDisabled(
			viewOnly
				? true
				: !!_itm.to_delete ??
						(isAdmin ? false : !['draft'].includes(_itm.progress))
		)
		form.setFieldsValue(generateInitialFormValues())
	}, [_itm.to_delete, viewOnly])

	const userIdTrack = Form.useWatch('for_user_id', form)

	const [features, setFeatures] = React.useState({
		subtasks: toJS(_itm.getSubtasks)?.length > 0,
		comments: viewOnly ? true : toJS(_itm.getComments)?.length > 0
	})

	// on features change, set all related fields to empty
	const onFeaturesChange = (features) => {
		if (!features.subtasks) {
			form.setFieldsValue({
				subtasks: []
			})
		}
		if (!features.comments) {
			form.setFieldsValue({
				comments: []
			})
		}
	}

	const onSetProgressChange = () => {
		runInAction(() => {
			uiLstMonthlyReports.setModal('monthly-report-progress', {
				visible: true,
				itmId: itmId
			})
		})
	}

	/*
	 * Tour Reccurence
	 * This is a tour for the recurrence fields. Manually added to the tour due to possible scenario were list item might not have all recurrence types.
	 */
	let tourRecurrence = features.recurrence
		? getTourArrayByKeys('recurrence', ['section'])
		: []

	/*
	 * Tour features
	 * This is a tour for the features of the report.
	 */
	const tourFeatures =
		!viewOnly && !formIsDisabled
			? getTourArrayByKeys('features', ['all'])
			: []

	const tourForm = getTourArrayByKeys('form', [
		'section',
		'name',
		'category_id',
		'user_id',
		// 'for_user_id',
		'platformIds',
		'progress'
	])

	/**
	 * Start tour:
	 * This function starts the tour for the report form.
	 * It includes the general form, subtasks, comment and recurrence fields.
	 */
	const startTour = () => {
		tourStore.startTour('SprintData-tour', [
			!!!_itm.to_delete && {
				title: 'Sync on interval',
				description:
					'Enable or disable data synchronization on interval for the report. Any additions or modifications will be automatically updated even if working in multiple tabs or is updated by another user.',
				target: () => document.getElementById('target-interval-itm')
			},
			// Features
			...tourFeatures,

			// General info
			...getTourArrayByKeys('info', ['name', 'modified']),

			// General form
			...getTourArrayByKeys('form', ['section']),

			// Recurrence
			...tourRecurrence
		])
	}

	const startTourForm = () => {
		tourStore.startTour('SprintData-tour', [
			// General form
			...tourForm
		])
	}

	return (
		<Space direction="vertical" style={{ width: '100%' }}>
			<GeneralTourTitle
				titleProps={{ text: 'Monthly Report' }}
				buttonProps={{
					onClick: startTour
				}}
				rowProps={{
					justify: 'space-between'
				}}
			>
				{!!_itm.to_delete && (
					<Tooltip
						title={
							<>
								Delete on{' '}
								<FormatedTimestamp
									timestamp={_itm.toDeleteTs}
								/>
							</>
						}
					>
						<Tag color="red" icon={<WarningOutlined />}>
							Marked for deletion
						</Tag>
					</Tooltip>
				)}
				{!!!_itm.to_delete && (
					<Switch
						id="target-interval-itm"
						style={{ marginRight: 10 }}
						tooltip="Sync data on interval"
						value={withIntervalLoad}
						checkedChildren="Sync on"
						unCheckedChildren="Sync off"
						defaultChecked
						onChange={() => toggleIntervalLoad(itmId)}
					/>
				)}
			</GeneralTourTitle>

			{!viewOnly && !formIsDisabled && (
				<Features
					features={features}
					setFeatures={(val) => {
						setFeatures(val)
						onFeaturesChange(val)
					}}
				/>
			)}
			<Card
				title={
					<span id="target-info-name">
						{viewOnly ? (
							<Tooltip title="View Monthly Report">
								<EyeOutlined id="target-mode" />{' '}
								{dayjs.unix(_itm.name)?.format('YYYY-MM')}
							</Tooltip>
						) : _itm.loaded === IS_NEW ? (
							'Create new monthly report'
						) : (
							<Tooltip title="Edit monthly report">
								<SettingOutlined />{' '}
								{dayjs.unix(_itm.name)?.format('YYYY-MM')}
							</Tooltip>
						)}{' '}
					</span>
				}
				style={{ width: '100%' }}
				extra={
					<div id="target-info-modified">
						<Tooltip title="Last updated">
							<FormatedTimestamp
								timestamp={_itm.modifiedTs}
								formatedAs="DD.MM.YYYY hh:mm"
							/>{' '}
							<HistoryOutlined />
						</Tooltip>
					</div>
				}
			>
				<ProgressModal
					CustomClass={TimedSubtasks}
					modalKey="monthly-report-progress"
					uiStore={uiLstMonthlyReports}
					requireItemById={requireMonthlyReportsById}
					loadItm={(id) => actLoadMonthlyReport(id)}
					saveItem={(itemId, itemValue) => {
						actSaveMonthlyReport(itemId, itemValue),
							form.setFieldsValue({
								progress: _itm.progress
							})
					}}
				/>
				<ConfigProvider>
					<Form
						layout="vertical"
						form={form}
						name="report"
						initialValues={generateInitialFormValues()}
						onFinish={_doSave}
						className={viewOnly && 'view-only-styles'}
					>
						<Row
							gutter={[50, 50]}
							style={
								features.subtasks && {
									borderBottom: '1px solid #f0f0f0'
								}
							}
						>
							<Col
								id="target-form"
								className="section-pattern-dots"
								span={features.recurrence ? 12 : 24}
								style={{
									// backgroundColor: 'grey',
									...(features.recurrence
										? {
												borderRight:
													'1px solid #f0f0f0',
												paddingRight: '25px'
										  }
										: {})
								}}
							>
								<Space
									direction="vertical"
									style={{ width: '100%' }}
								>
									<GeneralTourTitle
										align="end"
										titleProps={{
											text: 'General',
											h: Title,
											level: 5
										}}
										buttonProps={{
											onClick: startTourForm,
											disabled: false,
											style: { marginBottom: 4 }
										}}
									/>
									<div id="target-form-name">
										<Form.Item
											label="Name"
											tooltip={{
												title: 'Is used for searching and filtering.',
												icon: <InfoCircleOutlined />
											}}
											name="name"
											rules={[
												{
													required: true,
													message:
														'Please provide a name!'
												}
											]}
										>
											<MonthPicker
												style={{ minWidth: '150px' }}
												disabled={formIsDisabled}
											/>
										</Form.Item>
									</div>
									<Space>
										<div id="target-form-name">
											<Form.Item
												label="Hours Worked"
												tooltip={{
													title: 'Total hours worked.',
													icon: <InfoCircleOutlined />
												}}
												name="hoursWorked"
												rules={[
													{
														required: true,
														message:
															'Please provide a name!'
													}
												]}
											>
												<Input disabled={true} />
											</Form.Item>
										</div>
									</Space>
									<div id="target-form-progress">
										<Row align="bottom" gutter={6}>
											<Col>
												<Form.Item
													label="Progress"
													tooltip={{
														title: 'Current progress of the report. You might want to directly define as completed.',
														icon: (
															<InfoCircleOutlined />
														)
													}}
													name="progress"
													rules={[
														{
															required: true,
															message:
																'Please provide a progress!'
														}
													]}
												>
													<ProgressInput
														type="monthly-report"
														viewOnly={viewOnly}
														disabled={
															formIsDisabled
														}
													/>
												</Form.Item>
											</Col>
											{viewOnly && (
												<Col>
													<Form.Item>
														<Button
															htmlType="Set Progress"
															type="primary"
															onClick={(e) => {
																e.preventDefault()
																onSetProgressChange()
															}}
															ghost
														>
															Set Progress
														</Button>
													</Form.Item>
												</Col>
											)}
										</Row>
									</div>
								</Space>
							</Col>
						</Row>
						<Row
							gutter={[50, 50]}
							style={
								!viewOnly && {
									borderBottom: '1px solid #f0f0f0'
								}
							}
						>
							{features.subtasks && (
								<Col
									span={features.comments ? 12 : 24}
									style={
										features.comments && {
											borderRight: '1px solid #f0f0f0',
											paddingRight: '25px'
										}
									}
								>
									<Title level={5}>Subtasks</Title>
									<Form.Item
										label="Subtasks items"
										name="subtasks"
										tooltip={{
											title: 'Subtasks can also have comments.',
											icon: <InfoCircleOutlined />
										}}
									>
										<SubtasksInput
											displaySubtaskDescription
											CustomClass={TimedSubtasks}
											createdBy={loggedInUserId}
											disabled={formIsDisabled}
											currentMonth={currentMonth}
										/>
									</Form.Item>
								</Col>
							)}
							{features.comments && (
								<Col span={features.subtasks ? 12 : 24}>
									<Title level={5}>Comments</Title>
									<Form.Item
										label="Comments"
										name="comments"
										tooltip={{
											title: 'Comments can be added to the report.',
											icon: <InfoCircleOutlined />
										}}
									>
										<CommentsInput
											createdBy={loggedInUserId}
											disabled={false}
										/>
									</Form.Item>
								</Col>
							)}
						</Row>
						{/* {!viewOnly && ( */}
						<SubmitButton>
							{_itm.loaded === IS_NEW ? 'Create' : 'Update'}
						</SubmitButton>
					</Form>
				</ConfigProvider>
			</Card>
		</Space>
	)
}
MonthlyReportData.propTypes = {
	viewOnly: PropTypes.bool
}

export default observer(MonthlyReportData)
