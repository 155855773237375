import {
	Col,
	Form,
	Input,
	InputNumber,
	Row,
	Select,
	Space,
	Switch,
	Tag,
	Typography
} from 'antd'
import React from 'react'
import { useStores } from '../../stores/MobXProvider'
import { observer } from 'mobx-react-lite'
import { countriesOptions, statesOptions } from '../../utils'
const { Paragraph } = Typography

/**
 * BusinessDetailsInput component renders a form for providing business details.
 * It includes fields for account name, phone number, address, city, state, and zip code.
 *
 * @returns {JSX.Element} The rendered BusinessDetailsInput component.
 */
export const BusinessDetailsInput = observer(({ form }) => {
	const { users } = useStores()
	const { loggedInUser } = users
	const isSuperAdmin = loggedInUser.isSuperAdmin

	// Get current selected country
	const selectedCountry = Form.useWatch(['businessDetails', 'address', 'country'], form)

	return (
		<Row>
			<Col flex="auto">
				<Paragraph style={{ color: '#1890ff' }}>
					Used in billing.
				</Paragraph>

				<Row gutter={16}>
					<Col xs={24} sm={12}>
						<Form.Item
							label="Account Name"
							name={['businessDetails', 'account_name']}
						>
							<Input placeholder="Account Name" />
						</Form.Item>
					</Col>

					<Col xs={24} sm={12}>
						<Form.Item
							label="Phone"
							name={['businessDetails', 'mobile']}
						>
							<PhoneInputComponent isSuperAdmin={isSuperAdmin} />
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={16}>
					<Col xs={24} sm={12}>
						<Form.Item
							label="Address Line 1"
							name={[
								'businessDetails',
								'address',
								'address_line_1'
							]}
						>
							<Input placeholder="Address Line 1" />
						</Form.Item>
					</Col>

					<Col xs={24} sm={12}>
						<Form.Item
							label="Address Line 2"
							name={[
								'businessDetails',
								'address',
								'address_line_2'
							]}
						>
							<Input placeholder="Address Line 2" />
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={16}>
					<Col xs={24} sm={12}>
						<CountrySelect />
					</Col>

					{/* Make user select state only if selected country is usa */}
					{selectedCountry === 'usa' && (
						<Col xs={24} sm={12}>
							<Form.Item
								label="State"
								name={['businessDetails', 'address', 'state']}
							>
								<Select
									allowClear
									showSearch
									options={statesOptions}
								/>
							</Form.Item>
						</Col>
					)}

					<Col xs={24} sm={12}>
						<Form.Item
							label="City"
							name={['businessDetails', 'address', 'city']}
						>
							<Input placeholder="City" />
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={16}>
					<Col xs={24} sm={12}>
						<Form.Item
							label="Zip Code"
							name={['businessDetails', 'address', 'zip_code']}
						>
							<Input placeholder="Zip Code" />
						</Form.Item>
					</Col>

					<Col xs={24} sm={12}>
						<Form.Item
							label="Time Zone"
							name={['businessDetails', 'timezone']}
						>
							<Input />
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</Row>
	)
})

/**
 * CountrySelect component renders a dropdown for selecting a country.
 *
 * @returns {JSX.Element} The rendered CountrySelect component.
 */
const CountrySelect = observer(() => {
	return (
		<Form.Item
			label="Country"
			name={['businessDetails', 'address', 'country']}
		>
			<Select
				allowClear
				showSearch
				options={countriesOptions}
			/>
		</Form.Item>
	);
})

/**
 * PhoneInputComponent renders a phone number input section.
 * It includes fields for phone number prefix and number, along with a status tag and switch.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.value - The current value of the phone input.
 * @param {boolean} props.isSuperAdmin - Indicates if the user is a super admin.
 * @returns {JSX.Element} The rendered PhoneInputComponent.
 */
const PhoneInputComponent = observer(
	({ value = {}, onChange, isSuperAdmin }) => {
		const onStatusChange = (checked) => {
			const status = checked ? 'verified' : 'not-verified'
			onChange?.({
				...value,
				status
			})
		}

		return (
			<Row gutter={8} align="middle">
				<Col xs={6} sm={5}>
					<Form.Item name={['businessDetails', 'mobile', 'prefix']}>
						<InputNumber
							placeholder="Prefix"
							style={{ width: '100%' }}
						/>
					</Form.Item>
				</Col>
				<Col xs={18} sm={10}>
					<Form.Item name={['businessDetails', 'mobile', 'number']}>
						<InputNumber
							placeholder="Phone Number"
							style={{ width: '100%' }}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={9}>
					<Form.Item>
						{' '}
						{/* placed this Form Item to maintain the alignment */}
						<Space align="center">
							<Tag
								color={
									value.status === 'verified'
										? '#52c41a'
										: '#f5222d'
								}
							>
								{value.status === 'verified'
									? 'Verified'
									: 'Not Verified'}
							</Tag>
							<Form.Item
								name={['businessDetails', 'mobile', 'status']}
								valuePropName="checked"
								noStyle
							>
								<Switch
									checked={value.status === 'verified'}
									onChange={onStatusChange}
									disabled={!isSuperAdmin}
								/>
							</Form.Item>
						</Space>
					</Form.Item>
				</Col>
			</Row>
		)
	}
)
